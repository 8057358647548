<template>
  <div>
    <div class="antd-page-content">
      <a-row :gutter="12" style="padding:20px 15px 0px">
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" @submit="searchHandleSubmit">
          <a-col :span="6">
            <a-form-item label="课程名称">
              <a-input v-model="queryParams.courseName" placeholder="请输入课程名称" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item>
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQueryForm">重置</a-button>
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
      <div class="antd-toolbar">
        <div class="antd-toolbar-left">
          <a-button type="primary" @click="createFormEvt">新建</a-button>
        </div>
      </div>
      <!-- 视频上传 -->
      <upload-form ref="uploadForm" :visible="uploadFormVisible" @refreshDataList="$refs.table.refresh(true)"/>

      <s-table size="default" ref="table" rowKey="id" :data="loadData" :columns="columns" :pageNum="pageNum">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="fileUrl" slot-scope="text, record">
          <a-tag color="green" v-if="record.fileUrl">已上传</a-tag>
          <a-tag color="red" v-else>否</a-tag>
        </span>
        <template slot="action" slot-scope="text,record">
          <a href="javascript:void(0)" class="table-operation-action" @click="updateFormEvt(record)">编辑</a>
          <a-popconfirm title="确定删除此课程么？" ok-text="确定" cancel-text="取消" placement="left" @confirm="deleteEvt(record)">
            <a href="javascript:void(0)" class="table-operation-action">删除</a>
          </a-popconfirm>
          <a href="javascript:void(0)" class="table-operation-action" @click="questionEvt(record)">试题管理</a>
          <a href="javascript:void(0)" class="table-operation-action" @click="uploadFormEvt(record)">上传</a>
        </template>
         <template slot="img" slot-scope="text,record" class="picture">
          <img v-if="$imgBaseUrl + record.courseImageUrl!=null" :src="$imgBaseUrl + record.courseImageUrl"
            class="list-pic" style="width:100px;height:40px">
          <span v-if="$imgBaseUrl + record.courseImageUrl==null">暂无图片</span>
        </template>
      </s-table>
    </div>
  </div>
</template>

<script>
import { getNewCourseList,delCourseList,getCourseType } from '@/httpsAPI/course/index'
// import UploadForm from '@/components/Upload/video-upload'
// 分片视频上传 
import UploadForm from './video/upload-file'

const columns = [
  {
    title: '序号',
    scopedSlots: { customRender: 'serial' }
  },
  {
    title: '课程名称',
    dataIndex: 'courseName'
  },
  {
    title: '封面图',
    dataIndex: 'courseImageUrl',
    scopedSlots: { customRender: "img" },
  },
  {
    title: '是否上传视频 ',
    dataIndex: 'fileUrl',
    scopedSlots: { customRender: "fileUrl" },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
export default {
  name: "learningRecords",
  data () {
    return {
      courseId:'',
      uploadFormVisible:false,
      columns,
      queryParams:{},
      channelList:[],
      pageNum: 1,
      form: this.$form.createForm(this),
      loadData: parameter => {
        this.pageNum = parameter.pageNo
        return getNewCourseList(Object.assign(parameter,this.queryParams)).then(res => {
          return res.data
        })
      },
    }
  },
  components:{
    UploadForm
  },
  created(){
    this.changeChannelList ()
  },
  methods:{
    // 重置搜索form
    resetQueryForm () {
      this.queryParams = {}
      this.$refs.table.refresh(true)
    },
    changeChannelList () {
      getCourseType()
        .then(res => {
          this.channelList = res.data;
        })
    },
    // 课程类型下拉滚动
    handlePopupScroll (e) {
      const { target } = e
      const scrollHeight = target.scrollHeight - target.scrollTop
      const clientHeight = target.clientHeight
      // 下拉框不下拉的时候
      if (scrollHeight === 0 && clientHeight === 0) {
        this.scrollPage = 1
      } else {
        // 当下拉框滚动条到达底部的时候
        if (scrollHeight < clientHeight + 5) {
          this.pageData(1);
        } else if (scrollHeight == 3208) {
          this.pageData(2);
        }
      }
    },
    handleSearch (keyword) {
      this.keyword = keyword;
      this.changeChannelList();
    },
    searchHandleSubmit (e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
        }
      });
    },
    // 删除
    deleteEvt (item) {
      const that = this
      delCourseList([item.id]).then(res => {
        if (res.code == 10000) {
          that.$message.success({
            content: '操作成功',
            duration: 1,
            onClose: () => {
              that.$refs.table.refresh(true)
            }
          })
        } else {
          that.$message.error({
            content: res.msg,
            duration: 1
          })
        }
      })
    },
    // 新建
    createFormEvt(){
      this.$router.push({path: '/personalDevelopment/add'})
    },
    // 修改
    updateFormEvt(record) {
      this.$router.push({ path: '/personalDevelopment/update', query:{courseid:record.id} })
    },
    // 试题管理
    questionEvt(record){
      this.$router.push({ path: '/personalDevelopment/question', query:{courseId:record.id,courseNo:record.courseNo} })
    },
    // 视频上传
    uploadFormEvt(record){
      console.log(record.id,"record")
      this.uploadFormVisible = true
      this.$nextTick(() => {
        // this.$refs.uploadForm.init({courseId : record.id})
        this.$refs.uploadForm.init(record.id)
      })
    }
  }
}
</script>

<style scoped>

</style>