<template>
  <div>
    <div class="antd-table-search-wrap">
      <a-row :gutter="12">
        <a-form
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 19 }"
        >
          <a-col :md="6" :sm="24">
            <a-form-item label="项目名称">
              <a-input
                v-model="queryParames.projectName"
                placeholder="请输入项目名称"
                @keyup.enter.native="$refs.table.refresh(true)"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="负责人">
              <a-input
                v-model="queryParames.masterName"
                placeholder="请输入负责人名称"
                @keyup.enter.native="$refs.table.refresh(true)"
              ></a-input>
            </a-form-item>
          </a-col>
         
          <a-col :md="6" :sm="24">
            <a-form-item>
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button :style="{ marginLeft: '20px' }" type="default" @click="resetQueryForm">重置</a-button>
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </div>
    <div class="antd-page-content">
      <a-menu mode="horizontal" v-model="currentMenu">
        <a-menu-item
          v-for="(item, index) in projectStatus"
          :key="index"
          @click="statusClickHandle(item.value)"
          >{{ item.title }}</a-menu-item
        >
      </a-menu>
      <div class="antd-toolbar">
        <div class="antd-toolbar-left">
          <div class="antd-toolbar-item">
            <a-button type="primary" @click="addVisible = true" v-action:addProject> + 新建项目</a-button>
            <a-button type="primary" @click="exportBtn"><a-icon type="download"></a-icon>导出</a-button>
          </div>
        </div>
        <div class="antd-toolbar-right">
          <div class="antd-toolbar-item">
            <a-tooltip>
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-icon type="reload" @click="$refs.table.refresh(true)" />
            </a-tooltip>
          </div>
          <div class="antd-toolbar-item">
            <a-tooltip>
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover
                v-model="settingVisible"
                placement="bottomLeft"
                trigger="click"
              >
                <a-icon type="setting" />
                <div slot="content" class="antd-draggable-container">
                  <a-checkbox-group
                    v-model="defaultTableList"
                    @change="tableListHandleChange"
                  >
                    <div
                      class="antd-draggable-wrapper"
                      v-for="(item, index) in tableListArr"
                      :key="index"
                    >
                      <div
                        class="antd-draggable-list-item"
                        :style="{ padding: '4px 0' }"
                      >
                        <a-checkbox :value="item.dataIndex">
                          {{ item.title }}
                        </a-checkbox>
                      </div>
                    </div>
                  </a-checkbox-group>
                </div>
              </a-popover>
            </a-tooltip>
          </div>
        </div>
      </div>
      <s-table
        size="default"
        ref="table"
        rowKey="id"
        :data="loadData"
        :columns="columns"
        :selections="true"
        :pageNum="pageNum"
        :scroll="{ x: 1200 }"
      >
        <template slot="projectName" slot-scope="text,record">
          <a-button type="link" @click="viewProjectModel(record)">{{ record.projectName }}</a-button>
          <a-tag v-if="record.earlyWarningType==1" color="#F5222D" style="border-radius:50%; width:24px; height: 24px; line-height: 22px; padding:0; text-align: center;">警</a-tag>
        </template>
        
        <template slot="status" slot-scope="text, record">
          <a-tag color="red" v-if="record.status === 0">未开始</a-tag>
          <a-tag color="orange" v-if="record.status === 1">进行中</a-tag>
          <a-tag color="green" v-if="record.status === 2">已完成</a-tag>
        </template>
        <template slot="archiveStatus" slot-scope="text, record">
          <a-tag color="red" v-if="record.archiveStatus === 0">未归档</a-tag>
          <a-tag color="green" v-if="record.archiveStatus === 1">已归档</a-tag>
        </template>
         <template slot="projectCompleteRate" slot-scope="text, record">
          <a-progress
            v-if="record.schedule === 0"
            :percent="record.schedule"
            size="small"
            status="normal"
          />
          <a-progress
            v-if="record.schedule > 0 && record.schedule < 100"
            :percent="record.schedule"
            size="small"
            status="active"
          />
          <a-progress
            v-if="record.schedule === 100"
            :percent="record.schedule"
            size="small"
            status="success"
          />
        </template>
        <template slot="completeRate" slot-scope="text, record">
          <a-progress
            v-if="record.completeRate === 0"
            :percent="record.completeRate"
            size="small"
            status="normal"
          />
          <a-progress
            v-if="record.completeRate > 0 && record.completeRate < 100"
            :percent="record.completeRate"
            size="small"
            status="active"
          />
          <a-progress
            v-if="record.completeRate === 100"
            :percent="record.completeRate"
            size="small"
            status="success"
          />
        </template>
      </s-table>
    </div>
    <add-project
      :visible="addVisible"
      :confirm-loading="confirmLoading"
      @ok="addHandleClick"
      @cancel="addVisible = false"
    ></add-project>
  </div>
</template>

<script>
import { getProjectList, projectAdd,projectExport } from "@/httpsAPI/project";
import addProject from "./modules/addProject";
const projectStatus = [
  { title: "全部项目", value: "" },
  { title: "预警项目", value: "12" },
  { title: "未开始", value: "0" },
  { title: "进行中", value: "1" },
  { title: "已完成", value: "2" },
  { title: "已归档", value: "11" },
];

let columns = [
  {
    title: "项目名称",
    dataIndex: "projectName",
    width: 200,
    scopedSlots: { customRender: "projectName" },
    isShow: true,
  },
  {
    title: "状态",
    dataIndex: "status",
    width: 80,
    scopedSlots: { customRender: "status" },
    align: "center",
    isShow: true,
  },
  {
    title: "负责人",
    dataIndex: "masterName",
    width: 150,
    align: "center",
    isShow: true,
  },
  {
    title: "计划开始日期",
    dataIndex: "startTime",
    width: 120,
    align: "center",
    isShow: true,
  },
  {
    title: "计划结束日期",
    dataIndex: "endTime",
    width: 120,
    align: "center",
    isShow: true,
  },
  {
    title: "是否归档",
    dataIndex: "archiveStatus",
    scopedSlots: { customRender: "archiveStatus" },
    width: 120,
    align: "center",
    isShow: true,
  },
  {
    title: "项目进度",
    dataIndex: "schedule",
    width: 120,
    scopedSlots: { customRender: "projectCompleteRate" },
    isShow: true,
  },
  {
    title: "工作项完成度",
    dataIndex: "completeRate",
    width: 120,
    scopedSlots: { customRender: "completeRate" },
    isShow: true,
  },
  { title: "工作项数量", dataIndex: "taskNums", width: 120, isShow: false },
  { title: "成员数", dataIndex: "memberNums", width: 120, isShow: false },
  {
    title: "未开始工作项数量",
    dataIndex: "unStartTaskNums",
    width: 120,
    isShow: false,
  },
  {
    title: "进行中工作项数量",
    dataIndex: "processingTaskNums",
    width: 120,
    isShow: false,
  },
  {
    title: "已完成工作项数量",
    dataIndex: "finishedTaskNums",
    width: 120,
    isShow: false,
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    width: 120,
    isShow: false,
    sorter: true,
    sortDirections: ["descend", "ascend"],
  },
  { title: "创建人", dataIndex: "createBy", width: 120, isShow: false },
];
export default {
  name: "project",
  data() {
    return {
      sortField: "createTime",
      sortOrder: "descend",
      projectStatus,
      queryParames: {},
      pageNum: 1,
      loadData: (parameter) => {
        this.pageNum = parameter.pageNo;
        if (!parameter.sortField && !parameter.sortOrder) {
          parameter.sortField = "createTime";
          parameter.sortOrder = "descend";
        }
        const requestResult = Object.assign({}, parameter, this.queryParames);
        return getProjectList(requestResult).then((res) => {
          return Object.assign({}, res.data, parameter);
        });
      },
      tableListArr: (() => {
        return columns.filter((item) => {
          return item.dataIndex;
        });
      })(),
      defaultTableList: (() => {
        let arr = [];
        columns.map((item) => {
          if (item.isShow) {
            arr.push(item.dataIndex);
          }
        });
        return arr;
      })(),
      currentMenu: [0],
      settingVisible: false,
      addVisible: false,
      confirmLoading: false,
    };
  },
  computed: {
    columns() {
      return columns.filter((item) => {
        return item.isShow;
      });
    },
  },
  components: {
    addProject,
  },
  methods: {
    exportBtn(){
      projectExport(Object.assign({pageNo: 1, pageSize: 2000000}, this.queryParames)).then(res=>{
        console.log('导出完成')
      })
    },
    viewProjectModel(record) {
      this.$router.push({
        query: { projectId: record.id },
        name: "ProjectManageOverview",
      })
    },
    // 重置搜索form
    resetQueryForm() {
      this.queryParames = {};
      this.$refs.table.refresh(true);
    },
    statusClickHandle(val) {
      this.queryParames.status = val;
      // 预警任务查询
      if(val==12) {
        this.queryParames.isAlarm = 1;
        this.queryParames.status = ''
      } else {
        this.queryParames.isAlarm = '';
      }
      
      // 归档任务查询
      if(val==11) {
        this.queryParames.archiveStatus = 1;
        this.queryParames.status = ''
      } else {
        this.queryParames.archiveStatus = '';
      }
      
      this.$refs.table.refresh(true);
    },
    searchHandleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    tableListHandleChange(checkedVal) {
      columns.forEach((item) => {
        if (checkedVal.includes(item.dataIndex)) {
          item.isShow = true;
        } else {
          item.isShow = false;
        }
      });
    },
    addHandleClick(parames) {
      this.confirmLoading = true
      
      projectAdd(parames).then((res) => {
        this.confirmLoading = false;
        this.addVisible = false;
        if (res.code === "10000") {
          this.$message.success("新建成功");
          this.$refs.table.refresh(true);
        } else {
          this.$message.error(`${res.msg}`);
        }
      })
    },
  },
};
</script>

<style scoped>
</style>