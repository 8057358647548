<template>
  <a-modal
        title="共享文件"
        :visible="visible"
        width="850px"
        :confirm-loading="confirmLoading"
        @cancel="handleCancel"
        destroyOnClose
      >
      <a-row :gutter="24">
       <a-form
            :form="form"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 19 }"
          >
            <a-col :md="12" :sm="12">
              <a-form-item label="成员名称">
              <a-input v-model="queryParams.userName" placeholder="请输入成员名称"></a-input>
            </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item>
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button :style="{marginLeft:'20px'}" type="default" @click="resetQueryForm">重置</a-button>
              </a-form-item>
            </a-col>
       </a-form>
      </a-row>
      <a-button class="ant-btn ant-btn-primary" @click="moreSetEvt()" style="margin-bottom:20px">批量授权</a-button>
      <a-button class="ant-btn ant-btn-primary" @click="moreDelEvt()" style="margin:0px 0px 20px 20px;">批量撤销</a-button>
      <a-row :gutter="24">
          <s-table
              rowKey="userId"
              size="default"
              ref="table"
              :columns="columns"
              :data="loadData"
              :row-selection="{ selectedRowKeys: selectedRowKeys,onChange: onSelectChange,}"
              :pageNum="pageNum"
            >
            <span slot="isShare" slot-scope="text, record">
              <a-tag color="red" v-if="record.isShare==='0'">未共享</a-tag>
              <a-tag color="green" v-if="record.isShare==='1'">已共享</a-tag>
            </span>
            <template slot="action" slot-scope="text,record">
               <a-popconfirm
                title="确定授权此数据么？"
                ok-text="确定"
                cancel-text="取消"
                placement="topLeft"
                @confirm="agreeEvt(record)"
                 v-if="record.isShare==0"
              >
                <a-icon slot="icon" type="question-circle-o" style="color: red" />
                <a href="javascript:void(0)" class="table-operation-action">授权</a>
              </a-popconfirm>
              <a-popconfirm
                title="确定撤销此数据么？"
                ok-text="确定"
                cancel-text="取消"
                placement="topLeft"
                @confirm="revoke(record)"
                 v-if="record.isShare==1"
              >
                <a-icon slot="icon" type="question-circle-o" style="color: red" />
                <a href="javascript:void(0)" class="table-operation-action">撤销</a>
              </a-popconfirm>
            </template>
          </s-table>
      </a-row>
      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">关闭</a-button>
      </span>
  </a-modal>
</template>
<script>
  import { getShare,setShare } from "@/httpsAPI/project/task"

  let columns=[
    {
      title: '成员名称',
      dataIndex: 'userName',
    },
    {
      title: '共享状态',
      dataIndex: 'isShare',
      scopedSlots: { customRender: 'isShare' },
    },
    {
      title: "操作", dataIndex: "action", scopedSlots: { customRender: 'action' }, align: 'center'
    }
  ]

  export default{
    data(){
      return{
        visible: false,
        confirmLoading: false,
        queryParams:{},
        columns,
        type:'1',
        projectId:'',
        fileId:'',
        pageNum: 1,
        selectedRowKeys: [],
        form: this.$form.createForm(this),
        loadData: parameter => {
          this.pageNum = parameter.pageNo
          return getShare(Object.assign(parameter,this.queryParams,{type:this.type,fileId:this.fileId,projectId:this.projectId})).then(res => {
            return res.data
          })
        },
      }
    },
    computed: {

    },
    created(){

    },
    methods: {
       // 重置搜索form
      resetQueryForm() {
        this.queryParams = {}
        this.$refs.table.refresh(true)
      },
      //批量授权
      moreSetEvt() {
        if(this.selectedRowKeys.length==0) {
          this.$message.warning({
                content: '请选择一条数据进行操作！',
                duration: 1
            })
          return
        }
        const that = this
        this.$confirm({
          title: '系统提示',
          content: '确认授权选中数据么?',
          okText: '确认',
          okType: 'default',
          cancelText: '取消',
          onOk() {
            setShare({type:that.type,fileId:that.fileId,isShare: 1,userIds:that.selectedRowKeys}).then(res=>{
                if(res.code==10000) {
                  that.$message.success({
                      content: '操作成功',
                      duration: 2,
                      onClose: () => {
                          that.$refs.table.refresh(true)
                          that.selectedRowKeys = []
                      }
                  })
                } else {
                  that.$message.error({
                      content: res.msg,
                      duration: 2
                  })
                }
            })
          }
        })
      },
      //批量取消
      moreDelEvt() {
        if(this.selectedRowKeys.length==0) {
          this.$message.warning({
                content: '请选择一条数据进行操作！',
                duration: 1
            })
          return
        }
        const that = this
        this.$confirm({
          title: '系统提示',
          content: '确认撤销选中数据么?',
          okText: '确认',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            setShare({type:that.type,fileId:that.fileId,isShare: 2,userIds:that.selectedRowKeys}).then(res=>{
                if(res.code==10000) {
                  that.$message.success({
                      content: '操作成功',
                      duration: 2,
                      onClose: () => {
                          that.$refs.table.refresh(true)
                          that.selectedRowKeys = []
                      }
                  })
                } else {
                  that.$message.error({
                      content: res.msg,
                      duration: 2
                  })
                }
            })
          }
        })
      },
      // 授权
      agreeEvt(record){
        const that = this
        setShare({type:this.type,fileId:this.fileId,isShare: 1,userIds:[record.userId]}).then(res=>{
        if(res.code==10000) {
            that.$message.success({
                content: '操作成功',
                duration: 1,
                onClose: () => {
                    that.$refs.table.refresh(true)
                }
            })
            } else {
            that.$message.error({
                content: res.msg,
                duration: 1
            })
            }
        })
      },
      //撤回
      revoke(record){
        const that = this
        setShare({type:this.type,fileId:this.fileId,isShare: 2,userIds:[record.userId]}).then(res=>{
        if(res.code==10000) {
            that.$message.success({
                content: '操作成功',
                duration: 1,
                onClose: () => {
                    that.$refs.table.refresh(true)
                }
            })
            } else {
            that.$message.error({
                content: res.msg,
                duration: 1
            })
            }
        })
      },
      onSelectChange(selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys;
      },
      handleCancel(e) {
        this.visible = false;
      },
      init (projectId,fileId) {
        this.visible = true
        this.projectId = projectId
        this.fileId = fileId
        this.selectedRowKeys = []
      }
    },
  }
</script>
<style scoped>
.ant-modal-content {
    width: 850px;
}
</style>
