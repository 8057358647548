const proxy = process.env.VUE_APP_BASE_URL;
const API = {
  login: proxy + "/user/login",
  userInfo: proxy + "/user/profile/userInfo",
  projectList: proxy + "/project/list",
  projectTemplateSelect: proxy+"/project/template-select",                  
  projectSelect: proxy+"/project/project-select",    
  changeProjectList: proxy+"/project/change-list",                           
  projectAdd: proxy+"/project/add",                                         
  projectProfile: proxy+"/project/profile/detail",                                         
  projectUserList: proxy+"/project/user/list",                                         
  projectUserRelateList: proxy+"/project/user/relate-list",                                 
  projectOverviewTask: proxy+"/project/profile/alarm-task-list",
  projectOverviewPlan: proxy+"/project/profile/alarm-plan-list",
  projectOverviewNotice: proxy+"/project/profile/notice-list",
  projectTaskList: proxy+"/project/task/list",
  projectTaskFileList: proxy+"/project/file/list",
  projectDeliverablesList: proxy+"/project/deliverables/list",
  projectStageList: proxy+"/project/plan/list",
  projectStageList2: proxy+"/project/plan/list2",
  projectStageGantt: proxy+"/project/plan/gantt-list/",
};

export default API;
