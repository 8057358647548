<template>
  <layout>
    <template slot="headerMenu">
      <div class="header-menu">
        <div class="header-menu-wraper">
          <div class="header-menu-title">知识库</div>
            <a-menu mode="horizontal" v-model="currentMenu">
                <template v-for="item in topMenuList">
                  <a-menu-item :key="item.name" v-if="!item.hidden" >
                    <div @click="navEvt(item)">{{ item.meta.title }}</div>
                  </a-menu-item>
                </template>
            </a-menu>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import {mapGetters} from 'vuex'
import { getUserModulePermList } from "@/httpsAPI/configure";
export default {
  name: "Knowledge",
  computed:{
    ...mapGetters(['addRouter'])
  },
  data(){
    return{
      currentMenu:['Myself'],
      topMenuList : []
    }
  },
  methods:{
    navEvt(item){
      this.$router.push({
        path:item.path
      })
    },
    loadPermData() {
      getUserModulePermList({id: 17}).then((res) => {
        let permitList = {}
        permitList.permissions = res.data
        permitList.isLoad = 1
        
        this.$store.commit('SET_PERMITS',permitList)
        console.log('permitList************', permitList)
      })
    }
  },
  created() {
    this.currentMenu = [this.$route.name]
    this.topMenuList = this.addRouter.filter(item=>item.name==='Knowledge')[0].children
    this.loadPermData()
  }
}

</script>

<style scoped>
</style>