<template>
  <a-card>
    <div class="antd-toolbar">
      <div class="antd-toolbar-left">
        <div class="antd-toolbar-item">
          <a-button type="primary" icon="download" @click="batchDownloadFileEvt">批量下载</a-button>
        </div>
      </div>
      <div class="antd-toolbar-right">
        
        <div class="antd-toolbar-item">
          <a-input-search v-model="queryParams.fileTitle" style="margin-left: 16px; width: 272px;" placeholder="请输入资料名称进行搜索" @keyup.enter.native="$refs.table.refresh(true)" />
        </div>
      </div>
    </div>
    <s-table
      rowKey="id"
      :data="loadData"
      size="default"
      ref="table"
      :columns="columns"
      :alert="alert"
      :rowSelection="rowSelection"
      :pageNum="pageNum"
    >
      <template slot="refType" slot-scope="text, record">
          <span v-if="record.refType == 1">需求</span>
          <span v-if="record.refType == 2">任务</span>
          <span v-if="record.refType == 3">求助单</span>
          <span v-if="record.refType == 4">交付物文件</span>
          <span v-if="record.refType == 5">任务资料文件</span>
        </template>
      <template slot="action" slot-scope="text,record">
        <a href="javascript:void(0)" class="table-operation-action" @click="showDeleteHandleClick(record)" v-permit:deleteTaskFile="{projectId:$route.query.projectId}">删除</a>
        <a href="javascript:void(0)" class="table-operation-action" @click="downloadFileEvt(record)">下载</a>
      </template>
    </s-table>
  </a-card>
</template>

<script>
import {getProjectManageTaskFileList, deleteTaskFileList, batchDownload, fileDownload} from "@/httpsAPI/project/file";
import {coludDownloadFile } from '@/httpsAPI/common'

const columns=[
  {
    title:'文件名称',
    dataIndex:'fileTitle'
  },
  {
    title: '所属工作项',
    dataIndex: 'taskName'
  },
  {
    title: '上传人',
    dataIndex: 'userName'
  },
  {
    title: '文件类型',
    dataIndex: 'refType',
    scopedSlots:{customRender:"refType"}
  },
  {
    title: '上传时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    align: 'center',
    dataIndex: 'action',
    scopedSlots: {customRender: 'action'}
  }
]
export default {
  name: "taskData",
  data(){
    return {
      form: this.$form.createForm(this, { name: 'searchProject' }),
      columns,
      pageNum: 1,
      queryParams:{},
      selectedRowKeys:[],
      selectedRows:[],
      loadData:parameter=>{
        this.pageNum = parameter.pageNo
        this.queryParams.projectId = this.$route.query.projectId
        this.queryParams.refType = 5

        return getProjectManageTaskFileList(Object.assign(parameter, this.queryParams)).then(res=>{
          return res.data
        })
      },
      alert:{
        show: false, clear: () => { this.selectedRowKeys = [] }
      },
      rowSelection:{
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  methods:{
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    //删除
    showDeleteHandleClick(record){
     const that = this
      this.$confirm({
        title: '删除任务资料?',
        content: '确定删除资料['+record.fileTitle+']么？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deleteTaskFileList([record.id]).then(res => {
            that.$message.success({
                  content: '操作成功',
                  duration: 1,
                  onClose: () => {
                    that.$refs.table.refresh(true)
                  }
              })
          }).catch(error => {
              console.log(error)
          })
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    // 批量下载
    batchDownloadFileEvt() {
      if(this.selectedRows.length==0) {
          this.$message.warning('请选择要下载的数据')
          return
      }

      batchDownload('batch_task_file.zip', this.selectedRowKeys).then(res=>{
      })
    },
    // 下载文件
    downloadFileEvt(record) {
      var type = window._CONFIG['downloadType'];
      if (type == "local") {
        const params = {
          id : record.id,
          fileName : record.fileTitle
        }
        fileDownload(params).then(res=>{
        })
      }
      if(type == "cloud"){
        coludDownloadFile(record.id,'/project/file/file-download');
      }
    }
  }
}
</script>

<style scoped>

</style>