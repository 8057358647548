<template>
  <a-modal
      :title="planType==1 ? (parentId==0 ? '编辑阶段' : '编辑子阶段') : '编辑评审点'"
      :visible="visible"
      :width="650"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="阶段名">
          <a-input placeholder="请输入阶段名称"
            v-decorator="['planName', { rules: [{ required: true, message: '请输入阶段名称' }] }]"
          />
        </a-form-item>

        <a-form-model-item label="负责人">
            <a-select
                :filter-option="false"
                show-search
                :allowClear="true"
                placeholder="请选择或搜索负责人"
                v-decorator="['masterId', { rules: [{ required: true, message: '请选择负责人' }] }]"

                @search="handleSearch"
                >
                    <a-select-option v-for="item in masterList" v-bind:key="item.userId" :value="item.userId">
                        {{item.name }}
                    </a-select-option>
            </a-select>
          </a-form-model-item>

        <a-form-item label="起止时间" v-if="planType==1">
          <a-range-picker style="width:100%" format="YYYY-MM-DD" v-decorator="['timeRange', {rules: [{ required: true, message: '请输入起止时间'}]}]" @change="dateChange">
                      <a-icon slot="suffixIcon" type="calendar" />
           </a-range-picker>
        </a-form-item>

        <a-form-item label="完成时间" v-if="planType==2">
          <a-date-picker format="YYYY-MM-DD" v-decorator="['endTimeRange', {rules: [{ required: true, message: '请输入完成时间'}]}]" @change="endDateChange"/>
        </a-form-item>

        <a-form-item label="备注">
            <a-textarea placeholder="请输入备注" :rows="4" v-decorator="['content']"/>
        </a-form-item>

      </a-form>
      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">取消</a-button>
        <a-button class="ant-btn ant-btn-primary" :loading="confirmLoading" @click="dataFormSubmit()">确定</a-button>
      </span>
    </a-modal>
</template>
<script>
import moment from 'moment'
import {getProjectUserList} from "@/httpsAPI/common";
import {updateStage} from "@/httpsAPI/project/stage";
    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                visible: false,
                confirmLoading: false,
                timeRange:[],
                startTime:'',
                endTime:'',
                scrollPage: 1,
                masterData: [],
                keyword: '',
                masterList: [],
                parentId :0,
                planType: 1
            }
        },
        methods:{
          // 下拉框 分页 搜索
          changeMasterList() {
              getProjectUserList({name: this.keyword,projectId:this.$route.query.projectId,pageNo:1, pageSize: 1000})
                      .then(res => {
                          this.masterList = res.data.data;
                          this.masterData = res.data;
                      })
          },
          //选择渠道下拉框滚动事件
          handlePopupScroll(e) {
              const { target } = e
              const scrollHeight = target.scrollHeight - target.scrollTop
              const clientHeight = target.clientHeight
              // 下拉框不下拉的时候
              if (scrollHeight === 0 && clientHeight === 0) {
                  this.scrollPage = 1
              } else {
                  // 当下拉框滚动条到达底部的时候
                  if (scrollHeight < clientHeight + 5) {
                      this.pageData(1);
                  }else if (scrollHeight == 3208) {
                      this.pageData(2);
                  }
              }
          },
          pageData(type) {
              if (this.masterData.totalPage < this.scrollPage) {
                  this.scrollPage = this.masterData.totalPage;
              } else {
                  type == 1 ? this.scrollPage++ : this.scrollPage--;
                  this.changeMasterList();
                  let newData = [];
                  let max = this.masterData.totalCount;
                  this.masterList.forEach((item, index) => {
                  //当data数组的下标小于max时
                  if (index < max) {
                      newData.push(item)
                  }
                  })
                  this.masterList = newData;
              }
          },
          handleSearch(keyword){
              this.keyword = keyword;
              this.changeMasterList();
          },
          dateChange(date, dateString) {
            this.startTime = dateString[0]
            this.endTime = dateString[1]
          },
          endDateChange(date, dateString) {
            this.endTime = dateString
          },
          dataFormSubmit(e) {

            this.form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                this.confirmLoading = true;

                values['id'] = this.stageId
                if(this.planType!=2) {
                  values['startTime'] = this.startTime
                }
                values['endTime'] = this.endTime

                window._CONFIG['highlightId'] = this.stageId;
                updateStage(values).then(res=>{
                  this.confirmLoading = false;
                  if(res.code==10000) {
                    this.$message.success({
                        content: '保存成功',
                        duration: 1,
                        onClose: () => {
                            this.form.resetFields();
                            this.visible = false;
                            this.$emit('refreshDataList')
                        }
                    })
                  }else {
                    this.$message.error({
                        content: res.msg,
                        duration: 1
                    })
                  }

                })
            });
          },
          handleCancel(e) {
            this.visible = false;
          },
          init(record) {
            this.visible = true
            this.stageId = record.id
            this.parentId = record.parent
            this.$nextTick(() => {
                this.form.resetFields();
                this.planType = record.planType
                this.startTime = record.startTime ? moment(record.startTime).format('YYYY-MM-DD') : ''
                this.endTime = moment(record.endTime).format('YYYY-MM-DD')

                // this.changeMasterList()
                getProjectUserList({name: this.keyword,projectId:this.$route.query.projectId,pageNo:1, pageSize: 1000}).then(res => {
                    this.masterList = res.data.data;
                    this.masterData = res.data;

                    if(this.planType==1) {
                      this.form.setFieldsValue({
                          planName: record.planName,
                          masterId: record.masterId,
                          timeRange: [moment(this.startTime, "YYYY-MM-DD"), moment(this.endTime, "YYYY-MM-DD")],
                          content: record.content
                        })
                    } else {
                      this.form.setFieldsValue({
                          planName: record.planName,
                          masterId: record.masterId,
                          endTimeRange: moment(this.endTime, "YYYY-MM-DD"),
                          //timeRange: [moment('2021-11-04', "YYYY-MM-DD"), moment(this.endTime, "YYYY-MM-DD")],
                          content: record.content
                        })
                    }
                })
            });
          }
        }
    }
</script>
