<template>
    <a-card :bordered="false">
      <a-row :gutter="50">
        <a-form id="components-form-demo-normal-login" :form="form" @submit="phoneSubmit">  
          <a-col :md="24" :lg="12"> 
            <a-form-item>
              <a-input v-decorator="[
                'phone',
                { rules: [{ required: true, message: '旧手机号!' }] }]" placeholder="请输入旧手机号" :disabled="true">
                      <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
              </a-input>
            </a-form-item>                 
            <a-form-item style="width:300px;display:inline-block;"> 
              <a-input v-decorator="[
                'verification',
                { rules: [{ required: true, message: '请输入验证码!' }] }]" type="password" placeholder="请输入验证码">
                      <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
              </a-input>
            </a-form-item>
            <a-button class="login-form-button" style="width:150px;display:inline-block;margin-top:3px;" @click="sendcode($event)" v-if="issend">
              验证码
            </a-button>
            <a-button html-type="submit" class="login-form-button" style="width:150px;display:inline-block;margin-top:3px;" @click="sendcode($event)" v-if="!issend">
              {{count}}s
            </a-button>
            <a-form-item>
              <a-input v-decorator="[
                'newPassWord',
                { rules: [{ required: true, message: '请输入新手机号!' }] }]" placeholder="请输入新手机号" :setFieldsValue="newPassWord">
                      <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" html-type="submit" class="login-form-button">
                保存
              </a-button>
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </a-card>
</template>

<script>
import { updateSmsVerification, updatePhone, getuserInfo} from '@/httpsAPI/personal/index'

export default {
  data () {
    return {
      loadingFile:false,
      newPassWord:'',
      count: 60,
      issend: true,
      phone:''
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  methods: {
    phoneSubmit (e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          updatePhone(values).then(res => {
           this.form.resetFields();
           this.$emit('refreshDataList')
          })
        }
      });
    },
  // 获取信息
    getInfo(){
      getuserInfo().then(res => {
        this.form.setFieldsValue({
            phone: res.data.phone,
          }) 
      })
    },
    //验证码
    sendcode() {
      if(this.newPassWord == null){
        this.issend = true;
      }else{
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.issend = false;
          //这里可以插入$axios调用后台接口
          updateSmsVerification(this.newPassWord).then(res=>{
            console.log(res)
          })
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.issend = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    }
  },
  created(){
    this.getInfo()
  }
}
</script>
<style lang="less" scoped>
.certificate-box{
  position: relative;
  width: 360px;
  margin: auto;
  .item{
    position: absolute;
    color: #799999;
    .red{
      color: #FF7070;
    }
  }
  img{
    width: 100%;
  }
}
</style>