<template>
  <div class="help-content">
    <a-row type="flex">
      <a-col :span="4">
        <a-menu
          @click="changNav"
          theme="light"
          mode="inline"
          :defaultSelectedKeys="['1']"
          style="calc(height:100% - 64px;)"
        >
          <a-sub-menu :key="key" v-for="(item, key) in menuList">
            <span slot="title"
              ><span>{{ item.title }}</span></span
            >

            <template v-for="subMenuItem in item.children">
              <!--子导航栏-->
              <template v-if="subMenuItem.children.lenght > 0">
                <SubMenu
                  :subMenuArr="subMenuItem"
                  :key="subMenuItem.key"
                ></SubMenu>
              </template>

              <template v-else>
                <!--导航栏-->
                <a-menu-item :key="subMenuItem.key">{{
                  subMenuItem.title
                }}</a-menu-item>
              </template>
            </template>
          </a-sub-menu>
        </a-menu>
      </a-col>
      <a-col :span="20">
        <div style="padding: 20px 28px" v-html="content"></div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { getHelpDicList, getHelpDetail } from "../../httpsAPI/search";
import SubMenu from "../workplace/components/SubMenu.vue";
export default {
  components: {
    SubMenu,
  },
  name: "help",
  data() {
    return {
      menuList: [],
      content: "",
    };
  },
  mounted() {
    this.initData(3);
  },
  methods: {
    initData(t) {
      getHelpDicList({
        type: t,
      }).then((res) => {
        console.log("树形菜单", res);
        this.menuList = res.data;
      });
    },
    callback(e) {
      console.log("切换：", e);
      this.initData(e);
    },
    changNav(e) {
      console.log("选择：", e.key);
      getHelpDetail({
        id: e.key,
      }).then((res) => {
        console.log("详情：", res);
        this.content = res.data.content;
      });
    },
  },
};
</script>

<style >
.layout-content-page-wrap {
  position: relative;
}
.help-content {
  min-height: 100vh;
  background-color: #fff;
  border-radius: 8px;
}
</style>