<template>
<div>
     <!-- 评分弹窗 -->
    <comment-form v-if="commentFormVisible" ref="commentForm" @refreshDataList="init" />
    <!-- 上传文件组件-->
    <upload-form v-if="uploadFormVisible" ref="uploadForm" @refreshDataList="init" />
    <!-- 修改状态弹窗 -->
    <status-form v-if="updateFormVisible" ref="statusForm" @refreshDataList="init" />
    <!-- 关联知识库弹窗 -->
    <relate-form v-if="relateFormVisible" ref="relateForm" @refreshDataList="init" />
    <!-- 文件共享弹框 -->
    <share-form v-if="shareFormVisible" ref="shareForm" @refreshDataList="init" />


    <a-card :title="task.title" :bordered="false">
      <div slot="extra">
        <a-button type="primary" @click="$router.back()">返回</a-button>
      </div>
      <a-descriptions>
        <a-descriptions-item label="负责人">{{task.masterName}}</a-descriptions-item>
        <a-descriptions-item label="开始日期">{{startTime}}</a-descriptions-item>
        <a-descriptions-item label="完成日期">{{endTime}}</a-descriptions-item>
        <a-descriptions-item label="状态">
          <span v-if="task.status==0">待开始</span>
          <span v-if="task.status==1">进行中</span>
          <span v-if="task.status==2">已完成</span>
          <div style="display:inline-block" v-show="isPermit">
            <a-tooltip placement="bottom" title="修改任务状态" style="margin-left:5px" v-permit:updateTaskStatus="{projectId:$route.query.projectId}">
            <a-icon type="edit" @click="updateStatusEvt()"/>
            </a-tooltip>
          </div>
        </a-descriptions-item>

        <a-descriptions-item label="任务评分">
          <div @click="commentStars" style="cursor: pointer;">
            <a-rate v-model="task.score" disabled />
          </div>
        </a-descriptions-item>
      </a-descriptions>
    </a-card>

     <a-row :gutter="24">
      <a-col :xl="16" :lg="24" :md="24" :sm="24" :xs="24">
         <a-card :bordered="false" style="margin-top: 20px;height:908px;overflow-y:auto;">
              <a-tabs default-active-key="1">
                <a-tab-pane key="2" tab="任务资料" force-render>
                  <div class="antd-table-wrap">
                    <a-button type="primary" icon="upload" @click="addFileEvt" v-permit:uploadTaskFile="{projectId:$route.query.projectId}"  v-show="isPermit">上传</a-button>
                    <a-table
                      rowKey="id"
                      size="default"
                      ref="fileTable"
                      :columns="columns"
                      :data-source="tabFileData"
                    >
                      <template slot="dataTime" slot-scope="text">
                          {{text|dataTimeFilter}}
                        </template>
                      <span slot="serial" slot-scope="text, record, index">
                              {{ index + 1 }}
                            </span>
                      <template slot="operation" slot-scope="text,record">
                        <a-popconfirm
                            v-show="isPermit"
                            :title="'确定删除['+record.fileTitle+']文件么？'"
                            ok-text="确定"
                            cancel-text="取消"
                            placement="topLeft"
                            @confirm="deleteFileEvt(record)"
                            >
                            <a-button type="link" v-permit:deleteTaskFile="{projectId:$route.query.projectId}" v-if="record.isShare == 1">删除</a-button>
                        </a-popconfirm>
                        <a-button type="link" @click="shaerFileEvt(record)" v-if="record.isShare == 1">共享</a-button>
                        <a-button type="link" @click="downloadFileEvt(record)" v-show="isPermit">下载</a-button>
                      </template>
                    </a-table>
                  </div>
                </a-tab-pane>
                <a-tab-pane key="3" tab="评分评价" force-render>
                  <div class="antd-table-wrap">
                    <a-descriptions title="">
                      <a-descriptions-item label="创建时间">{{$moment(this.task.createTime).format('YYYY-MM-DD HH:mm:ss')}}</a-descriptions-item>
                      <a-descriptions-item label="更新时间">{{this.task.modifyTime ? $moment(this.task.modifyTime).format('YYYY-MM-DD HH:mm:ss') : ''}}</a-descriptions-item>
                    </a-descriptions>
                    <a-descriptions title="">
                      <a-descriptions-item label="评分人">{{this.task.evaluateUserName}}</a-descriptions-item>
                      <a-descriptions-item label="评分时间">{{this.task.evaluateTime ? $moment(this.task.evaluateTime).format("YYYY-MM-DD HH:mm:ss") : "" }}</a-descriptions-item>
                    </a-descriptions>

                    <a-descriptions title="" size="small">
                      <a-descriptions-item label="评语">
                        {{task.evaluation}}
                      </a-descriptions-item>
                    </a-descriptions>

                    <div>
                      <span style="display:inline-block;padding-bottom:10px">描述：</span>
                      <span v-html="task.content"></span>
                    </div>
                  </div>
                </a-tab-pane>
              </a-tabs>
          </a-card>
       </a-col>
       <a-col
          style="padding: 0 24px"
          :xl="8"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24">
          <!-- 页面类型：0阶段 1评审点 2需求，3任务，4求助单-->
          <trends :pageType="3" :projectId="this.$route.query.projectId" :taskId="this.$route.query.taskId" :taskType="2" />
       </a-col>
     </a-row>
</div>
</template>

<script>
import store from '@/store'
import moment from 'moment'
import Trends from "@/components/Trends";
import StatusForm from "@/components/Status";
import { getUserProjectPermList } from "@/httpsAPI/configure";
import {getFileList, detailTask, deleteFile, fileDownload, getRelatedWikiList, deleteRelateWiki} from "@/httpsAPI/project/task";
import CommentForm from '@/components/commentStars/index'
import UploadForm from '@/components/Upload/document-upload'
import RelateForm from './wikiRelate.vue'
import ShareForm from './taskShare.vue'
import {coludDownloadFile } from '@/httpsAPI/common'

const tplColumns = [
  {
    title:'序号',
    scopedSlots: { customRender: 'serial' }
  },
  {
    title:'模板名称',
    dataIndex: 'name',
    scopedSlots: {customRender: 'wikiName'}
  },
  {
    title: '操作',
    dataIndex: 'action',
    align: 'center',
    scopedSlots: {customRender: 'operation'}
  }
]
const columns=[
  {
    title:'序号',
    scopedSlots: { customRender: 'serial' }
  },
  {
    title:'文件名称',
    dataIndex: 'fileTitle',
  },
  {
    title: '上传人',
    dataIndex: 'userName'
  },
  {
    title: '上传时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    dataIndex: 'action',
    align: 'center',
    scopedSlots: {customRender: 'operation'}
  }
]

export default {
  data() {
    return {
      pageNum: 1,
      tplColumns,
      columns,
      task : {},
      startTime : '',
      endTime : '',
      deliverableListVisible: false,
      commentFormVisible: false,
      tabFileData: [],
      tabTplData: [],
      relatedCheckKey: [],
      uploadFormVisible: false,
      updateFormVisible: false,
      relateFormVisible: false,
      shareFormVisible: false,
      type:3,
      statusId:'',
      status: 0,
      isAuth: true,
      aclList: [], // 已授权权限列表
      isPermit:false
    };
  },
  created(){
    this.getPermData()
    this.init()
    this.statusId = this.$route.query.taskId
  },
  components: {
    CommentForm,
    UploadForm,
    Trends,
    StatusForm,
    RelateForm,
    ShareForm
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
  },
  filters: {
    dataTimeFilter (val) {
      return val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : ''
    }
  },
  methods: {
    // 查询项目权限
    getPermData() {
      const permits = store.getters.permits
      permits.permissions.forEach(item=>{
        if(item.permissionId==='projectTask'){
          this.isPermit=true
        }
      })
      if(permits.length==0) {
        getUserProjectPermList({id: this.$route.query.projectId}).then((res) => {
          let permitList = {}
          permitList.permissions = res.data.permissions
          permitList.isAuth = res.data.isAuth
          permitList.isManageAuth = res.data.isManageAuth
          permitList.projectId = this.$route.query.projectId

          store.commit('SET_PERMITS',permitList)

          if(permitList.isAuth==0) { // 项目已归档，不授权
            this.isAuth = false
            return
          }

          const accessBtns = permitList.permissions.filter(item=>item.permissionId==='projectTask')[0].actionEntitySet
          this.aclList = accessBtns.map(action => {
                    return action.action
                  })
          console.log('accessBtns==========', this.aclList)
        })
      } else {
          if(permits.isAuth==0) { // 项目已归档，不授权
            this.isAuth = false
            return
          }

          const accessBtns = permits.permissions.filter(item=>item.permissionId==='projectTask')[0].actionEntitySet
          this.aclList = accessBtns.map(action => {
                    return action.action
                  })
      }
    },
    init() {
      const that = this
      this.$nextTick(() => {
          detailTask({id:this.$route.query.taskId}).then(res=>{
              that.task = res.data
              that.status = res.data.status
              that.startTime = that.task.startTime ? moment(that.task.startTime).format('YYYY年MM月DD日') : ''
              that.endTime = that.task.endTime ? moment(that.task.endTime).format('YYYY年MM月DD日') : ''

              // 加载任务资料
              getFileList({projectId:this.$route.query.projectId, refType:5, refId: this.$route.query.taskId, pageSize: 1000}).then(res=>{
                that.tabFileData = res.data.data
              })

              // 加载知识库
              getRelatedWikiList({taskId: this.$route.query.taskId}).then(res=>{
                that.tabTplData = res.data

                // 已关联目录id
                for(var i in that.tabTplData) {
                  that.relatedCheckKey.push(that.tabTplData[i].wikiDicId)
                }
              })
          })
      })
    },
    // 评分
    commentStars(){
      if(!this.aclList.includes('commentTask')) {
        this.$message.warning('抱歉，您暂无评分权限！')
        return
      }
      if(this.task.status!=2) {
        this.$message.warning('工作项必须完成后才能进行评分！')
        return
      }
      if(this.task.score) {
        this.$message.warning('工作项已进行过评分！')
        return
      }
      this.commentFormVisible = true
      this.$nextTick(() => {
        this.$refs.commentForm.init(this.task.id, this.task.score)
      })
    },
    // 修改状态
    updateStatusEvt() {
      this.updateFormVisible = true
      this.$nextTick(() => {
        this.$refs.statusForm.init(this.statusId,this.type,this.status)
      })
    },
    // 上传文件
    addFileEvt() {
      const params = {refType: 5, projectId: this.$route.query.projectId, refId: this.$route.query.taskId}
      this.uploadFormVisible = true
      this.$nextTick(() => {
        this.$refs.uploadForm.init(params)
      })
    },
    // 关联模板
    relateWikiEvt() {
      this.relateFormVisible = true
      this.$nextTick(() => {
        this.$refs.relateForm.init(this.$route.query.taskId, this.relatedCheckKey)
      })
    },
    // 共享文件
     shaerFileEvt(record) {
      this.shareFormVisible = true
      this.$nextTick(() => {
        this.$refs.shareForm.init(this.$route.query.projectId,record.id)
      })
    },
    // 移除文件
    deleteFileEvt(record) {
      const that = this
      deleteFile({id:record.id}).then(res=>{
          if(res.code==10000) {
              that.$message.success({
                  content: '操作成功',
                  duration: 1,
                  onClose: () => {
                      that.init()
                  }
              })
              } else {
              that.$message.error({
                  content: res.msg,
                  duration: 1
              })
          }
      })
    },
    // 下载文件
    downloadFileEvt(record) {
      var type = window._CONFIG['downloadType'];
        if (type == "local") {
          const params = {
            id : record.id,
            fileName : record.fileTitle
          }
          fileDownload(params).then(res=>{
          })
        }
        if(type == "cloud"){
            coludDownloadFile(record.id,'/project/file/file-download');
        }
    },
    // 删除已关联wiki
    deleteWikiEvt(record) {
      const that = this
      deleteRelateWiki({id:record.id}).then(res=>{
          if(res.code==10000) {
              that.$message.success({
                  content: '操作成功',
                  duration: 1,
                  onClose: () => {
                      that.init()
                  }
              })
              } else {
              that.$message.error({
                  content: res.msg,
                  duration: 1
              })
          }
      })
    },
    // 查看知识库目录详情
    viewWikiEvt(record) {
      this.$router.push({ path: '/knowledge/myself/detail',query:{wikiId:record.wikiId} })
    }
  }
};
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
</style>
