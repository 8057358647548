<template>
  <a-modal
      title="个人应用汇总"
      width="950px"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      :maskClosable="false"
      destroyOnClose
  >
    <s-table
        rowKey="userId"
        :data="loadData"
        size="default"
        ref="table"
        :columns="columns"
        :pageNum="pageNum"
    >
    <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
    </s-table>

    <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">关闭</a-button>
      </span>
  </a-modal>
</template>

<script>
import { resourcesMore } from "@/httpsAPI/effect";

const columns = [
   {
    title: '序号',
    scopedSlots: { customRender: 'serial' },
  },
  {
    title: "姓名",
    dataIndex: "userName",
  },
  {
    title: "部门",
    dataIndex: "deptName"
  },
  {
    title: "专利",
    dataIndex: "zl"
  },
  {
    title: "成果库",
    dataIndex: "cgk",
  },
   {
    title: "专家库",
    dataIndex: "zjk",
  },
  {
    title: "市场报告",
    dataIndex: "scbg",
  },
  {
    title: "标准",
    dataIndex: "bz",
  },
   {
    title: "期刊库",
    dataIndex: "qkk",
  },
   {
    title: "文献库",
    dataIndex: "wxk",
  },
   {
    title: "万方数据",
    dataIndex: "wfsj",
  },
   {
    title: "汇总",
    dataIndex: "count",
  }
]
export default {
  data(){
    return{
      columns,
      visible: false,
      confirmLoading: false,
      queryParams: {},
      pageNum: 1,
      loadData:parameter=>{
        this.pageNum = parameter.pageNo
        return resourcesMore(Object.assign(parameter,this.queryParams)).then(res=>{
          return res.data
        })
      }
    }
  },
  methods:{
    handleCancel(e) {
      this.visible = false;
    },
    init() {
      this.confirmLoading = false
      this.visible = true
    }
  }
}
</script>

<style lang="less">
.member-list-wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .list-label{
    line-height: 42px;
  }
}
</style>