<template>
  <a-modal
      title="项目权限配置"
      :visible="visible"
      :confirm-loading="confirmLoading"
      width="900px"
      @cancel="handleCancel"
  >
    <div class="configure-right" :style="{height: '100%'}">
    <div class="left-main" :style="{height: '100%'}">
      <a-list>
          <a-list-item class="list-item" v-for="item in roleList" :key="item.id"  @click="changeRole(item.id)">
            <a-list-item-meta :description="item.defineType===0?'系统默认':'企业自定'">
              <div slot="title" :style="currentRoleId==item.id ? 'color: #1890ff': 'color:rgba(0, 0, 0, 0.65)'" style="cursor:pointer">
                {{item.name}}
                <span class="tree-node-handle" style="z-index:100" v-if="item.defineType===1">
                    <a-tooltip placement="bottom" title="修改">
                      <a-icon type="edit" @click="updateFormEvt(item)" />
                    </a-tooltip>
                    <a-tooltip placement="bottom" title="删除">
                      <a-icon type="minus-circle-o" @click="deleteEvt(item)" />
                    </a-tooltip>
                </span>
              </div>
            </a-list-item-meta>
          </a-list-item>
          <a-list-item class="list-item">
            <a-button type="link" @click="createFormEvt"> + 新建角色</a-button>
          </a-list-item>
        </a-list>
    </div>
    <div class="right-main">
      <div class="right1" v-if="isshow">
        <h2>操作对象</h2>
        <h2 style="margin-left:60px">权限</h2>
        <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange" class="allCheck">
          全选
        </a-checkbox>
      </div>

      <a-form :form="form">
        <a-checkbox-group v-model="checkedList" >
        <div class="right2" v-for="item in permList" :key="item.id">
          <div class="right2-main">
            <p>{{item.name}}</p>
            <div class="right2-checkbox">
                <template v-for="itemSub in item.children">
                  <a-checkbox :key="itemSub.id" :value="itemSub.id" :disabled="currentRoleId==4 || currentRoleId==3">
                  {{itemSub.name}}
                  </a-checkbox>
                </template>
            </div>
          </div>
        </div>
        </a-checkbox-group>
      </a-form>
      <a-button v-if="(currentRoleId!=4 && currentRoleId!=3)  && isshow==true" class="ant-btn ant-btn-primary" :loading="confirmLoading" style="margin-top:20px;margin-bottom: 15px" @click="saveRolePerm">保存</a-button>
    </div>

    <!-- 新建弹窗 -->
    <add-form v-if="addFormVisible" ref="addForm" @refreshDataList="init(projectId)"></add-form>
    <!-- 修改弹窗 -->
    <update-form v-if="updateFormVisible" ref="updateForm" @refreshDataList="init(projectId)"></update-form>
  </div>

  <span slot="footer" class="dialog-footer">
    <a-button @click="visible = false">关闭</a-button>
  </span>
  </a-modal>
</template>

<script>
import { getRoleList, getProjectPermList, deleteRole, savePerm } from "@/httpsAPI/configure";
import AddForm from '../../configure/components/addRole'
import UpdateForm from '../../configure/components/updateRole'

export default {
  name: "settingConfig",
  data(){
    return {
      form: this.$form.createForm(this),
      roleList: [],
      permList: [],
      checkedList: [],
      currentRoleId :0,
      addFormVisible: false,
      updateFormVisible: false,
      visible: false,
      indeterminate: true,
      checkAll: false,
      plainOptions: [],
      confirmLoading: false,
      isshow:false,
      projectId: 0
    }
  },
  components:{
    AddForm,
    UpdateForm,
  },
  methods:{
    init(projectId) {
      this.visible = true
      this.projectId = projectId
      
      this.$nextTick(() => {
          this.form.resetFields();
          this.getRoleData(projectId)
      });
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.plainOptions : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    },
    getRoleData(projectId) {
      getRoleList({rangeType: 1, projectId: projectId}).then((res) => {
        this.roleList = res.data.data
        this.changeRole(this.roleList[0].id)
      });
    },
    getPermData(roleId) {
      getProjectPermList({id: roleId}).then((res) => {
        console.log(res.data,"data")
        if(res.data != null){
          this.isshow = true
        }
        this.permList = res.data.menuNodeList
        this.checkedList = res.data.checkList

        for(var i in this.permList) {
          for(var j in this.permList[i].children) {
            this.plainOptions.push(this.permList[i].children[j].id)
          }
        }
      });
    },
    changeRole(roleId) {
      this.form.resetFields()

      this.currentRoleId = roleId
      this.getPermData(roleId)
    },
    // 新建
    createFormEvt() {
      this.addFormVisible = true
      this.$nextTick(() => {
        this.$refs.addForm.init(1, this.projectId)
      })
    },
    // 修改
    updateFormEvt(record) {
      this.updateFormVisible = true
      this.$nextTick(() => {
        this.$refs.updateForm.init(record)
      })
    },
    // 删除
    deleteEvt (record) {
      const that = this
      this.$confirm({
        title: '系统提示',
        content: '确认删除角色['+record.name+']么?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          deleteRole([record.id]).then(res => {
            that.$message.success({
              content: '操作成功',
              duration: 1,
              onClose: () => {
                that.getRoleData()
              }
            })
          }).catch(error => {
            console.log(error)
          })
        }
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    // 保存角色权限
    saveRolePerm() {
      if(this.checkedList.length==0) {
        this.$message.warning("请选择角色权限进行保存")  
        return
      }

      this.confirmLoading = true;
      const values = {
        roleId : this.currentRoleId,
        ids : this.checkedList
      }

      savePerm(values).then(res=>{
          this.$message.success({
              content: '保存成功',
              duration: 1,
              onClose: () => {
                  this.form.resetFields();
                  this.confirmLoading = false;
              }
          })
      }).catch(error=>{
          console.log(error)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.configure-right{
  position: relative;
}
.left-main{
  width: 20%;
  float: left;
  padding-left: 15px;
  border-right: 1px solid #f0f2f5;
  min-height: 500px;
}
/deep/ .ant-list-item-meta-title {
  font-size: 14px;
  font-weight: normal;
  line-height: 15px;
}
/deep/ .ant-list-item-meta-description {
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
}
.right-main{
  padding-right: 10px;
  width: 75%;
  margin-left: 5%;
  float: left;
  .right1{
    border-bottom: 1px solid rgb(230, 230, 230);
  }
  .right2-main{
    width: 100%;
    clear: both;
    min-height: 60px;
    border-bottom: 1px solid rgb(230, 230, 230);
    position: relative;
    p{
      float: left;
      font-size: 14px;
      width: 100px;
      height: 30px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .right2-checkbox{
      margin-left: 120px;
      margin-top: 6px;
    }
  }
  p{
    height: 50px;
  }
  h2{
    font-size: 14px;
    color: rgb(170, 170, 170);
    display: inline-block;

  }
}
.ant-checkbox-wrapper{
  padding: 12px 0;
}
.allCheck{
  position: absolute;
  right: 10px;
  top: -15px;
}
/deep/ .ant-modal-footer{
  border: none;
}
</style>