<template>
  <a-modal
      title="修改项目名称"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="项目名称">
          <a-input
            placeholder="请输入项目名称"
            v-decorator="['projectName', {initialValue:projectName,  rules: [{ required: true, message: '请输入项目名称' }] }]"
          />
        </a-form-item>
    </a-form>
    <span slot="footer" class="dialog-footer">
      <a-button @click="visible = false">取消</a-button>
      <a-button class="ant-btn ant-btn-primary" :loading="confirmLoading" @click="dataFormSubmit()">确定</a-button>
    </span>
  </a-modal>
</template>

<script>
import { updatePlantime } from "@/httpsAPI/project/overview";
export default {
  data(){
    return {
      visible:false,
      confirmLoading: false,
      projectId:'',
      form: this.$form.createForm(this),
      projectName:''
    }
  },
  methods:{
    dataFormSubmit(e) {
      this.form.validateFields((err, values) => {
          if (err) {
              return;
          }
          this.confirmLoading = true;
          
          values['projectId'] = this.projectId

          console.log('Received values of form: ', values);
          updatePlantime(values).then(res=>{
            this.confirmLoading = false;
            if(res.code==10000) {
              this.$message.success({
                  content: '操作成功',
                  duration: 1,
                  onClose: () => {
                    this.$emit('refreshDataList')
                    // window.location.reload();
                    this.visible = false;
                  }
              })
            }else {
              this.$message.error({
                  content: res.msg,
                  duration: 1
              })
            }
          })
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    init(projectId,projectName){
      this.visible = true;
      this.projectId = projectId
      this.projectName = projectName
    }
  }
}
</script>

<style scoped>

</style>