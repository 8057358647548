<template>
  <a-card>
    <div class="antd-toolbar">
      <div class="antd-toolbar-left">
        <div class="antd-toolbar-item">
          <a-button type="primary" icon="download" @click="batchDownloadFileEvt" >批量下载</a-button>
        </div>
      </div>
      <div class="antd-toolbar-right">
        <div class="antd-toolbar-item">
          <a-input-search v-model="queryParams.name" style="margin-left: 16px; width: 272px;" placeholder="请输入交付物名称进行搜索" @keyup.enter.native="$refs.table.refresh(true)" />
        </div>
      </div>
    </div>
    <s-table
      rowKey="id"
      :data="loadData"
      size="default"
      ref="table"
      :columns="columns"
      :alert="alert"
      :rowSelection="rowSelection"
    >
      <template slot="dataTime" slot-scope="text">
        {{text|dataTimeFilter}}
      </template>
      <template slot="status" slot-scope="text,record">
        <a-tag color="red" v-if="record.status===0">未提交</a-tag>
        <a-tag color="green" v-if="record.status===1">已提交</a-tag>
      </template>
      <template slot="deliverableType" slot-scope="text, record">
          <span v-if="record.type===1">网页链接</span>
          <span v-if="record.type===2">文件</span>
          <span v-if="record.type===3">知识库</span>
      </template>
     
      <template slot="action" slot-scope="text, record">
        <a href="javascript:void(0)" class="table-operation-action" v-if="record.status===1" @click="showDeleteHandleClick(record)" v-permit:deleteDeliverable="{projectId:$route.query.projectId}">删除</a>
        <a href="javascript:void(0)" class="table-operation-action" v-if="record.status===1" @click="downloadFileEvt(record)" >下载</a>
      </template>
    </s-table>
  </a-card>
</template>

<script>
import moment from 'moment'
import {getProjectManageDeliverablesList, deleteDeliverables, batchDownload, fileDownload} from "@/httpsAPI/project/deliverables";
import {coludDownloadFile } from '@/httpsAPI/common'

const columns=[
  {
    title:'文件名称',
    dataIndex:'name'
  },
  {
    title: '所属评审点',
    dataIndex: 'planName'
  },
  {
    title: '上传人',
    dataIndex: 'uploadByUserName'
  },
  {
    title: '文件类型',
    dataIndex: 'type',
    scopedSlots: {customRender: 'deliverableType'}
  },
  {
    title: '上传时间',
    dataIndex: 'uploadTime',
    scopedSlots:{customRender:"dataTime"} 
  },
  {
    title:'交付情况',
    dataIndex: 'status',
    scopedSlots: {customRender: 'status'}
  },
  {
    title: '操作',
    dataIndex: 'action',
    align: 'center',
    scopedSlots: {customRender: 'action'}
  }
]
export default {
  name: "taskData",
  data(){
    return {
      form: this.$form.createForm(this, { name: 'searchProject' }),
      columns,
      selectedRowKeys:[],
      selectedRows:[],
      queryParams:{},
      loadData:parameter=>{
        this.queryParams.projectId = this.$route.query.projectId

        return getProjectManageDeliverablesList(Object.assign(parameter, this.queryParams)).then(res=>{
          return res.data
        })
      },
      alert:{
        show: false, clear: () => { this.selectedRowKeys = [] }
      },
      rowSelection:{
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  filters: {
    dataTimeFilter (val) {
      return val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : ''
    }
  },
  methods:{
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    showDeleteHandleClick(record){
      const that = this
      this.$confirm({
        title: '删除交付物?',
        content: '确定删除交付物['+record.name+']么？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deleteDeliverables(record.id).then(res => {
            that.$message.success({
                  content: '操作成功',
                  duration: 1,
                  onClose: () => {
                    that.$refs.table.refresh(true)
                  }
              })
          }).catch(error => {
              console.log(error)
          })
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    // 批量下载
    batchDownloadFileEvt() {
      if(this.selectedRows.length==0) {
          this.$message.warning('请选择要下载的数据')
          return
      }

      batchDownload('batch_deliverable_file.zip', this.selectedRowKeys).then(res=>{
      })
    },
    // 下载文件
    downloadFileEvt(record) {
      var type = window._CONFIG['downloadType'];
      if (type == "local") {
        const params = {
          id : record.id,
          fileName : record.name + record.linkUrl.substring(record.linkUrl.lastIndexOf('.'))
        }
        fileDownload(params).then(res=>{
        })
      }
       if(type == "cloud"){
        coludDownloadFile(record.id,'/project/deliverables/file-download');
      }
    }
  }
}
</script>