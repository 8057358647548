<template>
  <a-modal title="修改知识库" :visible="visible" :confirm-loading="confirmLoading" @cancel="handleCancel" destroyOnClose>
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @keyup.enter.native="dataFormSubmit()">
      <a-form-item label="知识名称">
        <a-input
          v-decorator="['wikiName', {initialValue:dataForm.wikiName, rules: [{required: true, message: '请输入知识库名称'}]}]"
          placeholder="请输入知识库名称" />
      </a-form-item>
      <a-form-item label="知识库分类">
        <a-select
          :filter-option="false" 
          show-search
          :allowClear="true"
          placeholder="请选择分类"
          v-decorator="['entTypeId', { initialValue:dataForm.entWikiTypeName,rules: [{ message: '请选择分类' }] }]"
          @change="handleChange"
          >
            <a-select-option v-for="item in wikiTypeEntList" v-bind:key="item.id" :value="item.id">
                {{item.name }}
            </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="描述">
        <a-textarea placeholder="请输入描述" :rows="4"
          v-decorator="['content',  {initialValue:dataForm.wikiName, rules: [ { max:150, message: '描述' }] }]" />
      </a-form-item>
    </a-form>

    <span slot="footer" class="dialog-footer">
      <a-button @click="visible = false">取消</a-button>
      <a-button class="ant-btn ant-btn-primary" @click="dataFormSubmit()">确定</a-button>
    </span>
  </a-modal>
</template>
<script>
import { updateKnowList,getWikiTypeEntListAll } from "@/httpsAPI/knowledgeBase/index";

export default {
  data () {
    return {
      form: this.$form.createForm(this),
      visible: false,
      confirmLoading: false,
      id:'',
      wikiTypeEntList:[],
      dataForm: {
        id: '',
        wikiName: '',
        content: '',
        type: '',
        userName:'',
        wikiTypeName:''
      }
    }
  },
  created(){
    this.changeMasterList()
  },
  methods: {
    // 分类列表
    handleChange(value) {
      this.changeMasterList()
    },
    changeMasterList() {
      getWikiTypeEntListAll()
        .then(res => {
            this.wikiTypeEntList = res.data;
        })
    },
    dataFormSubmit (e) {
      this.confirmLoading = true;
      this.form.validateFields((err, values) => {
        if (err) {
          return;
        }

        values['type'] = this.dataForm.type
        values['id'] = this.dataForm.id
        console.log('Received values of form: ', values);
        updateKnowList(values).then(res => {
          if (res.code == 10000) {
            this.$message.success({
              content: '操作成功',
              duration: 1,
              onClose: () => {
                this.form.resetFields();
                this.visible = false;
                this.$emit('refreshDataList')
              }
            })
          } else {
            this.$message.error({
              content: res.msg,
              duration: 1
            })
          }

        })
      });
    },
    handleCancel (e) {
      this.visible = false;
    },
    init (record) {
      this.visible = true
      this.$nextTick(() => {
        this.form.resetFields();
        this.dataForm.id = record.id
        this.dataForm.type = record.type
        this.dataForm.wikiName = record.wikiName
        this.dataForm.content = record.content
        this.dataForm.userName = record.userName
        this.dataForm.wikiTypeName = record.wikiTypeName
        this.dataForm.entWikiTypeName = record.entWikiTypeName
      });
    }
  }
}
</script>