<template>
  <div class="antd-page-content">
    <div class="antd-panel">
      <a-form :form="form" :label-col="{ span: 7 }" :wrapper-col="{ span: 10 }">
        <a-form-item label="课程名称">
          <a-input placeholder="请输入课程名称"
            v-decorator="['courseName', { rules: [{ required: true, message: '请输入课程名称' }] }]" />
        </a-form-item>
        <a-form-item label="课程图片" help="允许上传图片的格式为jpg、jpeg、gif、bmp、png, 建议尺寸大小：122 x 98，图像4MB以内">
           <a-upload
            v-decorator="['courseImageUrl']"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :customRequest="customUploadRequest"
          >
            <img v-if="courseImageUrl" :src="courseImageUrl" alt="avatar" style="width:100%;height:100%" />
            <div v-else>
              <a-icon :type="loadingFile ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传
              </div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="是否有试题">
          <a-switch checked-children="是" un-checked-children="否" :defaultChecked="hasTest" @change="changeTest" />
        </a-form-item>
        <a-form-item label="考试通过分数">
          <a-input placeholder="请输入考试通过分数"
            v-decorator="['examPassScore', {rules: [{ required: false, message: '请输入考试通过分数', whitespace: true}, {trigger: 'blur' }]}]" />
        </a-form-item>
        <a-form-item label="课程介绍">
          <j-editor v-decorator="['introduction', {rules: [{  required: true, message: '请输入课程介绍'}]}]" triggerChange>
          </j-editor>
        </a-form-item>
      </a-form>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button @click="cancel">取消</a-button>
        <a-button type="primary" style="margin-left: 8px" @click="dataFormSubmit()">保存</a-button>
      </a-form-item>
    </div>
  </div>
</template>
<script>
import JEditor from '@/components/Editor/JEditor'
import { uploadImg } from '@/httpsAPI/common/index'
import { addCourseList, getCourseType } from '@/httpsAPI/course/index'

export default {
  data () {
    return {
      courseImageUrl:'',
      form: this.$form.createForm(this),
      visible: false,
      confirmLoading: false,
      courseTypeDate: [],
      hasTest: false,
      loadingFile:false
    }
  },
  components: {
    JEditor
  },
  methods: {
    //图片上传前检验
    beforeUpload (file) {
      console.log('beforeUpload')
      console.log(file)
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('仅支持图片上传');
      }
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        this.$message.error('图片不能大于4MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    customUploadRequest(option){
      const formData = new FormData() 
      formData.append('file', option.file)
      formData.append('channel', 2)
      this.saveFile(formData)
    },
    saveFile (formData) {
      console.log('saveFile')
      console.log(formData,"formData")
      this.loadingFile = true
      uploadImg(formData).then(res=>{
        console.log(res,"res")
          if (res.code == 10000) { 
            this.courseImageUrl = this.$imgBaseUrl + res.data.name
            this.loadingFile = false           
            this.form.setFieldsValue({
              courseImageUrl: res.data.name
            })
          } else {  
            this.$message.error(res.msg)  
          }
      })
    },
    //试题
    changeTest (checked) {
      this.hasTest = checked
    },
    dataFormSubmit (e) {
      this.confirmLoading = true;
      this.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        // 是否有考试 1有 0无考试
        if (this.hasTest) {
          values['isExam'] = 1
        } else {
          values['isExam'] = 0
        }
        addCourseList(values).then(res => {
          if (res.code == 10000) {
            this.$message.success({
              content: '操作成功',
              duration: 1,
              onClose: () => {
                this.confirmLoading = false;
                this.$emit('refreshDataList')
                this.$router.back()
              }
            })
          } else {
            this.$message.error({
              content: res.msg,
              duration: 1
            })
          }

        })
      })
    },
    cancel () {
      this.$router.back()
    }
  },
  created () {
  }
}
</script>