<template>
  <div class="configure-right" :style="{height: '100%'}">
    <h2 style="padding:20px;">组织配置</h2>
    <a-row class="wrapper" type="flex" :gutter="16">
      <a-col :span="6">
        <div class="tool-tip">
          <a-button class="ant-btn ant-btn-primary" @click="createRootNodeEvt()">+ 新建组织部门</a-button>
          <br /><br />
        </div>

        <!-- 新增部门弹窗 -->
        <add-form v-if="addFormVisible" ref="addForm" @refreshDataList="loadTreeData"></add-form>
        <!-- 修改弹窗 -->
        <update-form v-if="updateFormVisible" ref="updateForm" @refreshDataList="loadTreeData"></update-form>
        <!-- 新增用户弹窗 -->
        <addUser-form v-if="addUserFormVisible" ref="addUserForm" @refreshDataList="$refs.table.refresh(true)"></addUser-form>
        <!-- 修改用户弹窗 -->
        <updateUser-form v-if="updateUserFormVisible" ref="updateUserForm" @refreshDataList="$refs.table.refresh(true)"></updateUser-form>

        <a-tree :show-line="showLine" :tree-data="treeData" :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent" @expand="onExpand">
          <template slot="custom" slot-scope="item">
            <span @mouseover="handleOver(item)" @mouseout="handleOut(item)">
              <span v-if="item.title.indexOf(searchValue) > -1" @click="onSelectNode(item)">
                {{ item.title.substr(0, item.title.indexOf(searchValue)) }}<span
                  style="color: #f50">{{searchValue}}</span>{{item.title.substr(item.title.indexOf(searchValue) + searchValue.length)}}
              </span>
              <span v-else @click="onSelectNode(item)">{{item.title}}</span>
              <span class="tree-node-handle" style="z-index:100">
                <a-tooltip placement="bottom" title="新增下级分类">
                  <a-icon type="plus-circle-o" @click="createSubNodeEvt(item)" />
                </a-tooltip>
                <a-tooltip placement="bottom" title="修改分类">
                  <a-icon type="edit" @click="updateNodeEvt(item)" />
                </a-tooltip>
                <a-tooltip placement="bottom" title="删除分类">
                  <a-icon type="minus-circle-o" @click="deleteNodeEvt(item)" />
                </a-tooltip>
              </span>
            </span>
          </template>
        </a-tree>
      </a-col>
      <a-col :span="18">
        <div class="page-search-wrap">
          <a-form layout="inline" style="position:relative;">
                <!-- <h2 style="margin-top:-48px;position:absolute;right:0;top:0;">成员列表</h2> -->
                <a-button class="ant-btn ant-btn-primary" v-if="isUser" @click="addUserEvt()" style="margin-top:-48px;position:absolute;left:0;top:0;">+ 新建成员</a-button>
            <a-row :gutter="20">
              <a-col :md="8" :sm="24">
                <a-form-item label="成员名">
                  <a-input v-model="queryParams.name" placeholder="请输入成员名"/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQueryForm">重置</a-button>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="antd-table-wrap">
          <s-table ref="table" rowKey="id" :columns="columns" :data="loadData" :pageNum="pageNum">
            <template slot="serial" slot-scope="text, record, index">
              {{ index + 1 }}
            </template>
            <template slot="createTime" slot-scope="text">
              {{text|createTimeFilter}}
            </template>
            <template slot="action" slot-scope="text,record">
              <a href="javascript:void(0)" class="table-operation-action" @click="updateUserFormEvt(record)">编辑</a>
              <a-popconfirm
                title="确定删除此数据么？"
                ok-text="确定"
                cancel-text="取消"
                placement="topLeft"
                @confirm="deleteUserEvt(record)"
              >
                <a-icon slot="icon" type="question-circle-o" style="color: red" />
                <a href="javascript:void(0)" class="table-operation-action">删除</a>
              </a-popconfirm>
            </template>
          </s-table>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
import { getDeptTree, delDeptTree, getUserlist, delUser } from '@/httpsAPI/configure/index'
import AddForm from '../orgSet/add'
import UpdateForm from '../orgSet/update'
import AddUserForm from '../userSet/add'
import updateUserForm from '../userSet/update'

const treeData = [];
const getParentKey = (title, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.title === title)) {
        parentKey = node.key;
      } else if (getParentKey(title, node.children)) {
        parentKey = getParentKey(title, node.children);
      }
    }
  }

  return parentKey;
};
const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const key = node.key;
    dataList.push({ key, title: node.title });
    if (node.children) {
      generateList(node.children);
    }
  }
}

const columns = [
  { title: "序号", dataIndex: "serial", scopedSlots: { customRender: 'serial' }, width: 80 },
  { title: "姓名", dataIndex: "name" },
  { title: "邮箱", dataIndex: "email" },
  { title: "部门", dataIndex: "orgName" },
  { title: "系统角色", dataIndex: "rolesList" },
  { title: "操作", dataIndex: "action", align: 'center', scopedSlots: { customRender: "action" }, width: "158px" }
]

export default {
  data () {
    return {
      isUser:false,
      addUserFormVisible:false,
      updateUserFormVisible:false,
      form: this.$form.createForm(this),
      showLine: true,
      expandedKeys: [],
      searchValue: '',
      autoExpandParent: true,
      treeData,
      addFormVisible: false,
      updateFormVisible: false,
      parentId: 0,
      columns,
      pageNum: 1,
      queryParams: {},
      courseNo:'',
      courseName:'',
      deptId: 0,
      sortFormVisible: false,
      loadData:parameter => {
        this.pageNum = parameter.pageNo
        return getUserlist(Object.assign(parameter,this.queryParams,{deptId:this.deptId})).then(res=>{
          return res.data
        })
      },
    }
  },
  components: {
    AddForm,
    UpdateForm,
    AddUserForm,
    updateUserForm
  },
  filters: {
    createTimeFilter (value) {
      return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
    }
  },
  created () {
    this.loadTreeData()
    this.pageSize = 50
  },
  methods: {
    // 重置搜索form
    resetQueryForm() {
      this.queryParams = {}
      this.$refs.table.refresh(true)
    },
    // 新增一级节点
    createRootNodeEvt () {
      this.addFormVisible = true
      this.$nextTick(() => {
        this.$refs.addForm.init(this.parentId)
      })
    },
    // 新建子节点
    createSubNodeEvt (item) {
      this.addFormVisible = true
      this.$nextTick(() => {
        this.$refs.addForm.init(item.key)
      })
    },
    // 修改节点
    updateNodeEvt (item) {
      this.updateFormVisible = true
      this.$nextTick(() => {
        this.$refs.updateForm.init(item.key)
      })
    },
    // 删除节点
    deleteNodeEvt (item) {
      const that = this
      this.$confirm({
        title: '系统提示',
        content: '确认删除此部门[' + item.title + ']?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk () {

          delDeptTree([item.key]).then(res => {
            if(res.code == '11111'){
              that.$message.error({
                content: res.msg,
                duration: 1,
                })
            }else{
                that.$message.success({
                  content: '操作成功',
                  duration: 1,
                  onClose: () => {
                    that.loadTreeData()
                  }
                })
            }
          }).catch(error => {
            console.log(error)
          })
        },
        onCancel () {
          console.log('Cancel');
        },
      });
    },
    //新增用户
    addUserEvt (item) {
      this.addUserFormVisible = true
      this.$nextTick(() => {
        this.$refs.addUserForm.init(this.deptId)
      })
    },
    //用户编辑
    updateUserFormEvt(item){
      this.updateUserFormVisible = true
      this.$nextTick(() => {
        this.$refs.updateUserForm.init(item.id)
      })
    },
    //删除用户
    deleteUserEvt(item) {
      const that = this
      if (item.userType === 1) {
        this.$message.warning("企业默认管理员用户无法删除！！")  
        return
      }
      delUser([item.id]).then(res=>{
        if(res.code==10000) {
            that.$message.success({
                content: '操作成功',
                duration: 1,
                onClose: () => {
                    that.$refs.table.refresh(true)
                }
            })
            } else {
            that.$message.error({
                content: res.msg,
                duration: 1
            })
            }
        })
    },
    handleOver (item) {
      item.dataRef.isShow = true
    },
    handleOut (item) {
      item.dataRef.isShow = false
    },
    // 选择树形节点
    onSelectNode (item) {
      this.isUser=true
      this.deptId = item.key
      this.$refs.table.refresh(true)
    },
    filterTreeData (arr) {
      arr.map(t => {
        if (t.children && t.children.length) {
          this.filterTreeData(t.children)
        }
        return this.$set(t, 'isShow', false)
      })
    },
    onSearch (e) {
      const value = e.target.value;
      const expandedKeys = dataList
        .map(item => {
          if (item.title.indexOf(value) > -1 && value != '') {
            return getParentKey(item.title, this.treeData);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      Object.assign(this, {
        expandedKeys,
        searchValue: value,
        autoExpandParent: true,
      });
    },
    onExpand (expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    loadTreeData () {
      getDeptTree().then(res => {
        this.treeData = res.data

        this.filterTreeData(this.treeData)
        generateList(this.treeData);
      }).catch(error => {
        console.log(error)
      })
    },
  }
}
</script>

<style lang="less">
.wrapper {
  height: 100%;
  margin: 0 !important;
}
.tree-node-title {
  display: inline-block;
  margin-right: 8px;
}
.tree-node-handle {
  .anticon {
    margin: 4px;
  }
}
.configure-right {
  position: relative;
  .submit-container {
    position: absolute;
    top:350px;
    // bottom: 80px;
    left: 135px;
  }
}
</style>