<template>
  <div class="antd-panel">
    <p @click="readMsg"><a-icon type="close-circle" theme="filled" />一键已读</p>
    <a-tabs default-active-key="0" @change="callback" v-model="type">
      <a-tab-pane :key="3" tab="工作项">
         <a-list
          item-layout="vertical"
          size="large"
          :pagination="pagination"
          :data-source="messageList"
        >
          <a-list-item slot="renderItem" slot-scope="item" @click="msgDetail(item)">
            <a-row type="flex">
              <a-list-item-meta :description="item.msgContent">
                <a slot="title" class="readFlag-box">{{ item.titile }}<span v-if="item.readFlag==0" class="readFlag"></span></a>
              </a-list-item-meta>
              <a-row>
                <a-col :span="24"> {{ item.createTime }}</a-col>
              </a-row>
            </a-row>
          </a-list-item>
        </a-list>
      </a-tab-pane>
      <a-tab-pane :key="1" tab="系统公告">
        <a-list
          item-layout="vertical"
          size="large"
          :pagination="pagination"
          :data-source="messageList"
        >
          <a-list-item slot="renderItem" slot-scope="item" @click="msgDetail(item)">
            <a-row type="flex">
              <a-list-item-meta :description="item.msgContent">
                <a slot="title" class="readFlag-box">{{ item.titile }}<span v-if="item.readFlag==0" class="readFlag"></span></a>
              </a-list-item-meta>
              <a-row>
                <a-col :span="24"> {{ item.createTime }}</a-col>
              </a-row>
            </a-row>
          </a-list-item>
        </a-list>
      </a-tab-pane>
      <a-tab-pane :key="2" tab="系统消息" force-render>
        <a-list
          item-layout="vertical"
          size="large"
          :pagination="pagination"
          :data-source="messageList"
        >
          <a-list-item slot="renderItem" slot-scope="item" @click="msgDetail(item)">
            <a-row type="flex">
              <a-list-item-meta :description="item.msgContent">
                <a slot="title" class="readFlag-box">{{ item.titile }}<span v-if="item.readFlag==0" class="readFlag"></span></a>
              </a-list-item-meta>
              <a-row>
                <a-col :span="24"> {{ item.createTime }}</a-col>
              </a-row>
            </a-row>
          </a-list-item>
        </a-list>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import { getMessageQueryAllList,readMsg } from "../../httpsAPI/search";
export default {
  data() {
    return {
      type:3,
      messageList: [],
      pageSize: 10,
      pageIndex: 1,
      pagination: {
        onChange: (page) => {
          console.log(page)
          this.pageIndex = page
          this.msgList(this.type)
        },
        pageSize: 10,
        total:0,
        showSizeChanger:false,
        current:1
      },
    };
  },
  created(){
    this.msgList(3)
  },
  methods: {
    //一键已读
    readMsg(){
      readMsg().then((res)=>{
        console.log(res.code)
        if(res.code=="10000" || res.code==10000){
          location.reload()
        }
      })
    },
    //消息列表
    callback(t) {
      this.type = t;
      this.pageIndex = 1;
      this.messageList=[],
      this.msgList(t)
    },
    msgList(type){
      getMessageQueryAllList({
        pageNo: this.pageIndex,
        pageSize: this.pageSize,
        msgCategory:type
      }).then((res) => {
        this.messageList = res.data.data;
        console.log(res.data)
        this.pagination.pageSize = this.pageSize;
        this.pagination.total = res.data.totalCount;
        this.pagination.showTotal = () => `共 ${res.data.totalCount} 条`;
        this.pagination.showSizeChanger = true;
        this.pagination.current = this.pageIndex;
      });
    },
    //消息详情
    msgDetail(item){
      this.$router.push({path:"/workplace/message/detail",query:{msgId:item.id}})
    }
  },
};
</script>
<style lang="less" scoped>
.antd-panel{
  position: relative;
  p{
    position: absolute;
    top: 32px;
    right: 22px;
    background: rgb(252, 73, 73);
    padding: 5px 10px;
    color: #fff;
    z-index: 99;
    cursor: pointer;
  }
}
.new_page {
  padding: 20px;
  min-height: calc(100vh - 64px - 140px);
}
.message_page {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
}
.readFlag-box{
  position: relative;
}
.readFlag{
  display: inline-block;
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: 3px;
}
</style>
