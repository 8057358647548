<template>
  <a-modal
      title="登记工时"
      :visible="visible"
      :width="720"
      :maskClosable="false"
      destroyOnClose
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
      <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-item label="任务名称">
          <a-select
              :filter-option="false"
              show-search
              :allowClear="true"
              placeholder="请选择任务名称"
              v-decorator="['taskIdSel', { rules: [{ required: true, message: '请选择任务名称' }] }]"
              @search="handleSearchTask"
            >
              <a-select-option
                v-for="(item, index) in taskList"
                v-bind:key="index"
                :value="index"
              >
                {{ item.title }}<span style="color:red;margin-left:3px">({{item.projectName}})</span>
              </a-select-option>
            </a-select>
        </a-form-item>
        
        <a-form-item label="负责人">
           <a-select
                :filter-option="false" 
                show-search
                :allowClear="true"
                placeholder="请选择或搜索负责人"
                v-decorator="['masterId', { rules: [{ required: true, message: '请选择负责人' }] }]"
                @search="handleSearchUser"
                disabled
                >
                    <a-select-option v-for="item in masterList" v-bind:key="item.id" :value="item.id">
                        {{item.name }}
                    </a-select-option>
            </a-select>
        </a-form-item>

        <a-form-model-item label="预估工时">{{estimate ? estimate : '暂无'}}</a-form-model-item>
        <a-form-model-item label="剩余工时">{{leftTime ? leftTime : '暂无'}}</a-form-model-item>

        <a-form-item label="开始时间">
          <a-date-picker format="YYYY-MM-DD HH:mm:ss" :show-time="{ format: 'HH:mm:ss' }" style="width:100%"  v-decorator="['endTimeRange', {rules: [{ required: true, message: '请输入开始时间'}]}]" @change="dateChange"/>
        </a-form-item>

        <a-form-model-item label="实际投入时长">
            <a-input-group compact>
              <a-input-number style="width: 75%" 
              v-decorator="[
                    'realTime',
                    {rules: [{ required: true, message: '请输入实际投入时长'}]}
                  ]"
              />
              <a-input style="width: 25%" default-value="小时" disabled />
            </a-input-group>
          </a-form-model-item>

        <a-form-model-item label="剩余工时">
            <a-input-group compact>
              <a-input style="width: 75%" 
              v-decorator="[
                    'leftTime',
                    {rules: [{ message: '请输入剩余工时'}]}
                  ]"
              />
              <a-input style="width: 25%" default-value="小时" disabled />
            </a-input-group>
          </a-form-model-item>

         <a-form-item label="描述">
            <a-textarea placeholder="请输入描述" :rows="4" v-decorator="['content']"/>
        </a-form-item>
      </a-form>
      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">取消</a-button>
        <a-button class="ant-btn ant-btn-primary" :loading="confirmLoading" @click="dataFormSubmit()">确定</a-button>
      </span>
    </a-modal>
</template>
<script>
    import store from '@/store'
    import {getCompanyUserListAll} from "@/httpsAPI/common";
    import { taskList } from "@/httpsAPI/workplace/working";
    import { add } from "@/httpsAPI/workplace/working";
    
    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                visible: false,
                confirmLoading: false,
                timeRange:[],
                startTime:'',
                scrollPage: 1,
                keyword: '',
                masterList: [],
                masterData: [],
                taskList: [],
                taskData: [],
                projectId: 0,
                taskId : 0,
                estimate: 0,
                leftTime: 0
            }
        },
        methods:{
          // 成员下拉框 分页 搜索
          changeMasterList() {
            getCompanyUserListAll({ name: this.keyword }).then((res) => {
              this.masterList = res.data;
              this.masterData = res.data;

              this.form.setFieldsValue({
                masterId: store.getters.userinfo.userId
              })
            });
          },
          // 工作项下拉框 分页 搜索
          changeTaskList() {
            taskList({ title: this.keyword,pageSize:150,pageNo:1 }).then((res) => {
              this.taskList = res.data.data;
              this.taskData = res.data;
            });
          },
          //选择下拉框滚动事件
          handleTaskPopupScroll(e) {
            const { target } = e;
            const scrollHeight = target.scrollHeight - target.scrollTop;
            const clientHeight = target.clientHeight;
            // 下拉框不下拉的时候
            if (scrollHeight === 0 && clientHeight === 0) {
              this.scrollPage = 1;
            } else {
              // 当下拉框滚动条到达底部的时候
              if (scrollHeight < clientHeight + 5) {
                console.log("123")
                this.pageData(1, "task");
              } else if (scrollHeight == 3208) {
                this.pageData(2, "task");
              }
            }
          },
          //选择下拉框滚动事件
          handleUserPopupScroll(e) {
            const { target } = e;
            const scrollHeight = target.scrollHeight - target.scrollTop;
            const clientHeight = target.clientHeight;
            console.log(scrollHeight,clientHeight,"clientHeight")
            // 下拉框不下拉的时候
            if (scrollHeight === 0 && clientHeight === 0) {
              this.scrollPage = 1;
            } else {
              // 当下拉框滚动条到达底部的时候
              if (scrollHeight < clientHeight + 5) {
                this.pageData(1, "user");
              } else if (scrollHeight == 3208) {
                this.pageData(2, "user");
              }
            }
          },

          handleSearchTask(keyword) {
            this.keyword = keyword;
            this.changeTaskList();
          },
          handleSearchUser(keyword) {
            this.keyword = keyword;
            this.changeMasterList();
          },

          pageData(type, fieldType) {
            if (fieldType === "user") {
              if (this.masterData.totalPage < this.scrollPage) {
                this.scrollPage = this.masterData.totalPage;
              } else {
                type == 1 ? this.scrollPage++ : this.scrollPage--;
                this.changeMasterList();
                let newData = [];
                let max = this.masterData.totalCount;
                this.masterList.forEach((item, index) => {
                  //当data数组的下标小于max时
                  if (index < max) {
                    newData.push(item);
                  }
                });
                this.masterList = newData;
              }
            } else if (fieldType === "task") {
              if (this.taskData.totalPage < this.scrollPage) {
                this.scrollPage = this.taskData.totalPage;
              } else {
                type == 1 ? this.scrollPage++ : this.scrollPage--;
                this.changeTaskList();
                let newData = [];
                let max = this.taskData.length;
                this.taskList.forEach((item, index) => {
                  //当data数组的下标小于max时
                  if (index < max) {
                    newData.push(item);
                  }
                });
                this.taskList = newData;
              }
            }
          },
          dateChange(date, dateString) {
            this.startTime = dateString
          },
          dataFormSubmit(e) {
            this.form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                this.confirmLoading = true;
                values['startTime'] = this.startTime
                values['projectId'] = this.projectId
                values['taskId'] = this.taskId

                console.log('Received values of form: ', values);
                add(values).then(res=>{
                  this.confirmLoading = false;
                  if(res.code==10000) {
                    this.$message.success({
                        content: '操作成功',
                        duration: 1,
                        onClose: () => {
                            this.form.resetFields();
                            this.visible = false;
                            this.$emit('refreshDataList')
                        }
                    })
                  }else {
                    this.$message.error({
                        content: res.msg,
                        duration: 1
                    })
                  }
                })
            });
          },
          handleChangeTask(index) {
            if(this.taskList[index]) {
              this.projectId = this.taskList[index].projectId
              this.taskId = this.taskList[index].id
              this.estimate = this.taskList[index].estimate
              this.leftTime = this.taskList[index].leftTime
            } 
          },
          handleCancel(e) {
            this.visible = false;
          },
          init (projectId) {
            this.confirmLoading = false
            this.visible = true
            this.projectId = projectId
            this.$nextTick(() => { 
                this.form.resetFields();

                this.changeMasterList();
                this.changeTaskList();
            });
          }
        }
    }
</script>