<template>
  <div class="antd-table-search-wrap layout-content-view">
      <!-- 视频弹框 -->
    <study v-if="studyFormVisible" ref="study" @refreshDataList="$refs.table.refresh(true)" />
    <!-- <a-button style="position: absolute; top:20px; right:15px;z-index:100" type="primary" @click="$router.back()">返回</a-button> -->
    <a-button style="position: absolute; top:20px; right:15px;z-index:100" type="primary" @click="goBack">返回</a-button>
    <a-card :bordered="false">
      <a-row type="flex" :gutter="32">
        <a-col flex="450px">
          <img style="width: 100%;" v-if="dataForm.courseImageUrl!=null" :src="$imgBaseUrl + dataForm.courseImageUrl"  class="box-img">
        </a-col>
        <a-col flex="auto" class="preview-info">
          <a-row type="flex">
            <div class="name">{{dataForm.courseName}}</div>   
          </a-row>
          <a-row type="flex">
            <a-col flex="auto">课程分类：{{dataForm.courseTypeName}}
            </a-col>
          </a-row>
          <a-row type="flex">
            <a-col :span="12">考试分数：{{dataForm.score}}</a-col>
            <a-col flex="auto">考试通过分数：{{dataForm.examPassScore}}</a-col>
          </a-row>
          <a-row type="flex">
            <a-col :span="12">有无考题：{{dataForm.isExam ? '有' : '无'}}</a-col>  
          </a-row>
          <a-row type="flex">
            <a-icon type="youtube" style="font-size:50px;vertical-align: middle;color:#1890ff;" class="icon" @click="videoEvt()" />
          </a-row>
        </a-col>
      </a-row>
    </a-card>
    <a-card  :bordered="false" style="margin-top: 12px">
      <div>
        <h2>课程介绍</h2>
        <div v-html="dataForm.introduction"></div>
      </div>
    </a-card>
  </div>
</template>
<script>
import moment from 'moment'
import {detailCourse} from '@/httpsAPI/course/index';
import Study from './study'

export default {
  data() {
    return {
      pageNum: this.$route.query.pageNum,
      studyFormVisible:false,
      courseId: 0,
      courseNo:'',
      dataForm:{
        courseId:null,
        courseName:'',
        courseTypeName:"",
        score:'',
        examPassScore:'',
        isExam:'',
        introduction:'',
        courseImageUrl:'',
        courseNo:''
      },
      targetOffset: undefined,
    };
  },
  components:{
    Study
  },
  created(){
    this.courseId = this.$route.query.courseId
    this.detailCourse()
  },
  methods:{
    goBack(){
      this.$emit('refreshDataList',this.pageNum)
      this.$router.back()
    },
    //详情
    detailCourse(){
      detailCourse(this.courseId).then(res=>{
        this.dataForm.courseName = res.data.courseName
        this.dataForm.courseTypeName = res.data.courseTypeName
        this.dataForm.score = res.data.score
        this.dataForm.examPassScore = res.data.examPassScore
        this.dataForm.isExam = res.data.isExam
        this.dataForm.introduction = res.data.introduction
        this.dataForm.courseImageUrl = res.data.courseImageUrl
        this.courseNo = res.data.courseNo
      })
    },
    //视频播放
    videoEvt(){
      this.$router.push({path:"/personalDevelopment/course/study", query:{courseId: this.courseId} })
    }
  }
};
</script>
<style lang="less" scoped>
  .box-img{
    position: relative;
  }
  // .icon{
  //   position: absolute;
  //   right: 20px;
  //   bottom:0;
  //   z-index: 50;
  // }
  .preview-info{
    flex: 0 0 55% !important;
    line-height: 38px;
    .name {
      font-size:18px;
      font-weight:bold;
    }
    .price {
      font-size:16px;
      font-weight: bold;
      color: #f60;
    }
    .number{
      font-size:16px;
      font-weight: bold;
      color: #666;
    }
    .btn-study{
      display: inline-block;
      color: #fff;
      font-size: 18px;
      border-radius: 4px;
      background-color: #f60;
      padding: 10px 30px;
    }
  }
</style>