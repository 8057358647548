<template>
  <a-card>
    <a-table :columns="columns" :pagination="pagination" :data-source="data">
    </a-table>
  </a-card>
</template>

<script>
import { getSysLevelVersion } from "../../httpsAPI/search";

const columns = [
  {
    title: "版本号",
    dataIndex: "versionName",
    key: "versionName",
  },
  {
    title: "版本介绍",
    dataIndex: "contract",
    key: "contract",
  },
  {
    title: "发布时间",
    key: "publishTime",
    dataIndex: "publishTime",
  },
];
export default {
  name: "workday",
  data() {
    return {
      columns,
      data: [],
      pageSize: 10,
      pageIndex: 1,
      pagination: {
        onChange: (page) => {
          this.pageIndex = page;
          this.initData();
        },
        pageSize: 10,
        total: 0,
        showSizeChanger: false,
        current: 1,
      },
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      getSysLevelVersion({
        pageNo: this.pageIndex,
        pageSize: this.pageSize,
      }).then((res) => {
        this.data = res.data.data;
        this.pagination.pageSize = this.pageSize;
        this.pagination.total = res.data.totalCount;
        this.pagination.showTotal = () => `共 ${res.data.totalCount} 条`;
        this.pagination.showSizeChanger = true;
        this.pagination.current = this.pageIndex;
      });
    },
  },
};
</script>

<style>
</style>