<template>
  <a-card>
    <div class="antd-toolbar">
      <div class="antd-toolbar-left">
        <div class="antd-toolbar-item">
          <a-button type="primary" @click="addMemberEvt" v-permit:addMember="{projectId:$route.query.projectId}"> + 添加成员</a-button>
        </div>
      </div>
      <div class="antd-toolbar-right">
        <a-input-search v-model="queryParams.name" style="margin-left: 16px; width: 272px;" placeholder="请输入成员名称进行搜索"  @keyup.enter.native="$refs.table.refresh(true)" />
      </div>
    </div>
    <s-table
        rowKey="id"
        :data="loadData"
        size="default"
        ref="table"
        :columns="columns"
        :pageNum="pageNum"
    >
    <template slot="memberRole" slot-scope="text, record">
      <a-select :default-value="record.roleId" style="width: 150px;" @change="value => handleChange(value, record.userId)" :disabled="masterId==record.userId || disableUpdateStatus">
        <a-select-option v-for="item in roleList" v-bind:key="item.id" :value="item.id">
            {{item.name }}
        </a-select-option>
      </a-select>
    </template>
    <template slot="action" slot-scope="text,record">
      <a-popconfirm
          :title="'确定删除项目成员['+record.name+']么？'"
          ok-text="确定"
          cancel-text="取消"
          placement="topLeft"
          @confirm="deleteMemberEvt(record)"
          >
          <a href="javascript:void(0)" class="table-operation-action" v-permit:deleteMember="{projectId:$route.query.projectId}">删除</a>
      </a-popconfirm>
    </template>
    </s-table>
    <member-add ref="addForm" v-if="addMemberVisible" :confirm-loading="confirmLoading" @refreshDataList="$refs.table.refresh(true)"/>
  </a-card>
</template>

<script>
import store from '@/store'
import { detailProject } from "@/httpsAPI/project/setting";
import { getProjectUserList, deleteProjectUser, updateProjectUserRole} from "@/httpsAPI/project/member";
import { getRoleList } from "@/httpsAPI/configure";
import memberAdd from "./moudles/memberAdd";

const columns = [
  {title:'姓名',dataIndex:"name"},
  {title:'登陆账号',dataIndex:"loginName"},
  {title:'邮箱',dataIndex:"email"},
  {title:'所属部门',dataIndex:"orgName"},
  {title:'项目角色',width:'250px', dataIndex:"projectRoleId",scopedSlots: {customRender: 'memberRole'}},
  {title:'操作',dataIndex:"action",scopedSlots: {customRender: 'action'}}
]
export default {
  name: "member",
  data(){
    return{
      columns,
      masterId: 0,
      addMemberVisible:false,
      confirmLoading:false,
      queryParams:{},
      pageNum: 1,
      roleList: [],
      disableUpdateStatus: false,
      loadData:parameter=>{
        this.pageNum = parameter.pageNo
        this.queryParams.projectId = this.$route.query.projectId

        return getProjectUserList(Object.assign(parameter, this.queryParams)).then(res=>{
          console.log(res)
          return res.data
        })
      }
    }
  },
  components:{
    memberAdd
  },
  created() {
    this.getRoleData()
    this.getProjectDetail()

    // 更新项目成员按钮权限处理
    const permits = store.getters.permits
    if(permits.isAuth==0) { // 项目已归档
      this.disableUpdateStatus = true
      return
    }
    
    const accessBtns = permits.permissions.filter(item=>item.permissionId==='projectMember')[0].actionEntitySet
    if (accessBtns && accessBtns.filter(item=>item.action==='updateMemberRole').length==0) {
      this.disableUpdateStatus = true
    }
  },
  methods:{
    // 查询基本信息
    getProjectDetail(){
      detailProject(this.$route.query.projectId).then(res=>{
        this.masterId = res.data.masterId 
      })
    },
    getRoleData() {
      getRoleList({rangeType: 1, projectId: this.$route.query.projectId}).then((res) => {
        this.roleList = res.data.data
      });
    },
    // 修改项目成员角色
    handleChange(value, id) {
        const that = this
        
        updateProjectUserRole({userId:id, projectId:this.$route.query.projectId, roleId:value}).then(res=>{
          if(res.code==10000) {
              that.$message.success({
                  content: '设置项目角色成功',
                  duration: 2,
                  onClose: () => {
                  }
              })
              } else {
                that.$message.error({
                    content: res.msg,
                    duration: 1
                })
              }
          })
    },
    // 添加项目成员
    addMemberEvt(){
      this.addMemberVisible = true
      this.$nextTick(() => {
        this.$refs.addForm.init(this.$route.query.projectId)
      })
    },
    deleteMemberEvt(record){
      if(record.userId==this.masterId) {
        this.$message.warning("不能删除项目负责人")  
        return
      }
      const that = this
      deleteProjectUser({projectId:record.projectId, userId: record.userId}).then(res=>{
          if(res.code==10000) {
              that.$message.success({
                  content: '操作成功',
                  duration: 1,
                  onClose: () => {
                      that.$refs.table.refresh(true)
                  }
              })
              } else {
              that.$message.error({
                  content: res.msg,
                  duration: 1
              })
          }
      })
    }
  }
}
</script>

<style scoped>

</style>