<template>
<div>
    <!-- 修改状态弹窗 -->
    <status-form v-if="updateFormVisible" ref="statusForm" @refreshDataList="init"></status-form>

    <a-card :title="stage.planName" :bordered="false" >
      <div slot="extra">
        <a-button type="primary" @click="$router.back()">返回</a-button>
      </div>
      <a-descriptions>
        <a-descriptions-item label="完成日期">{{endTime}}</a-descriptions-item>
        <a-descriptions-item label="状态">
          <span v-if="stage.status==0">未完成</span>
          <span v-if="stage.status==1">已完成</span>
          <div style="display:inline-block">
            <a-tooltip placement="bottom" title="修改状态" style="margin-left:5px" v-if="isManageAuth==1 || (accessUpdateStatus && stage.masterId==userinfo.userId)">
            <a-icon type="edit" @click="updateStatusEvt()" />
            </a-tooltip>
          </div>
        </a-descriptions-item>
      </a-descriptions>
    </a-card>

     <a-row :gutter="24">
      <a-col :xl="16" :lg="24" :md="24" :sm="24" :xs="24">
        <a-card :bordered="false" style="margin-top: 20px;height:908px;overflow-y:auto;">
              <a-tabs default-active-key="1">
                <a-tab-pane key="1" tab="交付物" force-render>
                  <div class="antd-table-wrap">
                    <a-table
                      rowKey="id"
                      size="default"
                      ref="table"
                      :columns="columns"
                      :data-source="tabData"
                    >
                      <template slot="dataTime" slot-scope="text">
                          {{text|dataTimeFilter}}
                        </template>
                      <span slot="serial" slot-scope="text, record, index">
                              {{ index + 1 }}
                            </span>
                      <template slot="status" slot-scope="text,record">
                        <a-tag color="red" v-if="record.status===0">未提交</a-tag>
                        <a-tag color="green" v-if="record.status===1">已提交</a-tag>
                      </template>
                      <template slot="operation" slot-scope="text,record">
                        <a-button type="link" v-if="record.status===0 && (isManageAuth==1 || stage.masterId==userinfo.userId)" @click="addFileEvt(record)" v-permit:uploadDeliverable="{projectId:$route.query.projectId}">上传</a-button>
                        <a-popconfirm
                            :title="'确定移除['+record.name+']上传文件么？'"
                            ok-text="确定"
                            cancel-text="取消"
                            placement="topLeft"
                            @confirm="deleteFileEvt(record)"
                            >
                            <a-button type="link" v-if="record.status===1 && (isManageAuth==1 || stage.masterId==userinfo.userId)" v-permit:deleteDeliverable="{projectId:$route.query.projectId}">移除</a-button>
                        </a-popconfirm>
                        <a-button type="link" v-if="record.status===1" @click="downloadFileEvt(record)">下载</a-button>
                      </template>
                    </a-table>
                  </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="详情" force-render>
                  <div class="antd-table-wrap">
                    <a-descriptions title="">
                      <a-descriptions-item label="创建时间">{{$moment(this.stage.createTime).format('YYYY-MM-DD HH:mm:ss')}}</a-descriptions-item>
                      <a-descriptions-item label="更新时间">{{this.stage.modifyTime ? $moment(this.stage.modifyTime).format('YYYY-MM-DD HH:mm:ss') : ''}}</a-descriptions-item>
                    </a-descriptions>
                    <a-descriptions title="" size="small">
                      <a-descriptions-item label="描述">{{stage.content}}</a-descriptions-item>
                    </a-descriptions>
                    
                    <a-descriptions title="">
                      <a-descriptions-item label="">
                        <a-col :span="24">
                        
                        </a-col>
                      </a-descriptions-item>
                    </a-descriptions>
                  </div>
                </a-tab-pane>
                <a slot="tabBarExtraContent" @click="settingEvt" v-if="isManageAuth==1 || stage.masterId==userinfo.userId" v-permit:setDeliveralbe="{projectId:$route.query.projectId}">设置目标交付物</a>
              </a-tabs>
          </a-card>
          <deliverable-list ref="deliverableList" :visible="deliverableListVisible" @refreshDataList="init"/>
       </a-col>
       <a-col
          style="padding: 0 12px"
          :xl="8"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24">
          <!-- 页面类型：0阶段 1评审点 2需求，3任务，4求助单-->
          <trends :pageType="1" :projectId="this.$route.query.projectId" :taskId="this.$route.query.planId" :taskType="8" />
       </a-col>
     </a-row>
     <!-- 上传文件组件-->
    <upload-form v-if="uploadFormVisible" ref="uploadForm" @refreshDataList="init"></upload-form>
</div>
</template>

<script>
import store from '@/store'
import { mapGetters } from "vuex";
import moment from 'moment'
import Trends from "@/components/Trends";
import StatusForm from "@/components/Status/reviewDel";
import { getUserProjectPermList } from "@/httpsAPI/configure";
import {getDeliverableList, detailReview} from "@/httpsAPI/project/review";
import {fileDownload, fileDelete} from "@/httpsAPI/project/deliverables";
import deliverableList from './deliverableList'
import UploadForm from '@/components/Upload/document-upload'
import {coludDownloadFile } from '@/httpsAPI/common'

const columns=[
  {
    title:'序号',
    scopedSlots: { customRender: 'serial' }
  },
  {
    title:'名称',
    dataIndex: 'name',
  },
  {
    title:'交付情况',
    dataIndex: 'status',
    scopedSlots: {customRender: 'status'}
  },
  {
    title: '交付时间',
    dataIndex: 'uploadTime',
    scopedSlots:{customRender:"dataTime"} 
  },
  {
    title:'交付人',
    dataIndex: 'uploadByUserName',
  },
  {
    title: '操作',
    dataIndex: 'action',
    align: 'center',
    scopedSlots: {customRender: 'operation'}
  }
]

export default {
  data() {
    return {
      pageNum: 1,
      columns,
      stage : {},
      startTime : '',
      endTime : '',
      deliverableListVisible: false,
      tabData: [],
      uploadFormVisible: false,
      updateFormVisible: false,
      type:1,
      statusId:'',
      status: 0,
      accessUpdateStatus: false,
      isManageAuth: 0, // 是否管理授权操作数据
    };
  },
  created(){
    this.init()
    this.statusId = this.$route.query.planId
    this.getPermData()
  },
  computed: {
    ...mapGetters(["userinfo"]),
    title() {
      return this.$route.meta.title;
    },
  },
  filters: {
    dataTimeFilter (val) {
      return val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : ''
    }
  },
  components:{
      deliverableList,
      Trends,
      UploadForm,
      StatusForm
  },
  methods: {
    // 更新评审点状态按钮权限处理
    getPermData() {
      const permits = store.getters.permits

      if(permits.length==0) {
        getUserProjectPermList({id: this.$route.query.projectId}).then((res) => {
          let permitList = {}
          permitList.permissions = res.data.permissions
          permitList.isAuth = res.data.isAuth
          permitList.isManageAuth = res.data.isManageAuth
          permitList.projectId = this.$route.query.projectId
          this.isManageAuth = res.data.isManageAuth
          
          store.commit('SET_PERMITS',permitList)

          if(permitList.isAuth==0) { // 项目已归档，不授权
            return
          }
          
          const accessBtns = permitList.permissions.filter(item=>item.permissionId==='projectReview')[0].actionEntitySet
          
          this.aclList = accessBtns.map(action => {
                    return action.action
                  })
          console.log('this.aclList===============', this.aclList)
          if (this.aclList.includes('updateReviewStatus')) {
            this.accessUpdateStatus = true
          }
        })
      } else {
          this.isManageAuth = permits.isManageAuth
          if(permits.isAuth==0) { // 项目已归档,且用户非管理人员
            return
          }
          
          const accessBtns = permits.permissions.filter(item=>item.permissionId==='projectReview')[0].actionEntitySet
          this.aclList = accessBtns.map(action => {
                    return action.action
                  })
          console.log('this.aclList===============', this.aclList)
          if (this.aclList.includes('updateReviewStatus')) {
            this.accessUpdateStatus = true
          }
      }
    },
    init() {
      const that = this
      this.$nextTick(() => {
          detailReview({id:this.$route.query.planId}).then(res=>{
              console.log(res)
              that.stage = res.data
              that.status = res.data.status
              this.startTime = that.stage.startTime ? moment(that.stage.startTime).format('YYYY年MM月DD日') : ''
              this.endTime = moment(that.stage.endTime).format('YYYY年MM月DD日')

              getDeliverableList({id: this.$route.query.planId, pageSize: 1000}).then(res=>{
                this.tabData = res.data.data
              })
          })
      })
    },
    // 设定目标交付物
    settingEvt() {
      this.annexListVisible = true
      this.$nextTick(() => {
        this.$refs.deliverableList.init(this.$route.query.planId, this.$route.query.projectId)
      })
    },
     // 修改状态
    updateStatusEvt() {
      this.updateFormVisible = true
      this.$nextTick(() => {
        this.$refs.statusForm.init(this.statusId,this.type,this.status)
      })
    },
    // 上传文件
    addFileEvt(record) {
      const params = {refType: 4, projectId: record.projectId, refId: record.id}
      this.uploadFormVisible = true
      this.$nextTick(() => {
        this.$refs.uploadForm.init(params)
      })
    },
    // 移除文件
    deleteFileEvt(record) {
      const that = this
      fileDelete({id:record.id}).then(res=>{
          if(res.code==10000) {
              that.$message.success({
                  content: '操作成功',
                  duration: 1,
                  onClose: () => {
                      that.init()
                  }
              })
              } else {
              that.$message.error({
                  content: res.msg,
                  duration: 1
              })
          }
      })
    },
    // 下载文件
    downloadFileEvt(record) {
       var type = window._CONFIG['downloadType'];
        if (type == "local") {
          const params = {
          id : record.id,
          fileName : record.name + record.linkUrl.substring(record.linkUrl.lastIndexOf('.'))
        }
          fileDownload(params).then(res=>{
          })
        }
        if(type == "cloud"){
            coludDownloadFile(record.id,'/project/deliverables/file-download');
        }

    }
  }
};
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
</style>
