<template>
  <div>
    <gantt-elastic ref="gantt" :tasks="tasks" :options="options" :dynamic-style="dynamicStyle"></gantt-elastic>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import ganttElastic from 'gantt-elastic'
import { getProjectManageGantt} from "@/httpsAPI/project/stage";

let options = {
  maxRows: 100,
  maxHeight: 500,
  title: {
    label: 'Your project title as html (link or whatever...)',
    html: false,
  },
  row: {
    height: 24,
  },
  calendar: {
    hour: {
      display: false,
    },
    day:{
      format:{
        long(date){
          return date.format('DD')+'日'
        }
      }
    },
    month:{
      format:{
        long(date) {
          return date.format('YYYY')+'年'+date.format('MM')+'月';
        }
      }
    }
  },
  chart: {
    progress: {
      bar: false,
    },
    expander: {
      display: true,
    },
  },
  taskList: {
    expander: {
      straight: false,
    },
    columns: [
      {
        id: 1,
        label: 'ID',
        value: 'id',
        width: 40,
      },
      {
        id: 2,
        label: '阶段名',
        value: 'label',
        width: 200,
        expander: true, //开启收缩
        html: true,
        events: {
          click({ data, column }) {
            alert('description clicked!\n' + data.label);
          },
        },
      },
      {
        id: 4,
        label: '类型',
        value: (task) => {
          if(task.type=='project') {
            return '阶段'
          } else{
            return '评审点'
          }
        },
        width: 68,
      },
      {
        id: 3,
        label: '负责人',
        value: 'user',
        width: 100,
        html: true,
      },
      {
        id: 3,
        label: '开始日期',
        value: (task) => dayjs(task.start).format('YYYY-MM-DD'),
        width: 90,
      },
      {
        id: 5,
        label: '进度%',
        value: 'progress',
        width: 55,
        style: {
          'task-list-header-label': {
            'text-align': 'center',
            width: '100%',
          },
          'task-list-item-value-container': {
            'text-align': 'center',
            width: '100%',
          },
        },
      }
    ],
  }
};


export default {
  name: "stageGantt",
  components:{
    ganttElastic
  },
  data(){
    return{
      tasks: [],
      options:options,
      dynamicStyle: {
        'task-list-header-label': {
          'font-weight': 'bold',
        },
      }
    }
  },
  methods:{
    loadGantData() {
      this.$nextTick(()=>{
        getProjectManageGantt({projectId:this.$route.query.projectId}).then(res=>{
          console.log(res);
          this.tasks = res.data
        })
      })
    }
  },
  mounted() {
    this.loadGantData()
  },
  activated() {
    // 第一次和非详情页进入页面时 isBack都为false，即需要重新请求数据
    if (!this.$route.meta.isBack) {
        // 清空查询条件和数据 并重新请求数据
        this.tasks = [];
        this.loadGantData()
    }
    this.$route.meta.isBack = false;
  },
}
</script>

<style scoped>

</style>