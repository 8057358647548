<template>
  <div class="test">
  
  </div>
</template>

<script>
import { mapGetters } from "vuex";
let apiBaseUrl = window._CONFIG['domianURL'] || process.env.VUE_APP_BASE_URL;

export default {
  data(){
    return{
      lockReconnect:false,
      show:false
    }
  },
  created(){
  },
  computed: {
    ...mapGetters(["userinfo"]),
  },
  methods: {
     initWebSocket: function () {
        // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
        var url = apiBaseUrl.replace("https://","wss://").replace("http://","ws://")+"/ws/"+ this.userinfo.userId;
        this.websock = new WebSocket(url);
        this.websock.onopen = this.websocketOnopen;
        this.websock.onerror = this.websocketOnerror;
        this.websock.onmessage = this.websocketOnmessage;
        this.websock.onclose = this.websocketOnclose;
      },
      websocketOnopen: function () {
        console.log("WebSocket连接成功");
      },
      websocketOnerror: function (e) {
        console.log("WebSocket连接发生错误");
        this.reconnect();
      },
      websocketOnmessage: function (e) {
        if(e.isTrusted == true){
          this.show = true
          this.$emit('show',this.show)
          // this.$message.info(e.data);
        }
      },
      websocketOnclose: function (e) {
       
      },
      reconnect() {
        var that = this;
        if(that.lockReconnect) return;
        that.lockReconnect = true;
        //没连接上会一直重连，设置延迟避免请求过多
        setTimeout(function () {
          console.info("尝试重连...");
          that.initWebSocket();
          that.lockReconnect = false;
        }, 5000);
      },
  },
  mounted() {
    this.initWebSocket();
  },
  destroyed: function () { // 离开页面生命周期函数
      this.websocketOnclose();
  },
}
</script>

<style lang="less" scoped>
.test{
  width: 30%;
  background: red;
}
</style>>

