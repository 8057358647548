<template>
  <a-modal
      title="关联模板"
      ref="relateForm"
      width="650px"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :maskClosable="false"
      destroyOnClose
  >
    <div class="antd-panel">
        <div class="page-search-wrap">
          <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
            <a-form-item label="模板列表">
                <a-select style="width:100%" placeholder="请选择模板" 
                v-decorator="['wikiId', {rules: [{ message: '请选择模板'}]}]" @change="value => changeWiki(value)">
                  <a-select-option  v-for="item in wikiList" :key="item.id">{{ item.wikiName }}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="模板目录">
              <a-tree
                    checkable
                    :selected-keys="selectedKeys"
                    :checkedKeys="checkedKeys"
                    :tree-data="treeData"
                    @select="onSelect"
                    @check="onCheck"
                />
              </a-form-item>
          </a-form>
        </div>
    </div>
    
  </a-modal>
</template>

<script>
import {getWikiList, getWikiDicList, batchRelateWiki} from "@/httpsAPI/project/task";

export default {
  name: "relateWiki",
  data(){
    return{
      form: this.$form.createForm(this),
      visible: false,
      confirmLoading: false,
      taskId: 0,
      wikiId: 0,
      wikiList: [],
      checkedKeys: [],
      selectedKeys: [],
      treeData: [],
      relatedCheckKey: []
    }
  },
  methods:{
    changeWiki(value) {
      this.wikiId = value
      this.loadCheckTreeData(value)

    },
    onCheck(checkedKeys) {
        this.checkedKeys = checkedKeys;
    },
    onSelect(selectedKeys, info) {
        this.selectedKeys = selectedKeys;
    },
    // 根据 checkedKeys 获取 rows
    getCheckedRows(checkedKeys) {
        const forChildren = (list, key) => {
        for (let item of list) {
            if (item.id === key) {
            return item
            }
            if (item.children instanceof Array) {
            let value = forChildren(item.children, key)
            if (value != null) {
                return value
            }
            }
        }
        return null
        }

        let rows = []
        for (let key of checkedKeys) {
        let row = forChildren(this.treeData, key)
        if (row != null) {
            rows.push(row)
        }
        }
        return rows
    },
    // 加载知识库目录check树
    loadCheckTreeData(wikiId) {
        getWikiDicList({wikiId: wikiId,taskId:this.taskId}).then(res => {
          this.checkedKeys = this.relatedCheckKey
          this.selectedKeys = []
          this.treeData = res.data
        }) 
    },
    handleOk(){
      if(this.checkedKeys.length==0) {
        this.$message.warning("请选择模板目录进行关联！")  
        return
      }
      this.confirmLoading = true
      const that = this
      batchRelateWiki({taskId:that.taskId, wikiId: that.wikiId, ids: that.checkedKeys}).then(res=>{
          if(res.code==10000) {
              that.$message.success({
                  content: '关联成功',
                  duration: 1,
                  onClose: () => {
                    that.confirmLoading = false
                    this.visible = false
                    that.$emit('refreshDataList')
                  }
              })
          } else {
            that.$message.error({
                content: res.msg,
                duration: 1
            })
          }
      })
      console.log('checkedKeys', this.checkedKeys)
    },
    handleCancel(e) {
      this.visible = false;
    },
    init(taskId, relatedCheckKey) {
      this.confirmLoading = false
      this.visible = true
      this.taskId = taskId
      const that = this
      this.$nextTick(() => {
          this.relatedCheckKey = relatedCheckKey
          getWikiList().then(res => {
              this.wikiList = res.data;
          })
      })
    }
  }
}
</script>

<style lang="less">
.member-list-wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .list-label{
    line-height: 42px;
  }
}
</style>