<template>
  <div class="tinymce-editor">
    <editor
      v-if="!reloading"
      v-model="myValue"
      :init="init"
      :disabled="disabled"
      @onClick="onClick">
    </editor>
    <div class="editor-custom-btn-container">
      <upload-form v-if="uploadFormVisible" ref="uploadForm" @refreshDataList="showUploadFile"></upload-form>
    </div>
  </div>
</template>

<script>
  import tinymce from 'tinymce/tinymce'
  import Editor from '@tinymce/tinymce-vue'
  import UploadForm from './editor-upload'
  import 'tinymce/themes/silver/theme'
  import 'tinymce/plugins/image'
  import 'tinymce/plugins/link'
  import 'tinymce/plugins/media'
  import 'tinymce/plugins/table'
  import 'tinymce/plugins/lists'
  import 'tinymce/plugins/contextmenu'
  import 'tinymce/plugins/wordcount'
  import 'tinymce/plugins/colorpicker'
  import 'tinymce/plugins/textcolor'
  import 'tinymce/plugins/fullscreen'
  import 'tinymce/plugins/code'
  import 'tinymce/icons/default'
  import {uploadImg} from '@/httpsAPI/common'
  import { getVmParentByName } from '@/utils'
  export default {
    components: {
      Editor,
      UploadForm
    },
    props: {
      value: {
        type: String,
        required:false
      },
      triggerChange:{
        type: Boolean,
        default: false,
        required:false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      plugins: {
        type: [String, Array],
        default: 'lists image link media table textcolor wordcount contextmenu fullscreen code'
      },
      toolbar: {
        type: [String, Array],
        default: ' formatselect bold italic fontselect fontsizeselect forecolor backcolor alignleft aligncenter alignright alignjustify bullist numlist outdent indent lists link unlink image table fullscreen ',
        branding:false
      }
    },
    data() {
      return {
        //初始化配置
        init: {
          language_url: '/tinymce/langs/zh_CN.js',
          language: 'zh_CN',
          skin_url: '/tinymce/skins/lightgray',
          height: 300,
          plugins: this.plugins,
          toolbar: this.toolbar,
          branding: false,
          menubar: false,
          toolbar_drawer: false,
          images_upload_handler: (blobInfo, success) => {
            let formData = new FormData()
            var file = blobInfo.blob()
            formData.append('file', file, file.name) 
            formData.append('channel', 1)

            uploadImg(formData).then(res=>{
              if (res.code == 10000){
                success(res.data.path)
              } else {  
                  this.$message.error(res.msg)  
              }
            })
          }
        },
        myValue: this.value,
        reloading: false,
        uploadFormVisible: false
      }
    },
    mounted() {
      this.initATabsChangeAutoReload()
    },
    methods: {
      // 显示上传文件
      showUploadFile() {

      },
      // 上传文件
      addFileEvt() {
        this.uploadFormVisible = true
        this.$nextTick(() => {
          this.$refs.uploadForm.init()
        })
      },
      reload() {
        this.reloading = true
        this.$nextTick(() => this.reloading = false)
      },

      onClick(e) {
        this.$emit('onClick', e, tinymce)
      },
      //可以添加一些自己的自定义事件，如清空内容
      clear() {
        this.myValue = ''
      },

      /**
       * 自动判断父级是否是 <a-tabs/> 组件，然后添加事件监听，自动触发reload()
       *
       * 由于 tabs 组件切换会导致 tinymce 无法输入，
       * 只有重新加载才能使用（无论是vue版的还是jQuery版tinymce都有这个通病）
       */
      initATabsChangeAutoReload() {
        // 获取父级
        let tabs = getVmParentByName(this, 'ATabs')
        let tabPane = getVmParentByName(this, 'ATabPane')
        let modal = getVmParentByName(this,'AModal')
        if (tabs && tabPane ) {
          // 用户自定义的 key
          let currentKey = tabPane.$vnode.key
          // 添加事件监听
          tabs.$on('change', (key) => {
            // 切换到自己时执行reload
            if (currentKey === key) {
              this.reload()
            }
          })
        }else if(modal){
          console.log("tinymce reload!")
          this.reload();
        }
      },

    },
    watch: {
      value(newValue) {
        this.myValue = (newValue == null ? '' : newValue)
      },
      myValue(newValue) {
        if(this.triggerChange){
          this.$emit('change', newValue)
        }else{
          this.$emit('input', newValue)
        }
      }
    }
  }

</script>
