<template>
<a-card title="项目操作">
  <a-descriptions title="基本信息" style="border-bottom: 1px solid #e5e4e4;margin-bottom: 20px">
    <a-descriptions-item label="项目名称" :span="24">
      {{projectName}}
      <div style="display:inline-block">
        <a-tooltip placement="bottom" title="修改名称">
          <a-icon type="edit" @click="updateNameEvt()" />
        </a-tooltip>
      </div>
    </a-descriptions-item>
    <a-descriptions-item label="创建人" :span="24">
      {{createBy}}
    </a-descriptions-item>
    <a-descriptions-item label="创建时间" :span="24">
      {{createTime}}
    </a-descriptions-item>
  </a-descriptions>
  <div style="border-bottom: 1px solid #e5e4e4;margin-bottom: 20px;padding-bottom: 15px" v-permit:lockProject="{projectId:$route.query.projectId}">
    <a-descriptions title="锁定项目">
      <a-descriptions-item>
        初次使用需要设置密码（锁定后，只有授权获得解锁密码后才有权访问和使用项目)
      </a-descriptions-item>
    </a-descriptions>
    <a-button type="danger" @click="lockEvt" v-if="lockStatus==0">锁定项目</a-button>
    <a-button type="danger" @click="unlockEvt" v-else>取消锁定</a-button>
  </div>
  <div style="border-bottom: 1px solid #e5e4e4;margin-bottom: 20px;padding-bottom: 15px" v-permit:duplicateProject="{projectId:$route.query.projectId}">
    <a-descriptions title="复制项目" >
      <a-descriptions-item>
        复制当前项目的项目组件、工作项类型、权限配置以及项目配置，可自定义需要复制的项目数据
      </a-descriptions-item>
    </a-descriptions>
    <a-button type="primary" @click="projectNameEVt">复制项目</a-button>
  </div>
  <div style="border-bottom: 1px solid #e5e4e4;margin-bottom: 20px;padding-bottom: 15px" v-permit:archiveProject="{projectId:$route.query.projectId}">
    <a-descriptions title="归档项目" >
      <a-descriptions-item>
        归档后，只有项目管理员可以对项目内容进行更改，其余成员可以查看项目但无法执行其他操作；取消后，成员的操作权限将还原到正常状态
      </a-descriptions-item>
    </a-descriptions>
    <a-button type="primary" @click="archiveEvt" v-if="archiveStatus==0">归档</a-button>
    <a-button type="danger" @click="unArchiveEvt" v-if="archiveStatus==1">关闭归档</a-button>
  </div>
  <div style="border-bottom: 1px solid #e5e4e4;margin-bottom: 20px;padding-bottom: 15px" v-permit:saveProjectPerm="{projectId:$route.query.projectId}">
    <a-descriptions title="权限设置" >
      <a-descriptions-item>
        管理该项目下所有的可操作权限
      </a-descriptions-item>
    </a-descriptions>
    <a-button type="primary" @click="settingConfigEvt">设置项目权限</a-button>
  </div>
  <div style="padding-bottom: 15px" v-permit:deleteProject="{projectId:$route.query.projectId}">
    <a-descriptions title="删除项目" >
      <a-descriptions-item>
        删除该项目下所有的资源
      </a-descriptions-item>
    </a-descriptions>
    <a-button type="danger" @click="showDeleteConfirm">删除</a-button>
  </div>
  
  <setting-lock :visible="lockVisible" :confirm-loading="confirmLoading" :model="mdl" @cancel="lockVisible=false" @ok="handleOk" ref="settingLockFrom" />
  <setting-config v-if="configVisible" ref="settingConfigForm"/>
  <setting-project-name :visible="projectNameVisible" :confirm-loading="confirmLoading"  ref="projectNameForm" @cancel="projectNameVisible=false"/>
  <update-project-name :visible="updateProjectNameVisible" :confirm-loading="confirmLoading"  ref="updateProjectNameForm" @refreshDataList="detailProject()" @cancel="updateProjectNameVisible=false"/>
</a-card>
</template>

<script>
import settingLock from "./moudles/settingLock";
import settingConfig from "./moudles/settingConfig";
import settingProjectName from "./moudles/settingProjectName";
import updateProjectName from "./moudles/updateProjectName";
import { delProject, detailProject, archiveProject, unArchiveProject, lockProject, cancelLockProject } from "@/httpsAPI/project/setting";
export default {
  name: "setting",
  components:{
    settingLock,
    settingConfig,
    settingProjectName,
    updateProjectName
  },
  data(){
    return{
      mdl: {},
      lockVisible:false,
      configVisible:false,
      projectNameVisible:false,
      confirmLoading:false,
      updateProjectNameVisible:false,
      projectId:'',
      projectName:'',
      createBy:'',
      createTime:'',
      lockStatus:'',
      archiveStatus:'',
      status:''
    }
  },
  created(){
    this.projectId = this.$route.query.projectId
    this.detailProject()
  },
  methods:{
    // 权限设置
    settingConfigEvt() {
      this.configVisible = true
      this.$nextTick(() => {
        this.$refs.settingConfigForm.init(this.projectId)
      })
    },
    // 锁定项目
    lockEvt () {
      this.lockVisible = true
      this.mdl = { unlock: false }
    },
    // 解锁项目
    unlockEvt () {
      cancelLockProject({projectId: this.projectId}).then(res => {
        this.detailProject()
        this.$message.success('取消项目锁定成功')
      })
    },
    handleOk (data) {
      const form = this.$refs.settingLockFrom.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
            // 锁定项目
            lockProject(Object.assign(values, {projectId: this.projectId})).then(res => {
              this.lockVisible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.detailProject()
              this.$message.success('锁定成功')
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    //归档
    archiveEvt(){
      archiveProject(this.projectId).then(res=>{
        this.detailProject()
        this.$message.success('操作成功')
      })
    },
    //取消归档
    unArchiveEvt(){
      unArchiveProject(this.projectId).then(res=>{
        this.detailProject()
        this.$message.success('操作成功')
      })
    },
    // 复制项目名称
    projectNameEVt() {
      this.projectNameVisible = true
      this.$nextTick(() => {
        this.$refs.projectNameForm.init(this.projectId)
      })
    },
    // 修改项目名称
    updateNameEvt() {
      this.updateProjectNameVisible = true
      this.$nextTick(() => {
        this.$refs.updateProjectNameForm.init(this.projectId,this.projectName)
      })
    },
    // 详情
    detailProject(){
      detailProject(this.projectId).then(res=>{
        this.projectName = res.data.projectName 
        this.createBy = res.data.masterName 
        this.createTime = res.data.createTime 
        this.lockStatus = res.data.lockStatus 
        this.archiveStatus = res.data.archiveStatus 
        this.status = res.data.status 
      })
    },
    //删除
    showDeleteConfirm() {
      const that = this
      this.$confirm({
        title: '删除此项目?',
        content: '确定删除此项目么？删除将无法恢复',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          delProject(that.projectId).then(res => {
            that.$message.success({
                  content: '操作成功',
                  duration: 1,
                  onClose: () => {
                     that.$router.push({path:"/project/index"})
                  }
              })
          }).catch(error => {
              console.log(error)
          })
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  }
}
</script>

<style scoped>

</style>