import API from "@/httpsAPI/API";
import request from "@/utils/request";

// 查询项目成员列表
export function getProjectUserList(params) {
    return request({
        url:API.projectUserList,
        method:'post',
        data:params
    })
}

// 企业团队未关联项目成员列表
export function getProjectUserRelateList(params) {
    return request({
        url:API.projectUserRelateList,
        method:'post',
        data:params
    })
}

// 添加项目成员
export function addProjectUser(params) {
    return request({
        url: '/project/user/batch-add',
        method:'post',
        data:params
    })
}

// 删除项目成员
export function deleteProjectUser(params) {
    return request({
        url: '/project/user/delete',
        method:'post',
        data:params
    })
}

// 更新项目成员角色
export function updateProjectUserRole(params) {
    return request({
        url: '/project/user/update-role',
        method:'post',
        data:params
    })
}