import axios from "axios";
import store from "@/store";
import storage from "store";
import { VueAxios } from "./axios";
import { TOKEN } from "@/store/token.config";

import notification from "ant-design-vue/es/notification";
import { Modal } from "ant-design-vue";
import NProgress from "nprogress";

// let apiBaseUrl = window._CONFIG['domainURL'] || "/proxy";
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000,
});

// 异常拦截处理器
const errorHandler = (error) => {
  NProgress.done();
  if (error.response) {
    const data = error.response.data;
    // 从 localstorage 获取 token
    const token = storage.get(TOKEN);
    if (error.response.status === 403) {
      notification.error({
        message: "Forbidden",
        description: data.message,
      });
    }
    if (
      error.response.status === 401 &&
      !(data.result && data.result.isLogin)
    ) {
      notification.error({
        message: "Unauthorized",
        description: "Authorization verification failed",
      });
      if (token) {
        store.dispatch("Logout").then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        });
      }
    }
  }
  return Promise.reject(error);
};

request.interceptors.request.use((config) => {
  const token = storage.get(TOKEN);
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers["token"] = token;
  }
  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use(
  (response) => {
    NProgress.done();
    if (response) {
      switch (response.data.code) {
        case 401:
          Modal.error({
            title: "登录已过期",
            content: "很抱歉，登录已过期，请重新登录",
            okText: "重新登录",
            mask: false,
            onOk: () => {
              store.dispatch("Logout").then(() => {
                try {
                  let path = window.document.location.pathname;
                  if (path != "/" && path.indexOf("/login") == -1) {
                    window.location.reload();
                  }
                } catch (e) {
                  window.location.reload();
                }
              });
            },
          });
      }
    }

    return response.data;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request);
  },
};
export default request;

export { installer as VueAxios, request as axios };
