<template>
<a-modal
      title="上传文件"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      destroyOnClose
    >
 <div class="antd-page">
  <div class="antd-panel">
  <a-form :form="form" class="form" v-if="params.refType!=5">
        <a-row type="flex" justify="center" class="form-row" :gutter="16">
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item>
                <div class="dropbox">
                  <a-upload-dragger
                    :action="uploadAction"
                    :data="{refType: params.refType, refId: params.refId, projectId: params.projectId}"
                    :before-upload="beforeUpload"
                    :headers="headers"
                    name="file"
                    :accept="uploadFomat+','+uploadFomat.toUpperCase()"
                    @change="handleChange"
                    :multiple="false"
                  >
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">
                      点击或将文件拖拽到这里上传
                    </p>
                    <p class="ant-upload-hint">
                      支持扩展名：{{uploadFomat.replace(/,/g," ")}}
                    </p>
                  </a-upload-dragger>
                </div>
              </a-form-item>
            </a-col>
        </a-row>
      </a-form>
      <a-form :form="form" class="form" v-else-if="params.refType==5">
        <a-row type="flex" justify="center" class="form-row" :gutter="16">
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item>
                <div class="dropbox">
                  <a-upload-dragger
                    :action="uploadAction"
                    :data="{refType: params.refType, refId: params.refId, projectId: params.projectId}"
                    :before-upload="beforeUpload"
                    :headers="headers"
                    name="file"
                    :accept="uploadFomat+','+uploadFomat.toUpperCase()"
                    @change="handleChange2"
                    :multiple="true"
                  >
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">
                      点击或将文件拖拽到这里上传
                    </p>
                    <p class="ant-upload-hint">
                      支持扩展名：{{uploadFomat.replace(/,/g," ")}}
                    </p>
                  </a-upload-dragger>
                </div>
              </a-form-item>
            </a-col>
        </a-row>
      </a-form>
  </div>
 </div>
  <span slot="footer" class="dialog-footer">
    <a-button @click="handleCancel">关闭</a-button>
  </span>
</a-modal>
</template>
<script>
import storage from 'store'
import {TOKEN} from "@/store/token.config"
let apiBaseUrl = window._CONFIG['domianURL'] || process.env.VUE_APP_BASE_API;

    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                visible: false,
                confirmLoading: false,
                uploadFomat: '.doc,.docx,.xls,.xlsx,.ppt,.pptx,.jpg,.png,.pdf',
                headers:{},
                uploadAction: '',
                fileList: [],
                filePath: '',
                uploading: false,
                params: {}
            }
        },
        methods:{
          handleRemove(file) {
            const index = this.fileList.indexOf(file);
            const newFileList = this.fileList.slice();
            newFileList.splice(index, 1);
            this.fileList = newFileList;
          },
          beforeUpload(file) {
            // 请求判断 4交付物文件 5任务资料文件
            if(this.params.refType==4){
              this.uploadAction = apiBaseUrl + "/project/review/deliverable-upload"
            }else if(this.params.refType==5){
              this.uploadAction = apiBaseUrl + "/project/task/file-upload"
            }
            console.log(this.uploadAction)
          },
          handleChange(info) {
            if(info.file.status==='done'){
              this.$message.success(`${info.file.name} 上传成功.`);
              this.visible = false;
              this.$emit('refreshDataList')
            }else if (info.file.status === 'error') {
              this.$message.error(`${info.file.name} 上传失败.`);
            }
          },
          handleChange2(info) {
            if(info.file.status==='done'){
              this.$message.success(`${info.file.name} 上传成功.`);
              this.$emit('refreshDataList')
            }else if (info.file.status === 'error') {
              this.$message.error(`${info.file.name} 上传失败.`);
            }
          },
          handleCancel(e) {
            this.visible = false;
          },
          init (params) {
            console.log('============上传参数=============', params)
            this.visible = true
            this.multipleList = []
            this.params = params
            this.fileList = []
            this.filePath = ''
            this.$nextTick(() => {
                this.form.resetFields();

                const token = storage.get(TOKEN);
                this.headers = {"token":token};
            });
          }
        }
    }
</script>