<template>
  <layout>
    <template slot="headerMenu">
      <div class="header-menu">
        <div class="header-menu-wraper">
          <div class="header-menu-title" @click="viewProjectList">项目管理</div>
          <a-dropdown :trigger="['click']" v-model="dropdownVisible" class="header-dropdown">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">{{currentProjectName}}<a-icon type="down" /></a>
            <div slot="overlay" class="ant-dropdown-menu-project">
              <div class="ant-dropdown-search">
                <a-input-search v-model="searchVal" @pressEnter="handleSearchProject" placeholder="输入项目名称搜索" :loading="searchLoding">
                </a-input-search>
              </div>
              <a-menu @click="()=>dropdownVisible=false" v-if="!searchContent">
                <a-menu-item-group key="g1">
                  <template slot="title" v-if="masterList.length>0">负责项目</template>
                  <template v-for="(masterProject,index)  in masterList">
                    <a-menu-item :key="masterProject.id" v-if="index<5">
                      <a :href="'/projectManage/overview?projectId='+masterProject.id">{{ masterProject.projectName }}</a>
                    </a-menu-item>
                  </template>
                </a-menu-item-group>

                <a-menu-item-group key="g2">
                  <template slot="title" disabled="" v-if="partList.length>0">参与项目</template>
                  <template v-for="(partProject,index)  in partList">
                    <a-menu-item :key="partProject.id" v-if="index<5">
                      <a :href="'/projectManage/overview?projectId='+partProject.id">{{ partProject.projectName }}</a>
                    </a-menu-item>
                  </template>
                </a-menu-item-group>
              </a-menu>
              <router-link to="/project" v-if="!searchContent" class="ant-dropdown-more"><a-icon type="bars" />查看更多项目</router-link>

              <a-menu v-if="searchContent">
                <template v-for="project in searchList">
                  <a-menu-item :key="project.id">
                    <a :href="'/projectManage/overview?projectId='+project.id">{{ project.projectName }}</a>
                  </a-menu-item>
                </template>
              </a-menu>
            </div>
          </a-dropdown>

          <a-menu mode="horizontal" v-model="currentMenu">
            
            <template v-for="item in topMenuList">
              <a-menu-item :key="item.name" v-if="!item.hidden" >
                <div @click="navEvt(item)">{{ item.meta.title }}</div>
              </a-menu-item>
            </template>
            
          </a-menu>

        </div>
      </div>
      <!-- 项目锁定弹框-->
      <unlock-project v-if="unlockProjectVisible" ref="unlockProject" />
    </template>
  </layout>
</template>

<script>
import {mapGetters} from 'vuex'
import {getProjectSelect, changeProjectList} from "@/httpsAPI/project";
import { unlockProjectState } from "@/httpsAPI/project/setting";
import { getUserProjectPermList } from "@/httpsAPI/configure";
import UnlockProject from "./moudles/unlockProject";

function hasPermission(permission, route) {
  if (route.meta && route.meta.permission) {
    let flag = false
    for (let i = 0, len = permission.length; i < len; i++) {
      flag = route.meta.child_permission.includes(permission[i])
      if (flag) {
        return true
      }
    }
    return false
  }
  return true
}

function filterAsyncRouter(routerMap, permissionList) {
  const accessedRouters = routerMap.filter(route => {
    if (hasPermission(permissionList, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, permissionList)
      }
      return true
    }
    return false
  })
  return accessedRouters
}

export default {
  name: "index",
  components: {
    UnlockProject,
  },
  computed:{
    ...mapGetters(['addRouter'])
  },
  data(){
    return{
      projectList: [],
      masterList: [],  // 负责项目
      partList: [],    // 参与项目
      searchList: [],  // 搜索到的项目
      currentProjectId: 0,
      currentProjectName: '',
      currentMenu:["Overview"],
      topMenuList : [],
      dropdownVisible:false,
      searchLoding:false,
      searchContent:false,
      searchVal:'',
      unlockProjectVisible: false
    }
  },
  methods:{
    // 查询项目列表
    getProjectList(){
      this.currentProjectId = this.$route.query.projectId
      getProjectSelect({pageNo:1, pageSize: 100}).then(res=>{
        this.projectList = res.data.data

        for (let i = 0, len = this.projectList.length; i < len; i++) {
          if(this.projectList[i].id==this.currentProjectId) {
            this.currentProjectName = this.projectList[i].projectName
            break;
          }
        }
      })
    },
    // 项目切换选择列表
    changeProjectList(){
      this.currentProjectId = this.$route.query.projectId
      changeProjectList({projectName: this.searchVal}).then(res=>{
        console.log(res)
        this.masterList = res.data.chargeProject
        this.partList = res.data.participationProject
      })
    },
    // 查询用户项目中的权限列表
    getProjectPermData() {
      const accessMenuList = this.addRouter.filter(item=>item.name==='ProjectManage')[0].children
      getUserProjectPermList({id: this.$route.query.projectId}).then((res) => {
        const permissionList = res.data.permissions.map(permission => {
            return permission.permissionId
        })
        let permitList = {}
        permitList.permissions = res.data.permissions
        permitList.isAuth = res.data.isAuth
        permitList.isManageAuth = res.data.isManageAuth
        permitList.projectId = this.currentProjectId
        
        this.$store.commit('SET_PERMITS',permitList)
        console.log('permitList************', permitList)

        this.topMenuList = filterAsyncRouter(accessMenuList, permissionList)
      })
    },
    navEvt(item){
      this.$router.push({
        query: {projectId: this.$route.query.projectId},
        path:item.path
      })
    },
    viewProjectList() {
      this.$router.push({ 
        path : '/project/index'
      })
    },
    handleSearchProject(){
      if(this.searchVal==='') {
        this.searchContent=false
        return
      }
      this.searchLoding=true
      getProjectSelect({pageNo:1, pageSize: 100, projectName: this.searchVal}).then(res=>{
        this.searchList = res.data.data

        this.searchLoding=false
        this.searchContent=true
      })
    },
    getProjectDetail(){
      this.currentProjectId = this.$route.query.projectId
      unlockProjectState({id:this.currentProjectId}).then(res=>{
        console.log(res)
        this.currentProjectName = res.data.project.projectName 

        this.changeProjectList()

        // 非企业或项目管理员 (项目锁定状态且验证失败)
        if(res.data.isMaster!=1 && (res.data.project.lockStatus == 1 && !res.data.isAuth)) {
          this.openUnlockProjectEvt()
        }

        // 归档后只有企业或项目管理员有操作权限
        // if(res.data.project.archiveStatus==0 || (res.data.project.archiveStatus==1 && res.data.isMaster==1)) {
          
        // }
      })
    },
    // 打开项目锁屏
    openUnlockProjectEvt() {
      this.unlockProjectVisible = true;
      this.$nextTick(() => {
        this.$refs.unlockProject.init(this.currentProjectId);
      });
    }
  },
  created() {
    const projectId = this.$route.query.projectId

    if(!projectId) {
      this.$router.push({ 
        path : '/404'
      })
    }
    this.currentMenu = [this.$route.name]
    this.getProjectDetail()
  },
  mounted() {
    this.getProjectPermData()
  }
}
</script>

<style lang="less">
.ant-dropdown-menu-project{
  box-shadow: 0 2px 5px #c7c7c7;
  border-radius: 4px;
  background: #FFFFFF;
  .ant-dropdown-search{
    padding: 12px 12px;
  }
  .ant-menu-item-group-title{
    font-size: 12px;
    padding: 2px 12px;
    color: #d6d6d6;
  }
  .ant-menu-item-group-list{
    .ant-menu-item{
      padding: 0 16px 0 12px;
      height: 32px;
      line-height: 32px;
    }
  }
  .ant-dropdown-more{
    display: block;
    padding: 0px 16px 0px 12px;
    height: 32px;
    line-height: 32px;
    border-top: 1px solid #e5e5e5;
  }
}
</style>