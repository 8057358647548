<template>
  <a-modal
      title="添加项目成员"
      ref="addForm"
      width="800px"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :maskClosable="false"
      destroyOnClose
  >
    <div class="member-list-wrap">
      <div class="list-label">请选择成员加入到项目</div>
      <div class="list-input">
        <a-input-search v-model="queryParams.name" placeholder="请输入搜索成员"  @keyup.enter.native="$refs.table.refresh(true)" />
      </div>
    </div>
    <s-table
        rowKey="id"
        :data="loadData"
        size="default"
        ref="table"
        :columns="columns"
        :row-selection="{ selectedRowKeys: selectedRowKeys,onChange: onSelectChange}"
        :pageNum="pageNum"
    >
    </s-table>
  </a-modal>
</template>

<script>
import {getProjectUserRelateList, addProjectUser} from "@/httpsAPI/project/member";

const columns = [
  {title:'用户名',dataIndex:"name"},
  {title:'邮箱',dataIndex:"email"},
  {title:'所属部门',dataIndex:"orgName"}
]
export default {
  name: "addMember",
  data(){
    return{
      columns,
      visible: false,
      confirmLoading: false,
      queryParams: {},
      pageNum: 1,
      projectId: 0,
      loadData:parameter=>{
        this.pageNum = parameter.pageNo
        this.queryParams.projectId = this.projectId

        return getProjectUserRelateList(Object.assign(parameter, this.queryParams)).then(res=>{
          return res.data
        })
      },
      selectedRowKeys:[],
      selectedRows:[],
      alert:{
        show: false, clear: () => { this.selectedRowKeys = [] }
      },
      rowSelection:{
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  methods:{
    handleOk(){
      if(this.selectedRows.length==0) {
          this.$message.warning('请选择要添加的人员', 1)
          return
      }
      this.confirmLoading = true;
      const values = {projectId:this.projectId, userIds:this.selectedRowKeys}

      addProjectUser(values).then(res=>{
          if(res.code==10000) {
              this.$message.success({
                  content: '操作成功',
                  duration: 1,
                  onClose: () => {
                      this.visible = false;
                      this.$emit('refreshDataList')
                  }
              })
          }else {
              this.$message.error({
                  content: res.msg,
                  duration: 1
              })
          }
          this.confirmLoading = false;
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleCancel(e) {
      this.visible = false;
    },
    init(projectId) {
      this.confirmLoading = false
      this.visible = true
      this.projectId = projectId
    }
  }
}
</script>

<style lang="less">
.member-list-wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .list-label{
    line-height: 42px;
  }
}
</style>
