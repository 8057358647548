<template>
  <a-modal title="点评" :visible="visible" :confirm-loading="confirmLoading" @cancel="handleCancel">
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" >
      <a-form-item label="评分" style="margin:20px 0px;">
        <a-rate v-decorator="['score',  { rules: [ {required: true, message: '请输入评分' }] }]" starW="80" starH="80" />
      </a-form-item>

      <a-form-item label="评语">
        <a-textarea placeholder="请输入评语" :rows="4"
          v-decorator="['evaluation',  { rules: [ {required: true,  max:150, message: '请输入评语' }] }]" />
      </a-form-item>
    </a-form>
    <span slot="footer" class="dialog-footer">
      <a-button @click="visible = false">取消</a-button>
      <a-button class="ant-btn ant-btn-primary" :loading="confirmLoading" @click="dataFormSubmit()">确定</a-button>
    </span>
  </a-modal>
</template>
<script>
import { evaluateTask } from "@/httpsAPI/project/task";

export default {
  data () {
    return {
      form: this.$form.createForm(this),
      visible: false,
      confirmLoading: false,
      taskId: 0, 
      score: 0
    }
  },
  created(){
  },
  methods: {
    dataFormSubmit (e) {
      this.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        this.confirmLoading = true;
        values["taskId"] = this.taskId

        console.log('Received values of form: ', values);
        evaluateTask(values).then(res => {
          if (res.code == 10000) {
            this.$message.success({
              content: '操作成功',
              duration: 1,
              onClose: () => {
                this.form.resetFields();
                this.visible = false;
                this.$emit('refreshDataList')
              }
            })
          } else {
            this.$message.error({
              content: res.msg,
              duration: 1
            })
          }

        })
      });
    },
    handleCancel (e) {
      this.visible = false;
    },
    init (taskId, score) {
      this.visible = true
      this.$nextTick(() => {
        this.form.resetFields();

        this.taskId = taskId
        this.score = score
      });
    }
  }
}
</script>