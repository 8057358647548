<template>
  <a-modal
      title="新建部门"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      destroyOnClose
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @keyup.enter.native="dataFormSubmit()">
        <a-form-item label="部门名称">
          <a-input placeholder="请输入部门名称" v-decorator="['deptName', { rules: [{ required: true, message: '请输入部门名称' }] }]" />
        </a-form-item>

        <a-form-item label="部门简称">
          <a-input placeholder="请输入部门简称" v-decorator="['deptShorterName']" />
        </a-form-item>

        <a-form-item label="排序" prop="sort">
          <a-input-number v-decorator="['sort']" />
        </a-form-item>
        
        <a-form-item label="描述">
            <a-textarea placeholder="请输入描述" :rows="4" v-decorator="['remark']"/>
        </a-form-item>
      </a-form>
      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">取消</a-button>
        <a-button class="ant-btn ant-btn-primary" @click="dataFormSubmit()">确定</a-button>
      </span>
    </a-modal>
</template>
<script>
import { addDeptTree } from '@/httpsAPI/configure/index'
    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                parentId: 0,
                visible: false,
                confirmLoading: false,
                loadingFile: false,
                imageUrl: '',
            }
        },
        methods:{
          dataFormSubmit(e) {
            this.confirmLoading = true;
            this.form.validateFields((err, values) => {
                if (err) {
                    console.log(err)
                    return;
                }
            
                values['parentId'] = this.parentId
                console.log('Received values of form: ', values);
                addDeptTree(values).then(res=>{
                  if(res.code==10000) {
                    this.$message.success({
                        content: '操作成功',
                        duration: 1,
                        onClose: () => {
                            this.form.resetFields();
                            this.visible = false;
                            this.$emit('refreshDataList')
                        }
                    })
                  }else {
                    this.$message.error({
                        content: res.msg,
                        duration: 1
                    })
                  }
                })
            });
          },
          handleCancel(e) {
            this.visible = false;
          },
          init (pid) {
            this.parentId = pid
            this.visible = true

            this.$nextTick(() => {
                this.form.resetFields();
            });
          }
        }
    }
</script>