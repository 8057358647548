<template>
  <div class="layout-content-view" style="">
    <!-- 修改状态弹窗 -->
    <status-form v-if="updateFormVisible" ref="statusForm" @refreshDataList="init"></status-form>
    <!-- 处理详情弹窗 -->
    <resolve-detail-form v-if="resolveDetailFormVisible" ref="resolveDetailForm"  />
    <!-- 处理弹窗 -->
    <resolve-form v-if="resolveFormVisible" ref="resolveForm" @refreshDataList="init" />

    <a-card :bordered="false" :title="worderData.needName">
      <div slot="extra">
        <a-button type="primary" @click="goBack">返回</a-button>
      </div>
      <a-descriptions>
        <a-descriptions-item label="所属项目">{{worderData.projectName}}</a-descriptions-item>
        <a-descriptions-item label="优先级">
          <span v-if="worderData.level == 4">最高</span>
          <span v-if="worderData.level == 3">较高</span>
          <span v-if="worderData.level == 2">普通</span>
          <span v-if="worderData.level == 1">较低</span>
          <span v-if="worderData.level == 0">最低</span>
        </a-descriptions-item>
        <a-descriptions-item label="开始日期">{{startTime}}</a-descriptions-item>
        <a-descriptions-item label="完成日期">{{endTime}}</a-descriptions-item>
        <a-descriptions-item label="状态">
          <span v-if="worderData.status == 0">待处理</span>
          <span v-if="worderData.status == 1">处理中</span>
          <span v-if="worderData.status == 2">已完成</span>
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
    <a-row :gutter="24">
      <a-col :xl="16" :lg="24" :md="24" :sm="24" :xs="24">
        <a-card :bordered="false" style="margin-top: 20px; height:908px;overflow-y:auto;">
          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="处理人" force-render>
              <div class="antd-table-wrap">
                <a-table
                    rowKey="id"
                    size="default"
                    ref="table"
                    :columns="columns"
                    :data-source="tabData"
                    :pagination="pagination"
                  >
                    <template slot="dataTime" slot-scope="text">
                        {{text|dataTimeFilter}}
                    </template>
                    <span slot="serial" slot-scope="text, record, index">
                            {{ index + 1 }}
                          </span>
                    <span slot="status" slot-scope="text, record">
                      <a-tag color="red" v-if="record.status == 0">未处理</a-tag>
                      <a-tag color="green" v-if="record.status == 1">已处理</a-tag>
                    </span>
                    <template slot="isEnd" slot-scope="text,record">
                      <a-tag color="green" v-if="record.isEnd===1">已采纳</a-tag>
                    </template>
                    
                    <template slot="operation" slot-scope="text,record">
                      <a href="javascript:void(0)" class="table-operation-action" v-if="changeStatus==1 && record.status==0 && currentUser.userId==record.userId" @click="handleEvt(record)" v-action:resolveWorder>处理</a>
                      <a href="javascript:void(0)" v-if="record.status == 1" class="table-operation-action" @click="detailFormEvt(record)" v-action:detailWorder>查看</a>
                      <span v-if="changeStatus==2">
                      <a-popconfirm v-if="record.status == 1 && record.isEnd != 1 && currentUser.userId==record.masterId" title="您确定采纳此处理么？" ok-text="确定" cancel-text="取消" placement="left" @confirm="resolveEvt(record, 1)">
                        <a-button type="link" >采纳</a-button>
                      </a-popconfirm>
                      <a-popconfirm v-if="record.status == 1 && record.isEnd == 1 && currentUser.userId==record.masterId" title="您确定不采纳此处理么？" ok-text="确定" cancel-text="取消" placement="left" @confirm="resolveEvt(record, 2)">
                        <a-button type="link" >不采纳</a-button>
                      </a-popconfirm>
                      </span>
                    </template>
                  </a-table>
              </div>
            </a-tab-pane>

            <a-tab-pane key="2" tab="详情" force-render>
              <div class="antd-table-wrap">
                <a-descriptions title="">
                  <a-descriptions-item label="创建时间">{{$moment(this.worderData.createTime).format("YYYY-MM-DD HH:mm:ss")}}</a-descriptions-item>
                  <a-descriptions-item label="更新时间">{{this.worderData.modifyTime ? $moment(this.worderData.modifyTime).format("YYYY-MM-DD HH:mm:ss") : "" }}</a-descriptions-item>
                </a-descriptions>
                <div>
                  <span style="display:inline-block;padding-bottom:10px">描述：</span>
                   <span v-html="worderData.content"></span>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-card>
      </a-col>
      <a-col
        style="padding: 0 12px"
        :xl="8"
        :lg="24"
        :md="24"
        :sm="24"
        :xs="24"
      >
        <!-- 页面类型：0阶段 1评审点 2需求，3任务，4求助单-->
        <trends :pageType="4" :projectId="worderData.projectId" :taskId="this.$route.query.worderId" :taskType="6" />
      </a-col>
    </a-row>
    <div class="footer">
      <img src="@/assets/logo.png" alt="" />
      <p>硬科技研发管理系统</p>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import Trends from "@/components/Trends";
import StatusForm from "@/components/Status";
import ResolveDetailForm from './resolve-detail'
import ResolveForm from './resolve'
import moment from "moment";
import { detailWorder, getResolveList,endWorder } from "@/httpsAPI/worder";

  let columns=[
    {
      title:'序号',
      scopedSlots: { customRender: 'serial' }
    },
    {
      title:'处理人',
      dataIndex: 'name',
    },
    {
      title:'处理状态',
      dataIndex: 'status',
      scopedSlots: { customRender: 'status' }
    },
    {
      title: '处理时间',
      dataIndex: 'dealTime',
      scopedSlots:{customRender:"dataTime"} 
    },
    {
      title:'是否采纳',
      dataIndex: 'isEnd',
      scopedSlots: { customRender: 'isEnd' }
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      scopedSlots: {customRender: 'operation'}
    }
  ]


export default {
  name: "worderDetail",
  components: { Trends,StatusForm,ResolveDetailForm,ResolveForm },
  data() {
    return {
      updateFormVisible:false,
      resolveDetailFormVisible: false,
      resolveFormVisible: false,
      columns,
      tabData: [],
      loading: false,
      worderData: {

      },
      startTime: "",
      endTime: "",
      currentUser: {},
      type:4,
      statusId:'',
      status:"",
      changeStatus:'',
      refId:'',
      pagination: {
        onChange: (page) => {
          this.pageIndex = page;
          this.getResolveList();
        },
        pageSize: 10,
        total: 0,
        showSizeChanger: false,
        current: 1,
      },
    };
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
  },
  mounted() {
    this.init();
  },
  filters: {
    dataTimeFilter (val) {
      return val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : ''
    }
  },
  created(){
    this.refId = this.$route.query.worderId
    this.statusId = this.$route.query.taskId
    this.changeStatus = this.$route.query.changeStatus
  },
  methods: {
    //返回
    goBack(){
      this.$router.go(-1)
    },
    // 修改状态
    updateStatusEvt() {
      this.updateFormVisible = true
      this.$nextTick(() => {
        this.$refs.statusForm.init(this.statusId,this.type,this.status)
      })
    },
    // 处理
    handleEvt(record) {
      const that = this
      if(record.status==2) {
        this.$message.warning("已完成状态求助单不能进行处理！")  
        return
      }
      this.resolveFormVisible = true
      this.$nextTick(() => {
        this.$refs.resolveForm.init(this.refId)
      })
    },
    init() {
      const that = this;
      this.$nextTick(() => {
        this.currentUser = store.getters.userinfo
        detailWorder({ id: this.$route.query.worderId }).then((res) => {
          that.worderData = res.data;
          that.status = res.data.status
          this.startTime = that.worderData.startTime ? moment(that.worderData.startTime).format("YYYY年MM月DD日") : "";
          this.endTime = that.worderData.endTime ? moment(that.worderData.endTime).format("YYYY年MM月DD日") : "";

          getResolveList({worderId:this.$route.query.worderId,pageNo: this.pagination.current, pageSize: this.pagination.pageSize}).then(res=>{
            this.tabData = res.data.data

            console.log(this.tabData)
          })
        });
      });
    },
    // 标记是否采纳
    resolveEvt(record, isEnd) {
      const that = this
        endWorder({id:record.id, isEnd: isEnd}).then(res=>{
            if(res.code==10000) {
                that.$message.success({
                    content: '操作成功',
                    duration: 1,
                    onClose: () => {
                        that.init()
                    }
                })
                } else {
                that.$message.error({
                    content: res.msg,
                    duration: 1
                })
            }
        })
    },
    // 查看详情
    detailFormEvt(record) {
      const that = this
      this.resolveDetailFormVisible = true
      this.$nextTick(() => {
        this.$refs.resolveDetailForm.init(record)
      })
    }
  },
};
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
.footer{
  width: 100%;
  height: 60px;
  text-align: center;
  color: #999;
  font-size: 18px;
  margin-top: 20px;
  line-height: 100px;
  img {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin: 0px 10px;
    vertical-align: middle;
  }
  p {
    display: inline-block;
  }
}
</style>
