<template>
  <layout>
    <template slot="headerMenu">
      <div class="header-menu">
        <div class="header-menu-wraper">
          <div class="header-menu-title">需求管理</div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
export default {
name: "requirement"
}
</script>

<style scoped>

</style>