/**
 * meta 属性字典：permission --权限控制，后端返回字段匹配  title --浏览器标签title设置选用  icon --左侧菜单icon显示 defaultMenu --左侧菜单定位作用
 * @type {({redirect: string, path: string, component: (function(): Promise<*>), children: [{path: string, component: function(): Promise<*>, meta: {defaultMenu: string, permission: [string], title: string}, name: string}, {path: string, component: function(): Promise<*>, meta: {defaultMenu: string, permission: [string], title: string}, name: string}], meta: {icon: string, permission: [string], title: string}, name: string}|{redirect: string, path: string, component: (function(): Promise<*>), children: [{path: string, component: function(): Promise<*>, meta: {defaultMenu: string, permission: [string], title: string}, name: string}], meta: {icon: string, permission: [string], title: string}, name: string}|{path: string, component: (function(): Promise<*>), meta: {icon: string, permission: [string], title: string}, name: string}|{path: string, component: (function(): Promise<*>), meta: {icon: string, permission: [string], title: string}, name: string}|{path: string, component: (function(): Promise<*>), meta: {icon: string, permission: [string], title: string}, name: string})[]}
 */

 const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view')
}

export const asyncRouterMap = [
  // {
  //   path: "/forget_password",
  //   name: "Forgetpassword",
  //   component: () => import("@/views/login/forgetPass"),
  //   meta: {
  //     title: "重置密码"
  //   },
  // },
  {
    path: "/workplace/message",
    name: "ToolBar",
    hidden: true,
    component: () => import("@/views/message"),
    meta: {
      icon: "apartment",
      title: "全部消息"
    },
    redirect: "/workplace/message",
    children: [
      {
        path: "/workplace/message/list",
        name: "message",
        component: () => import("@/views/message/list"),
        meta: {
          permission: ["dashboard"],
          title: "消息列表",
          defaultMenu: "Workplace",
        }
      },
      {
        path: "/workplace/message/detail",
        name: "messageDetail",
        component: () => import("@/views/message/detail"),
        meta: {
          permission: ["dashboard"],
          title: "消息详情",
          defaultMenu: "Workplace",
        }
      }
    ],
  },
  {
    path: "/",
    name: "Workplace",
    component: () => import("@/views/workplace"),
    meta: {
      icon: "apartment",
      title: "我的工作台"
    },
    redirect: "/workplace/dashboard",
    children: [{
        path: "/workplace/dashboard",
        name: "Dashboard",
        component: () => import("@/views/workplace/dashboard"),
        meta: {
          permission: ["overview"],
          title: "概览仪表盘",
          defaultMenu: "Workplace",
        },
      },
      {
        path: "/workplace/working",
        name: "Working",
        component: () => import("@/views/workplace/working"),
        meta: {
          permission: ["working"],
          title: "工时",
          defaultMenu: "Workplace",
        },
      },
    ],
  },
  {
    path: "/project",
    name: "Project",
    component: () => import("@/views/project"),
    meta: {
      permission: ["project"],
      icon: "project",
      title: "项目管理"
    },
    redirect: "/project/index",
    children: [{
      path: "/project/index",
      name: "ProjectIndex",
      component: () => import("@/views/project/project"),
      meta: {
        permission: ["project"],
        title: "项目管理",
        defaultMenu: "Project",
      },
    }, ],
  },
  {
    path: "/projectManage",
    name: "ProjectManage",
    component: () => import("@/views/projectManage"),
    redirect: "/projectManage/overview",
    hidden: true,
    children: [{
        path: "/projectManage/overview",
        name: "ProjectManageOverview",
        component: () => import("@/views/projectManage/overview"),
        meta: {
          permission: ["project"],
          child_permission : ["projectOverview"],
          title: "项目概览",
          defaultMenu: "ProjectManageOverview",
        },
      },
      {
        path: "/projectManage/stage",
        name: "ProjectManageStage",
        component: () => import("@/views/projectManage/stage"),
        meta: {
          // keepAlive:true,
          isBack:false,
          permission: ["project"],
          child_permission : ["projectStage"],
          title: "项目阶段",
          defaultMenu: "ProjectManageOverview",
        },
      },
      {
        path: "/projectManage/stage/detail",
        name: "stageDetail",
        hidden: true,
        component: () => import("@/views/projectManage/moudles/stageDetail"),
        meta: {
          permission: ["project"],
          child_permission : ["projectStage"],
          title: "阶段详情",
          defaultMenu: "ProjectManageOverview",
        },
      },
      {
        path: "/projectManage/review",
        name: "ProjectManageReview",
        component: () => import("@/views/projectManage/review"),
        meta: {
          permission: ["project"],
          child_permission : ["projectReview"],
          title: "评审点",
          defaultMenu: "ProjectManageOverview",
        },
      },
      {
        path: "/projectManage/review/detail",
        name: "reviewDetail",
        hidden: true,
        component: () => import("@/views/projectManage/moudles/reviewDetail"),
        meta: {
          permission: ["project"],
          child_permission : ["projectReview"],
          title: "评审点详情",
          defaultMenu: "ProjectManageOverview",
        },
      },
      {
        path: "/projectManage/task",
        name: "ProjectManageTask",
        component: () => import("@/views/projectManage/task"),
        meta: {
          permission: ["project"],
          child_permission : ["projectTask"],
          title: "任务",
          defaultMenu: "ProjectManageOverview",
        },
      },
      {
        path: "/projectManage/task/detail",
        name: "taskDetail",
        hidden: true,
        component: () => import("@/views/projectManage/moudles/taskDetail"),
        meta: {
          permission: ["project"],
          child_permission : ["projectTask"],
          title: "任务详情",
          defaultMenu: "ProjectManageOverview",
        },
      },
      {
        path: "/projectManage/taskData",
        name: "ProjectManageTaskData",
        component: () => import("@/views/projectManage/taskData"),
        meta: {
          permission: ["project"],
          child_permission : ["projectFile"],
          title: "任务资料",
          defaultMenu: "ProjectManageOverview",
        },
      },
      {
        path: "/projectManage/deliverables",
        name: "ProjectManageDeliverables",
        component: () => import("@/views/projectManage/deliverables"),
        meta: {
          permission: ["project"],
          child_permission : ["projectDeliverable"],
          title: "交付物",
          defaultMenu: "ProjectManageOverview",
        },
      },
      {
        path: "/projectManage/member",
        name: "ProjectManageMember",
        component: () => import("@/views/projectManage/member"),
        meta: {
          permission: ["project"],
          child_permission : ["projectMember"],
          title: "成员",
          defaultMenu: "ProjectManageOverview",
        },
      },
      {
        path: "/projectManage/setting",
        name: "ProjectManageSetting",
        component: () => import("@/views/projectManage/setting"),
        meta: {
          permission: ["project"],
          child_permission : ["projectConfig"],
          title: "项目设置",
          defaultMenu: "ProjectManageOverview",
        },
      },
    ],
  },
  {
    path: "/requirement",
    name: "requirement",
    component: () => import("@/views/requirement"),
    meta: {
      permission: ["requirement"],
      icon: "file-add",
      title: "需求管理"
    },
    redirect: "/requirement/list",
    children: [{
        path: "/requirement/list",
        name: "requirementManage",
        component: () => import("@/views/requirement/list"),
        meta: {
          permission: ["requirement"],
          defaultMenu: "requirement",
          title: "需求管理",
        },
      },
      {
        path: "/requirement/detail",
        name: "requirementDetail",
        component: () => import("@/views/requirement/detail"),
        meta: {
          permission: ["requirement"],
          defaultMenu: "requirement",
          title: "需求详情",
        }
      }
    ]
  },
  {
    path: "/worder",
    name: "worder",
    component: () => import("@/views/worder"),
    meta: {
      permission: ["worder"],
      icon: "tags",
      title: "求助单"
    },
    redirect: "/worder/list",
    children: [{
        path: "/worder/list",
        name: "worderList",
        component: () => import("@/views/worder/list/index"),
        meta: {
          permission: ["worder"],
          defaultMenu: "worder",
          title: "已收到",
        },
        children:[{
            path: "/worder/detail",
            name: "worderDetail",
            component: () => import("@/views/worder/list/detail"),
            meta: {
              permission: ["worder"],
              defaultMenu: "worder",
              title: "已收到详情",
            },
        }]
      },

    ],
  },
  {
    path: "/effect",
    name: "Effect",
    component: () => import("@/views/effect"),
    meta: {
      permission: ["report"],
      icon: "audit",
      title: "效能管理"
    },
    redirect: "/effect/effectManage",
    children: [{
        path: "/effect/effectManage",
        name: "EffectManage",
        component: () => import("@/views/effect/effectManage"),
        meta: {
          permission: ["reportDetail"],
          defaultMenu: "Effect",
          title: "项目进展",
        },
      },
      {
        path: "/effect/ability",
        name: "Ability",
        component: () => import("@/views/effect/ability"),
        meta: {
          permission: ["reportPerson"],
          defaultMenu: "Effect",
          title: "人员能力",
        },
      },
      {
        path: "/effect/resource",
        name: "Resource",
        component: () => import("@/views/effect/resource"),
        meta: {
          permission: ["reportRescurce"],
          defaultMenu: "Effect",
          title: "资源应用",
        },
      },
    ],
  },
  {
    path: "/knowledge",
    name: "Knowledge",
    component: () => import("@/views/knowledgeBase"),
    meta: {
      permission: ["wiki"],
      icon: "cloud-server",
      title: "知识库"
    },
    redirect: "/knowledge/myself",
    children: [{
        path: "/knowledge/myself",
        name: "Myself",
        component: () => import("@/views/knowledgeBase/myself/index"),
        meta: {
          permission: ["myWiki"],
          defaultMenu: "Knowledge",
          title: "我的知识库",
        },
      },
      {
        path: "/knowledge/base",
        name: "Base",
        component: () => import("@/views/knowledgeBase/base/index"),
        meta: {
          permission: ["allWiki"],
          child_permission: ["wiki"],
          defaultMenu: "Base",
          title: "公共知识库",
        },
      },
      {
        path: "/knowledge/collection",
        name: "Collection",
        component: () => import("@/views/knowledgeBase/collection/index"),
        meta: {
          permission: ["collectionWiki"],
          defaultMenu: "Collection",
          title: "我的收藏",
        },
      },
      {
        path: "/knowledge/myself/detail",
        name: "MyselfDetail",
        hidden: true,
        component: () => import("@/views/knowledgeBase/myself/wikiDic/list"),
        meta: {
          permission: ["wiki"],
          child_permission: ["wiki"],
          defaultMenu: "Myself",
          title: "我的知识库详情",
        },
      },
      {
        path: "/knowledge/base/detail",
        name: "BaseDetail",
        hidden: true,
        component: () => import("@/views/knowledgeBase/base/wikiDic/list"),
        meta: {
          permission: ["wiki"],
          child_permission: ["wiki"],
          defaultMenu: "Knowledge",
          title: "公共知识库详情",
        },
      },
      {
        path: "/knowledge/collection/detail",
        name: "CollectionDetail",
        hidden: true,
        component: () => import("@/views/knowledgeBase/collection/wikiDic/list"),
        meta: {
          permission: ["wiki"],
          defaultMenu: "Collection",
          title: "我的收藏详情",
        },
      },
    ],
  },
  {
    path: "/PersonalDevelopment",
    name: "PersonalDevelopment",
    component: () => import("@/views/personalDevelopment"),
    meta: {
      permission: ["course"],
      icon: "solution",
      title: "个人提升"
    },
    redirect: "/personalDevelopment/course",
    children: [
      {
        path: "/personalDevelopment/course",
        name: "PersonalDevelopmentCourse",
        component: () => import("@/views/personalDevelopment/course/course"),
        meta: {
          permission: ["course"],
          defaultMenu: "PersonalDevelopment",
          title: "课程中心",
        },
        children:[
          {
            path: "/personalDevelopment/course/detail",
            name: "PersonalDevelopmentCourseDetail",
            hidden: true,
            component: () => import("@/views/personalDevelopment/course/detail"),
            meta: {
              permission: ["course"],
              defaultMenu: "PersonalDevelopment",
              title: "课程详情",
            },
          },
          {
            path: "/personalDevelopment/course/study",
            name: "PersonalDevelopmentCourseVideo",
            hidden: true,
            component: () => import("@/views/personalDevelopment/course/study"),
            meta: {
              permission: ["course"],
              defaultMenu: "PersonalDevelopment",
              title: "视频浏览",
            },
          },
        ]
      },
      {
        path: "/personalDevelopment/course/study",
        name: "PersonalDevelopmentCourseStudy",
        hidden: true,
        component: () => import("@/views/personalDevelopment/course/study"),
        meta: {
          permission: ["course"],
          defaultMenu: "PersonalDevelopment",
          title: "去学习",
        },
      },
      {
        path: "/personalDevelopment/learningRecords",
        name: "PersonalDevelopmentRecords",
        component: () => import("@/views/personalDevelopment/learningRecords"),
        meta: {
          permission: ["course"],
          defaultMenu: "PersonalDevelopment",
          title: "学习记录",
        },
      },
      {
        path: "/personalDevelopment/question",
        name: "PersonalDevelopmentUploadCourseQuestion",
        hidden: true,
        component: () => import("@/views/personalDevelopment/question/index"),
        meta: {
          permission: ["course"],
          defaultMenu: "PersonalDevelopment",
          title: "试题管理",
        },
      },
      {
        path: "/personalDevelopment/test",
        name: "PersonalDevelopmentTest",
        component: () => import("@/views/personalDevelopment/test/test"),
        meta: {
          permission: ["course"],
          defaultMenu: "PersonalDevelopment",
          title: "进入考试",
        },
      },
      {
        path: "/personalDevelopment/test/exam",
        name: "PersonalDevelopmentExam",
        hidden: true,
        component: () => import("@/views/personalDevelopment/test/exam"),
        meta: {
          permission: ["course"],
          defaultMenu: "PersonalDevelopment",
          title: "去考试",
        },
      },
      {
        path: "/personalDevelopment/test/examResult",
        name: "PersonalDevelopmentExamResult",
        hidden: true,
        component: () => import("@/views/personalDevelopment/test/examResult"),
        meta: {
          permission: ["course"],
          defaultMenu: "PersonalDevelopment",
          title: "查看答卷",
        },
      },
      {
        path: "/personalDevelopment/uploadCourse",
        name: "PersonalDevelopmentUploadCourse",
        component: () =>
          import("@/views/personalDevelopment/uploadCourse/uploadCourse"),
        meta: {
          permission: ["courseUpload"],
          defaultMenu: "PersonalDevelopment",
          title: "上传课程",
        },
      },
      {
        path: "/personalDevelopment/add",
        name: "PersonalDevelopmentUploadCourseAdd",
        hidden: true,
        component: () => import("@/views/personalDevelopment/uploadCourse/add"),
        meta: {
          permission: ["course"],
          defaultMenu: "PersonalDevelopment",
          title: "新建课程",
        },
      },
      {
        path: "/personalDevelopment/update",
        name: "PersonalDevelopmentUploadCourseUpdate",
        hidden: true,
        component: () =>
          import("@/views/personalDevelopment/uploadCourse/update"),
        meta: {
          permission: ["course"],
          defaultMenu: "PersonalDevelopment",
          title: "修改课程",
        },
      },
    ],
  },
  {
    path: "/resources",
    name: "Resources",
    component: () => import("@/views/resources"),
    meta: {
      permission: ["resources"],
      icon: "snippets",
      title: "资源管理"
    },
    redirect: "/resources/patent",
    children: [{
        path: "/resources/patent",
        name: "Patent",
        component: () => import("@/views/resources/patent"),
        meta: {
          permission: ["resources"],
          defaultMenu: "Resources",
          title: "专利",
        },
      },
      {
        path: "/resources/patent/detail/:id",
        name: "PatentDetail",
        hidden: true,
        component: () => import("@/views/resources/patent/detail"),
        meta: {
          permission: ["resources"],
          defaultMenu: "Resources",
          title: "专利详情",
        },
      },
      {
        path: "/resources/professor",
        name: "Professor",
        component: () => import("@/views/resources/professor"),
        meta: {
          permission: ["resources"],
          defaultMenu: "Resources",
          title: "专家库",
        },
      },
      {
        path: "/resources/professor/detail/:id",
        name: "ProfessorDetail",
        hidden: true,
        component: () => import("@/views/resources/professor/detail"),
        meta: {
          permission: ["resources"],
          defaultMenu: "Resources",
          title: "专家库详情",
        },
      },
      {
        path: "/resources/finished",
        name: "Finished",
        component: () => import("@/views/resources/finished"),
        meta: {
          permission: ["resources"],
          defaultMenu: "Resources",
          title: "成果库",
        },
      },
      {
        path: "/resources/finished/detail/:id",
        name: "FinishedDetail",
        hidden: true,
        component: () => import("@/views/resources/finished/detail"),
        meta: {
          permission: ["resources"],
          defaultMenu: "Resources",
          title: "成果库详情",
        },
      },
      {
        path: "/resources/stand",
        name: "Stand",
        component: () => import("@/views/resources/stand"),
        meta: {
          permission: ["resources"],
          defaultMenu: "Resources",
          title: "标准库",
        },
      },
      {
        path: "/resources/stand/detail",
        name: "StandDetail",
        hidden: true,
        component: () => import("@/views/resources/stand/detail"),
        meta: {
          permission: ["resources"],
          defaultMenu: "Resources",
          title: "标准库详情",
        },
      },
      {
        path: "/resources/periodical",
        name: "Periodical",
        component: () => import("@/views/resources/periodical"),
        meta: {
          permission: ["resources"],
          defaultMenu: "Resources",
          title: "期刊库",
        },
      },
      {
        path: "/resources/market",
        name: "Market",
        component: () => import("@/views/resources/market"),
        meta: {
          permission: ["resources"],
          defaultMenu: "Resources",
          title: "市场报告",
        },
      },
      {
        path: "/resources/Wanfang",
        name: "Wanfang",
        component: () => import("@/views/resources/wanfang"),
        meta: {
          permission: ["resources"],
          defaultMenu: "Resources",
          title: "万方数据",
        },
      },
      {
        path: "/resources/zlf",
        name: "Zlf",
        component: () => import("@/views/resources/zlf"),
        meta: {
          permission: ["resources"],
          defaultMenu: "Resources",
          title: "文献库",
        },
      },
    ],
  },
  {
    path: "/declare",
    name: "Declare",
    component: () => import("@/views/declare"),
    meta: {
      permission: ["policy"],
      icon: "radar-chart",
      title: "科技政策申报"
    },
    redirect: "/declare/manage",
    children: [{
        path: "/declare/manage",
        name: "DeclareManage",
        component: () => import("@/views/declare/list"),
        meta: {
          permission: ["policy"],
          defaultMenu: "Declare",
          title: "科技政策申报",
        },
      },
      {
        path: "/declare/detail",
        name: "DeclareDetail",
        component: () => import("@/views/declare/detail"),
        meta: {
          permission: ["policy"],
          defaultMenu: "Declare",
          title: "科技政策申报详情",
        },
      },
    ],
  },
  {
    path: "/personal",
    name: "Personal",
    hidden: true,
    component: () => import("@/views/personal"),
    meta: {
      permission: ["profile"],
      icon: "bank",
      title: "个人中心"
    },
    redirect: "/personal/settings",
    children: [{
      path: "/personal/settings",
      name: "PersonalManage",
      component: () => import("@/views/personal/personal"),
      meta: {
        permission: ["profile"],
        defaultMenu: "Personal",
        title: "个人中心",
      },
    }, ],
  },
  {
    path: "/configure",
    name: "Configure",
    hidden: true,
    component: () => import("@/views/configure"),
    meta: {
      permission: ["configure"],
      icon: "setting",
      title: "配置中心"
    },
    redirect: "/configure",
    children: [{
      path: "/configure",
      name: "ConfigureInfo",
      component: () => import("@/views/configure/configure"),
      meta: {
        permission: ["configure"],
        defaultMenu: "Configure",
        title: "配置中心",
      },
    }, ],
  },
  {
    path: "/search",
    name: "search",
    hidden: true,
    component: () => import("@/views/search"),
    redirect: "/search",
    meta: {
      permission: ["help"],
      title: "搜索"
    },
    children: [{
      path: "/search/:kw?",
      name: "search",
      component: () => import("@/views/search/content"),
      meta: {
        permission: ["help"],
        defaultMenu: "search",
        title: "搜索结果",
      }
    }]
  },
  {
    path: "/help",
    name: "Help",
    hidden: true,
    component: () => import("@/views/help"),
    meta: {
      permission: ["help"],
      icon: "setting",
      title: "帮助与反馈"
    },
    redirect: "/newStudy",
    children: [
      {
        path: "/newStudy",
        name: "NewStudy",
        component: () => import("@/views/help/newStudy"),
        meta: {
          permission: ["help"],
          defaultMenu: "Help",
          title: "新手引导",
        },
      },
      {
        path: "/help",
        name: "Help",
        component: () => import("@/views/help/help"),
        meta: {
          permission: ["help"],
          defaultMenu: "Help",
          title: "帮助手册",
        }
      },
      {
        path: "/workday",
        name: "Workday",
        component: () => import("@/views/help/workday"),
        meta: {
          permission: ["help"],
          defaultMenu: "Help",
          title: "更新日志",
        },
      },
      {
        path: "/contact",
        name: "Contact",
        component: () => import("@/views/help/contact"),
        meta: {
          permission: ["help"],
          defaultMenu: "Help",
          title: "联系客服",
        },
      }
    ],
  },
];

export const constantRouterMap = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login"),
    meta: {
      title: "登录"
    },
  },
  {
    path: "/v3login",
    name: "v3Login",
    component: () => import("@/views/login/rzs"),
    meta: {
      title: "登录"
    },
  },
  {
    path: "/v2login",
    name: "v2Login",
    component: () => import("@/views/login/v2Login"),
    meta: {
      title: "登录"
    },
  },
  {
    path: "/v4login",
    name: "v4Login",
    component: () => import("@/views/login/v4Login"),
    meta: {
      title: "岚山登录"
    },
  },
  {
    path: "/forget_password",
    name: "Forgetpassword",
    component: () => import("@/views/login/forgetPass"),
    meta: {
      title: "重置密码"
    },
  },
  {
    path: "/v3forget_password",
    name: "v3Forgetpassword",
    component: () => import("@/views/login/rzsRorgetPass"),
    meta: {
      title: "日照市重置密码"
    },
  },
  {
    path: "/v2forget_password",
    name: "v2Forgetpassword",
    component: () => import("@/views/login/v2RorgetPass"),
    meta: {
      title: "挑战赛重置密码"
    },
  },
  {
    path: "/v4forget_password",
    name: "v4Forgetpassword",
    component: () => import("@/views/login/v4RorgetPass"),
    meta: {
      title: "岚山重置密码"
    },
  },
{
    path: '/404',
    component:()=>import('@/views/exception/404')
}, ];
