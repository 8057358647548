import request from "@/utils/request";
import requestFile from "@/utils/requestFile";
import {downloadFile} from '@/httpsAPI/common' 

//学习记录-列表
export function getStudyList (params) {
  return request({
    url: "/course/courseTrn/list",
    method: 'post',
    data: params
  })
}
//学习记录-个人信息
export function getPersonalInfo (params) {
  return request({
    url: "/course/courseTrn/personalInfo",
    method: 'post',
    data: params
  })
}
//学习记录-课程分类
export function getCourseType (params) {
  return request({
    url: "/course/CourseType/select",
    method: 'post',
    data: params
  })
}
//课程列表
export function getCourseList (params) {
  return request({
    url: "/course/list",
    method: 'post',
    data: params
  })
}
//课程列表
export function getNewCourseList (params) {
  return request({
    url: "/course/entCourseList",
    method: 'post',
    data: params
  })
}
//课程列表删除
export function delCourseList (params) {
  return request({
    url: "/course/delete",
    method: 'post',
    data: params
  })
}
//课程列表新建
export function addCourseList (params) {
  return request({
    url: "/course/add",
    method: 'post',
    data: params
  })
}
//课程详情
export function detailCourse (params) {
  return request({
    url: "/course/detail/"+params,
    method: 'get',
    data: {}
  })
}
//修改课程
export function updateCourse (params) {
  return request({
    url: "/course/update",
    method: 'post',
    data: params
  })
}
//考题列表
export function getQuestionList (params) {
  return request({
    url: "/course/question/list",
    method: 'post',
    data: params
  })
}
//新建考题
export function addQuestion (params) {
  return request({
    url: "/course/question/add",
    method: 'post',
    data: params
  })
}
//考题删除
export function delQuestion (params) {
  return request({
    url: "/course/question/delete",
    method: 'post',
    data: params
  })
}
//全部清空
export function emptyDeleteEvt (params) {
  return request({
    url: "/course/question/all-delete/"+params,
    method: 'post',
    data: {}
  })
}
// 导出下载模板
export function downQuestionTemplate(params){
  return downloadFile("/course/question/download", '试题导入模板.xlsx', params)
}
// 上传系统课程试题
export function uploadQuestion(params){
  return request({
    url:"/course/question/upload",
    method:"post",
    data:params
  })
}
// 查询系统课程试题规则
export function getQuestionRule(params){
  return request({
    url:"/course/question/rule/get/" + params,
    method:"post",
    data:{}
  })
}
// 保存系统课程试题规则
export function saveQuestionRule(params){
  return request({
    url:"/course/question/rule/save",
    method:"post",
    data:params
  })
}
//题库详情
export function detailQuestion(params){
  return request({
    url:"/course/question/detail/" + params,
    method:"get",
    data:{}
  })
}
//考题接口-考题答题
export function courseLastAnswer(params){
  return request({
    url:"/course/exam/answer",
    method:"post",
    data:params
  })
}
//考题接口-答题
export function courseQuestionSubmit(params){
  return request({
    url:"/course/exam/examResult",
    method:"post",
    data:params
  })
}
//视频地址
export function getVideoUrl(params){
  return request({
    url:"/course/getVideoUrl/" + params,
    method:"post",
    data:{}
  })
}
//视频更新状态
export function updateCourseState(params){
  return request({
    url:"/course/courseTrn/updateCourseState",
    method:"post",
    data:params
  })
}
//考试列表
export function getCourseTrnExamList(params){
  return request({
    url:"/course/courseTrn/getCourseTrnExamList",
    method:"post",
    data:params
  })
}
//考试详情
export function getExamDetail(params){
  return request({
    url:"/course/exam/list/" + params,
    method:"post",
    data:{}
  })
}
//答卷详情
export function courseExamDetails(params){
  return request({
    url:"/course/exam/courseExamDetails/" + params,
    method:"post",
    data:{}
  })
}

//大视频上传
export function mergeFile(params){
  return request({
    url:"/project/upload/mergeFile" ,
    method:"post",
    data:params
  })
}

// 不分片视频上传
export function videoUpload(params){
  return requestFile({
    url:"/project/upload/video-upload" ,
    method:"post",
    data:params
  })
}
//记录课程学习时长
export function getStudyTime(params){
  return requestFile({
    url:"/course/courseTrn/recordStudyTime" ,
    method:"post",
    data:params
  })
}