<template>
  <div>
    <!-- 关联任务弹出窗口-->
    <task-relate ref="relateTaskForm"  v-if="relateTaskVisible" @refreshDataList="init" />
    <!-- 修改状态弹窗 -->
    <status-form v-if="updateFormVisible" ref="statusForm" @refreshDataList="init" />
    <a-card :bordered="false" :title="stage.planName">
      <div slot="extra">
        <a-button type="primary" @click="$router.back()">返回</a-button>
      </div>
      <a-descriptions>
        <a-descriptions-item label="进度">{{stage.progress}}%</a-descriptions-item>
        <a-descriptions-item label="计划开始日期" v-if="stage.planType==1">{{startTime}}</a-descriptions-item>
        <a-descriptions-item label="计划完成日期">{{endTime}}</a-descriptions-item>
        <a-descriptions-item label="状态">
          <span v-if="stage.status==0">待开始</span>
          <span v-if="stage.status==1">进行中</span>
          <span v-if="stage.status==2">已完成</span>
          <div style="display:inline-block">
            <a-tooltip placement="bottom" title="修改状态" style="margin-left:5px" v-permit:updateStageStatus="{projectId:$route.query.projectId}">
            <a-icon type="edit" @click="updateStatusEvt()" />
            </a-tooltip>
          </div>
        </a-descriptions-item>
      </a-descriptions>
    </a-card>

    <a-row :gutter="24">
        <a-col :xl="16" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :bordered="false" style="margin-top: 20px;height:908px;overflow-y:auto;">
                <a-tabs default-active-key="1">
                  <a-tab-pane key="1" tab="关联任务" force-render>
                    <div class="antd-table-wrap" :style="{ minHeight: '460px' }">
                      <a-button type="primary" icon="link" @click="relateTaskEvt" v-permit:relateTask="{projectId:$route.query.projectId}">关联任务</a-button>
                      <a-table
                        rowKey="id"
                        size="default"
                        ref="refTable"
                        :columns="refColumns"
                        :data-source="tabRefData"
                      >
                        <template slot="taskName" slot-scope="text, record">
                            <a-button type="link" @click="viewTaskEvt(record)">{{record.name}}</a-button>
                        </template>
                        <span slot="serial" slot-scope="text, record, index">
                                {{ index + 1 }}
                              </span>
                        <template slot="operation" slot-scope="text,record">
                          <a-popconfirm
                              :title="'确定删除['+record.title+']关联任务么？'"
                              ok-text="确定"
                              cancel-text="取消"
                              placement="topLeft"
                              @confirm="deleteRefTaskEvt(record)"
                              >
                              <a-button type="link" v-permit:deleteRelateTask="{projectId:$route.query.projectId}">删除</a-button>
                          </a-popconfirm>
                        </template>
                      </a-table>
                    </div>
                  </a-tab-pane>
                  <a-tab-pane key="2" tab="详情" force-render>
                    <div class="antd-table-wrap" :style="{ minHeight: '460px' }">
                      <a-descriptions title="">
                        <a-descriptions-item label="创建时间">{{$moment(this.stage.createTime).format('YYYY-MM-DD HH:mm:ss')}}</a-descriptions-item>
                        <a-descriptions-item label="更新时间">{{this.stage.modifyTime ? $moment(this.stage.modifyTime).format('YYYY-MM-DD HH:mm:ss') : ''}}</a-descriptions-item>
                      </a-descriptions>
                      <a-descriptions title="">
                        <a-descriptions-item label="描述">
                          {{stage.content}}
                        </a-descriptions-item>
                      </a-descriptions>
                      <a-descriptions title="">
                        <a-descriptions-item label="">
                          <a-col :span="24">
                          </a-col>
                        </a-descriptions-item>
                      </a-descriptions>
                    </div>
                  </a-tab-pane>
                </a-tabs>
          </a-card>
        </a-col>
        <a-col
          style="padding: 0 12px"
          :xl="8"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <!-- 页面类型：0阶段 1评审点 2需求，3任务，4求助单-->
          <trends :pageType="0" :projectId="this.$route.query.projectId" :taskId="this.$route.query.planId" :taskType="7" />
        </a-col>
      </a-row>

  </div>
</template>

<script>
import moment from 'moment'
import Trends from "@/components/Trends"
import StatusForm from "@/components/Status";
import {detailStage, getRelatedTaskList, deleteRelation} from "@/httpsAPI/project/stage"
import taskRelate from "./taskRelate";

const refColumns = [
  {
    title:'序号',
    scopedSlots: { customRender: 'serial' }
  },
  {
    title:'任务名称',
    dataIndex: 'title'
  },
  {
    title: '操作',
    dataIndex: 'action',
    align: 'center',
    scopedSlots: {customRender: 'operation'}
  }
]

export default {
  data() {
    return {
      stage : {},
      startTime : '',
      endTime : '',
      refColumns,
      tabRefData: [],
      relateTaskVisible: false,
      updateFormVisible: false,
      statusType:1, // （类型(1：计划(项目阶段，评审点)，2：项目，3：任务 4:求助单）
      statusId:'',
      status: 0,
    };
  },
  created(){
    this.init()
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
  },
  components: {
    Trends,
    StatusForm,
    taskRelate
  },
  methods: {
    init() {
      const that = this
      this.statusId = this.$route.query.planId
      this.$nextTick(() => {
          detailStage({id:this.$route.query.planId}).then(res=>{
              console.log(res)
              that.stage = res.data
              that.status = res.data.status
              that.startTime = that.stage.startTime ? moment(that.stage.startTime).format('YYYY年MM月DD日') : ''
              that.endTime = moment(that.stage.endTime).format('YYYY年MM月DD日')

              // 加载关联任务列表
              getRelatedTaskList({relatId: that.$route.query.planId}).then(res=>{
                that.tabRefData = res.data
              })
          })
      })
    },
    // 修改状态
    updateStatusEvt() {
      this.updateFormVisible = true
      this.$nextTick(() => {
        this.$refs.statusForm.init(this.statusId,this.statusType,this.status)
      })
    },
    // 关联任务
    relateTaskEvt(){
      this.relateTaskVisible=true
      this.$nextTick(() => {
        this.$refs.relateTaskForm.init(this.$route.query.projectId, this.$route.query.planId)
      })
    },
    // 删除项目阶段和任务的关联关系
    deleteRefTaskEvt(record) {
      const that = this
      deleteRelation({id:record.id}).then(res=>{
          if(res.code==10000) {
              that.$message.success({
                  content: '操作成功',
                  duration: 1,
                  onClose: () => {
                      that.init()
                  }
              })
              } else {
              that.$message.error({
                  content: res.msg,
                  duration: 1
              })
          }
      })
    },
  }
};
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
</style>
