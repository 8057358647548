<template>
  <div>
    <div :style="{padding:'80px 0 20px 0'}">
      <a-steps labelPlacement="vertical" :current="reviewIndex">
        <a-step title="评审点" >
          <div class="antd-step-icon" slot="icon"></div>
        </a-step>

        <template v-for="review in reviewList">
          <a-step :description="$moment(review.endTime).format('YYYY年MM月DD日')" :key="review.id">
            <div class="antd-step-icon" slot="icon"></div>
            <div slot="title" class="antd-step-title">{{review.planName}}</div>
            <div slot="subTitle">状态：
              <span v-if="review.status == 1">已完成</span>
              <span v-if="review.status == 0">未完成</span>
              </div>
          </a-step>
        </template>
        
      </a-steps>
    </div>
    <a-card>
      <div class="antd-toolbar">
        <div class="antd-toolbar-left">
          <a-button type="primary" @click="addReviewEvt" v-permit:addReview="{projectId:$route.query.projectId}"> + 新建评审点</a-button>
        </div>
      </div>
      <s-table
        rowKey="id"
        :data="loadData"
        size="default"
        ref="table"
        :columns="columns"
      >
        <template slot="dataTime" slot-scope="text">
            {{text|dataTimeFilter}}
          </template>
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="deliverableTotal" slot-scope="text, record">
          {{ record.submittedDeliverable + '/' + record.deliverableTotal }}
        </span>
        <template slot="reviewStatus" slot-scope="text, record">
          <a-select :default-value="record.status" style="width: 100px;" @change="value => handleChange(value, record.id)" :disabled="!accessUpdateStatus || (isManageAuth==0 && record.masterId!=userinfo.userId && accessUpdateStatus)">
            <a-select-option :value="0">
              未完成
            </a-select-option>
            <a-select-option :value="1">
              已完成
            </a-select-option>
          </a-select>
        </template>
        <template slot="operation" slot-scope="text,record">
          <a-button type="link" @click="detailEvt(record)">详情</a-button>
          <a-popconfirm
              :title="'确定删除评审点['+record.planName+']么？'"
              ok-text="确定"
              cancel-text="取消"
              placement="topLeft"
              @confirm="deleteEvt(record)"
              >
              <a href="javascript:void(0)" class="table-operation-action" v-if="isManageAuth==1 || record.masterId==userinfo.userId" v-permit:deleteReview="{projectId:$route.query.projectId}">删除</a>
          </a-popconfirm>
        </template>
      </s-table>
    </a-card>
    <review-add ref="addForm" @cancel="()=>{this.addVisible=false}" :visible="addVisible" :confirm-loading="confirmLoading" @refreshDataList="$refs.table.refresh(true)"/>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters } from "vuex";
import moment from 'moment'
import { getUserProjectPermList } from "@/httpsAPI/configure";
import {getProjectReviewList, deleteReview, updateReviewStatus} from "@/httpsAPI/project/review";
import reviewAdd from "./moudles/reviewAdd";

const columns=[
  {
    title:'序号',
    scopedSlots: { customRender: 'serial' }
  },
  {
    title:'评审点名称',
    dataIndex: 'planName',
  },
  {
    title:'状态',
    dataIndex: 'status',
    scopedSlots: {customRender: 'reviewStatus'}
  },
  {
    title: '计划完成日期',
    dataIndex: 'endTime',
    scopedSlots:{customRender:"createTime"} 
  },
  {
    title: '完成日期',
    dataIndex: 'realEndTime',
    scopedSlots:{customRender:"createTime"} 
  },
  {
    title: '交付物',
    dataIndex: 'deliverableTotal',
    scopedSlots: {customRender: 'deliverableTotal'}
  },
  {
    title: '操作',
    dataIndex: 'action',
    align: 'center',
    scopedSlots: {customRender: 'operation'}
  }
]
export default {
  name: "review",
  components:{
    reviewAdd
  },
  data(){
    return{
      columns,
      reviewList: [],
      aclList: [],
      reviewIndex: 0,
      isManageAuth: 0, // 是否管理授权操作数据
      accessUpdateStatus: false,
      loadData:paramer=>{
        const reviewParam = {
          planType : 2,
          projectId : this.$route.query.projectId
        }
        return getProjectReviewList(reviewParam).then(res=>{
          this.reviewList = res.data.data

          for (let i = 0, len = this.reviewList.length; i < len; i++) {
            if(this.reviewList[i].status==1) {
              this.reviewIndex = i + 1;
            }
          }

          return res.data
        })
      },
      addVisible:false,
      confirmLoading:false
    }
  },
  filters: {
    dataTimeFilter (type) {
      return moment(type).format('YYYY-MM-DD HH:mm:ss');
    }
  },
  created() {
    this.getPermData()
  },
  computed: {
    ...mapGetters(["userinfo"]),
  },
  methods:{
    // 更新评审点状态按钮权限处理
    getPermData() {
      const permits = store.getters.permits

      if(permits.length==0) {
        getUserProjectPermList({id: this.$route.query.projectId}).then((res) => {
          let permitList = {}
          permitList.permissions = res.data.permissions
          permitList.isAuth = res.data.isAuth
          permitList.projectId = this.$route.query.projectId
          permitList.isManageAuth = res.data.isManageAuth
          this.isManageAuth = res.data.isManageAuth
          
          store.commit('SET_PERMITS',permitList)

          if(permitList.isAuth==0) { // 项目已归档,且用户非管理人员
            return
          }
          const accessBtns = permitList.permissions.filter(item=>item.permissionId==='projectReview')[0].actionEntitySet
          this.aclList = accessBtns.map(action => {
                    return action.action
                  })
          if (this.aclList.includes('updateReviewStatus')) {
            this.accessUpdateStatus = true
          }
        })
      } else {
          this.isManageAuth = permits.isManageAuth
          
          if(permits.isAuth==0) { // 项目已归档,且用户非管理人员
            return
          }
          const accessBtns = permits.permissions.filter(item=>item.permissionId==='projectReview')[0].actionEntitySet
          this.aclList = accessBtns.map(action => {
                    return action.action
                  })
          if (this.aclList.includes('updateReviewStatus')) {
            this.accessUpdateStatus = true
          }
      }
    },
    // 新建评审点
    addReviewEvt(){
      this.stageAddVisible=true
      this.$nextTick(() => {
        this.$refs.addForm.init()
      })
    },
    // 删除评审点
    deleteEvt(item) {
        const that = this
        deleteReview(item.id).then(res=>{
            if(res.code==10000) {
                that.$message.success({
                    content: '操作成功',
                    duration: 1,
                    onClose: () => {
                        that.$refs.table.refresh(true)
                    }
                })
                } else {
                that.$message.error({
                    content: res.msg,
                    duration: 1,
                })
                }
            })
    },
    // 评审点详情
    detailEvt(record) {
      this.$router.push({ path: '/projectManage/review/detail', query: {projectId: this.$route.query.projectId, planId: record.id} })
    },
    async handleChange(value, id) {
      console.log(value,"111")
        const that = this
        await updateReviewStatus({id:id, status:value}).then(res=>{
            if(res.code==10000) {
                that.$message.success({
                    content: '操作成功',
                    duration: 1,
                    onClose: () => {
                      that.$refs.table.refresh(true)
                    }
                })
                } else {
                that.$message.error({
                    content: res.msg,
                    duration: 3,
                    value:"0",
                    onClose: () => {
                      that.$refs.table.refresh(true)
                    }
                })
                }
            })
    },
  }
}
</script>

<style scoped>
.antd-step-title{
      height: 26px;
    line-height: 26px;
    font-size: 12px;
    border-radius: 13px;
    min-width: 168px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid #6eadf0;
    position: absolute;
    top: -80px;
    left: -55px;
}
</style>