import request from "@/utils/request";

// 查询地区
export function queryProvince(data) {
  return request({
    url: "/resources/queryCityList",
    method: "post",
    data,
  });
}

// 行业分类
export function queryIndustry(data) {
  return request({
    url: "/resources/queryRelateExpertList",
    method: "post",
    data
  });
}

// 统计资源利用次数
export function addResourcesUse(params) {
  return request({
    url: "/report/addResources",
    method: "post",
    data: params
  });
}

// 获取市场报告账号密码
export function getResourcesID(params) {
  return request({
    url: "/resources/getResourcesID",
    method: "post",
    data: params
  });
}

