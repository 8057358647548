import Vue from 'vue'
import store from '@/store'
import { getUserProjectPermList, getUserModulePermList } from "@/httpsAPI/configure";

/**
 * Permit 项目权限指令
 * 指令用法：
 *  - 在需要控制 permit 级别权限的组件上使用 v-permit:[method] , 如下：
 *    <i-button v-permit:add >添加用户</a-button>
 *    <a-button v-permit:delete>删除用户</a-button>
 *    <a v-permit:edit @click="edit(record)">修改</a>
 *
 *  - 当前用户没有权限时，组件上使用了该指令则会被隐藏
 *  - 当后台权限跟 pro 提供的模式不同时，只需要针对这里的权限过滤进行修改即可
 */
function checkPermit(permitList, permissionId, el, actionName, isPermit) {
  permitList.map(per => {
    if (per.actionEntitySet != null && per.actionEntitySet.length > 0) {
      const action = per.actionEntitySet.map(action => {
        return action.action
      })
      per.actionList = action
    }
  })

  permitList.forEach(p => {
    if (!permissionId.includes(p.permissionId)) {
      return
    }

    if (p.actionList && !p.actionList.includes(actionName)) {
      el.parentNode && el.parentNode.removeChild(el) || (el.style.display = 'none')
    }
  })

  if(permitList.length==0) {
    el.parentNode && el.parentNode.removeChild(el) || (el.style.display = 'none')
  }
}
const permit = Vue.directive('permit', {
  inserted: function (el, binding, vnode) {
    const actionName = binding.arg
    const permits = store.getters.permits
    const elVal = vnode.context.$route.meta.child_permission
    const permissionId =  elVal instanceof String && [elVal] || elVal

    // 查询用户模块权限列表
    if(binding.value.moduleId) {
      if(!permits.isLoad) {
        getUserModulePermList({id: binding.value.moduleId}).then((res) => {
          let permitList = {}
          permitList.permissions = res.data
          permitList.isLoad = 1
          
          store.commit('SET_PERMITS',permitList)
         return checkPermit(permitList.permissions, permissionId, el, actionName, 0)
        })
      } else {
        return checkPermit(permits.permissions, permissionId, el, actionName, 0)
      }
    } else {
      // 查询用户项目中的权限列表
      if(permits.projectId!=binding.value.projectId) {
        getUserProjectPermList({id: binding.value.projectId}).then((res) => {
          let permitList = {}
          permitList.permissions = res.data.permissions
          permitList.isAuth = res.data.isAuth
          permitList.isManageAuth = res.data.isManageAuth
          permitList.projectId = binding.value.projectId
          
          store.commit('SET_PERMITS',permitList)

          console.log('permitList**********', permitList)

          if(permitList.isAuth==1) {
            return checkPermit(permitList.permissions, permissionId, el, actionName, 1)
          } else {
            el.parentNode && el.parentNode.removeChild(el) || (el.style.display = 'none')
          }
        })
      } else {
        if(permits.isAuth==1) {
          return checkPermit(permits.permissions, permissionId, el, actionName, 1)
        } else {
          el.parentNode && el.parentNode.removeChild(el) || (el.style.display = 'none')
        }
      }
    }
  }
})

export default permit
