<template xmlns:add-task="http://www.w3.org/1999/html">
  <div class="antd-page-content">
    <a-menu mode="horizontal" v-model="currentMenu">
      <a-menu-item v-for="(item,index) in taskTabList" :key="index" @click="statusSearchEvt(item)">{{ item.title }}</a-menu-item>
    </a-menu>
    <a-card>
      <div class="antd-toolbar">
        <div class="antd-toolbar-left">
          <a-button type="primary" @click="createFormEvt" v-permit:addTask="{projectId:$route.query.projectId}"> + 新建任务</a-button>
        </div>

        <div class="antd-toolbar-right">
          <div class="antd-toolbar-item">
            <a-input-search style="margin-right: 16px; width: 272px;" placeholder="请输入任务名称进行搜索" v-model="queryParams.title" @keyup.enter.native="$refs.table.refresh(true)"/>
            <a-tooltip>
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-icon type="reload" @click="$refs.table.refresh(true)" />
            </a-tooltip>
          </div>
        </div>
      </div>
      <s-table
          rowKey="id"
          :data="loadData"
          size="default"
          ref="table"
          :columns="columns"
          :pageNum="pageNum"
          :pagination="{ pageSizeOptions: ['10', '20', '50', '100', '150'] }"
      >
        <span slot="status" slot-scope="text, record">
          <a-tag color="red" v-if="record.status == 0">待开始</a-tag>
          <a-tag color="orange" v-if="record.status == 1">进行中</a-tag>
          <a-tag color="green" v-if="record.status == 2">已完成</a-tag>
        </span>
        <span slot="level" slot-scope="text, record">
          <a-tag color="red" v-if="record.level == 1">最高</a-tag>
          <a-tag color="orange" v-if="record.level == 2">较高</a-tag>
          <a-tag color="blue" v-if="record.level == 3">普通</a-tag>
          <a-tag color="blue" v-if="record.level == 4">较低</a-tag>
          <a-tag color="blue" v-if="record.level == 5">最低</a-tag>
        </span>
        <span slot="evaluateTime" slot-scope="text, record">
          <a-icon type="star" theme="filled" v-if="record.evaluateTime == null" style="fontSize:20px" />
          <a-icon type="star" theme="filled" v-if="record.evaluateTime !=null" style="color:yellow;fontSize:20px" />
        </span>
        <template slot="taskTitle" slot-scope="text,record">
          <a-button type="link"  @click="detailEvt(record)">{{ record.title }}</a-button>
          <a-tag v-if="record.isWarning==0" color="#F5222D" style="border-radius:50%; width:24px; height: 24px; line-height: 22px; padding:0; text-align: center;">警</a-tag>
        </template>
        <template slot="action" slot-scope="text,record">
          <a href="javascript:void(0)" class="table-operation-action" @click="detailEvt(record)">详情</a>
          <a href="javascript:void(0)" class="table-operation-action" @click="updateFormEvt(record)" v-permit:updateTask="{projectId:$route.query.projectId}">编辑</a>
          <a-popconfirm
              :title="'确定删除任务['+record.title+']么？'"
              ok-text="确定"
              cancel-text="取消"
              placement="topLeft"
              @confirm="deleteEvt(record)"
              >
              <a href="javascript:void(0)" class="table-operation-action" v-permit:deleteTask="{projectId:$route.query.projectId}">删除</a>
          </a-popconfirm>
        </template>
        <template slot="taskDate" slot-scope="text">
          <a-tag color="red" v-if="text">{{ text }}</a-tag>
        </template>
      </s-table>
    </a-card>

    <!-- 新建弹窗 -->
    <add-form v-if="addFormVisible" ref="addForm" @refreshDataList="$refs.table.refresh(true)"></add-form>
    <!-- 修改弹窗 -->
    <update-form v-if="updateFormVisible" ref="updateForm" @refreshDataList="$refs.table.refresh(true)"></update-form>
  </div>
</template>

<script>
import {getProjectManageTaskList, deleteTask} from "@/httpsAPI/project/task";
import addForm from "./moudles/taskAdd";
import updateForm from "./moudles/taskUpdate";


const taskTabList = [
  {title:'全部任务',value:'',isAlarm:''},
  {title:'待开始',value: '0',isAlarm:''},
  {title:'进行中',value: '1',isAlarm:''},
  {title:'已完成',value: '2',isAlarm:''},
  {title:'预警任务',value: '',isAlarm:'1'}
  ]
const columns=[
  {
    title:'优先级',
    dataIndex:'level',
    scopedSlots: {customRender: 'level'}
  },
  {
    title:'任务',
    dataIndex: 'title',
    scopedSlots:{customRender:"taskTitle"}
  },
  {
    title:'负责人',
    dataIndex: 'masterName'
  },
  {
    title:'开始时间',
    dataIndex: 'startTime'
  },
  {
    title:'结束时间',
    dataIndex: 'endTime'
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: {customRender: 'status'}
  },
  {
    title: '是否评价',
    dataIndex: 'evaluateTime',
    scopedSlots: {customRender: 'evaluateTime'},
    align : 'center'
  },
  {
    title: '操作',
    dataIndex: 'action',
    align : 'center',
    scopedSlots: {customRender: 'action'}
  }
]
export default {
  name: "task",
  components:{
    addForm,
    updateForm
  },
  data(){
    return{
      taskTabList,
      columns,
      queryParams:{},
      pageNum: 1,
      addFormVisible: false,
      updateFormVisible: false,
      loadData:parameter=>{
        this.pageNum = parameter.pageNo
          if (!parameter.sortField && !parameter.sortOrder) {
            parameter.sortField = 'createTime'
            parameter.sortOrder = 'descend'
          }
          
          this.queryParams.projectId = this.$route.query.projectId
          this.queryParams.taskType = 2
        return getProjectManageTaskList(Object.assign(parameter, this.queryParams)).then(res=>{
          return res.data
        })
      },
      currentMenu:[0],
      detailVlsible:false,
      detailData:{}
    }
  },
  methods:{
    statusSearchEvt(item){
      this.queryParams.status = item.value;
      this.queryParams.isAlarm = item.isAlarm
      this.$refs.table.refresh(true)
    },
    // 详情
    detailEvt(record){
      this.$router.push({ path: '/projectManage/task/detail', query: {projectId: this.$route.query.projectId, taskId: record.id} })
    },
    // 新建
    createFormEvt() {
      this.addFormVisible = true
      this.$nextTick(() => {
        this.$refs.addForm.init(this.$route.query.projectId)
      })
    },
    // 修改
    updateFormEvt(record) {
      this.updateFormVisible = true
      this.$nextTick(() => {
        this.$refs.updateForm.init(record)
      })
    },
    // 删除
    deleteEvt(record) {
      console.log(record,"record")
        const that = this
        deleteTask({id:record.id}).then(res=>{
            if(res.code==10000) {
                that.$message.success({
                    content: '操作成功',
                    duration: 1,
                    onClose: () => {
                        that.$refs.table.refresh(true)
                    }
                })
                } else {
                that.$message.error({
                    content: res.msg,
                    duration: 1
                })
            }
        })
    },
  }
}
</script>

<style scoped>

</style>