<template>
  <div class="antd-panel">
    <div class="top">
      <h2>专利</h2>
    </div>
    <div class="antd-page-content">
      <a-input-search
        placeholder="可以输入公司、发明（设计）人、关键词、专利号等"
        size="large"
        @search="getList"
        class="input-search"
        style="width: 50%"
      >
        <a-button slot="enterButton" type="primary">
          检索
        </a-button>
      </a-input-search>
    </div>
    <div class="content">
      <iframe id="contents" :src="src" frameborder="0"  v-if="isshow" style="width:100%;height:800px;margin-top:100px"></iframe>
    </div>
  </div>
</template>

<script>
import { addResourcesUse } from "@/httpsAPI/resources/common";
export default {
  name: "patent",
  data() {
    return {
      listData: [],
      current: 1,
      src:'',
      isshow:false
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  created(){
  },
  methods: {
    getList() {
      addResourcesUse({type:0, content:'专利统计'}).then(res=>{})
      this.isshow = true
      window.open("/patent.html","_blank")
      //this.src= window._CONFIG["domainURL"]  + "patent.html"
    },
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
    },
    goDetail (id) {
      this.$router.push({ path: `/resources/patent/detail/${id}` })
    }
  },
};
</script>

<style lang="less" scoped>
.antd-panel {
  .top {
    h2 {
      text-align: center;
      font-size: 30px;
      font-weight: 900;
      padding: 20px 0;
    }
  }
  .antd-page-content {
    position: relative;
    .input-search {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .list {
      padding-top: 110px;
      .item {
        cursor: pointer;
        padding: 20px;
        margin-top: 20px;
        box-shadow: 0 0 8px #888888;
        .top-title {
          display: flex;
          align-items: baseline;
          justify-content: flex-start;
          & > .des {
            margin-left: 20px;
          }
        }
        .f-lh2 {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
    .pagination {
      margin-top: 50px;
      text-align: right;
    }
  }
}
</style>
