import request from "@/utils/request";

// 需求列表
export function list(data) {
  return request({
    url: "/requirement/list",
    method: "post",
    data
  });
}

// 需求删除
export function deleteTask(data) {
  return request({
    url: `/requirement/delete/${data.id}`,
    method: "post",
    data
  });
}

// 需求详情
export function detailTask(params) {
  return request({
    url: '/requirement/detail/' + params.id,
    method:'post',
    data:params
  })
}

// 需求新增
export function addTask(data) {
  return request({
    url: "/requirement/add",
    method: "post",
    data
  });
}

// 需求编辑
export function updateTask(data) {
  return request({
    url: "/requirement/update",
    method: "post",
    data
  });
}
