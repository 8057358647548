<template>
  <div class="antd-panel">
    <div class="top">
      <h2>查找你需要的专家</h2>
      <h3>查看专家的专业经验，找到匹配的专家进行咨询</h3>
    </div>
    <div class="antd-page-content">
      <a-input-search
        v-model="key"
        placeholder="请输入关键字"
        size="large"
        @search="getList"
        class="input-search"
        style="width: 50%"
      >
        <a-button slot="enterButton" type="primary">
          检索
        </a-button>
      </a-input-search>
      <select-field
        @childProvinceId="getChildProvinceId"
        @childIndustryId="getChildIndustryId"
        @childOrderId="getChildOrderId"
      ></select-field>
      <div class="list">
        <div
          v-for="item in listData"
          :key="item.id"
          class="list-item"
          @click="goDetail(item.id)"
        >
          <div style="margin-top:30px">
            <div  style="float:left;width:70px;height:70px;margin-left:20px;background:rgb(234 233 232);border-radius: 50%;">
              <h2 style="line-height:70px;margin-left:20px">{{item.name.charAt(0)}}</h2>
            </div>
            <div class="list-info-top">
              <p style="font-size:16px;color:#333">
                {{ item.name }}
                <span
                  class="f-toe"
                  style="font-size:14px;color:#999;display:inline-block;width:150px;line-height: 16px;"
                  >{{ item.address }}</span
                >
              </p>
              <p class="introduce">
                {{ item.title }}<span>{{ item.college }}</span>
              </p>
            </div>
          </div>
          <div class="list-info-bottom">
            <p>
              评分<span>{{ item.score ? item.score : 10 }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="page_area">
        <span class="disabled-horizon" v-if="pageNo<=1"><a-icon type="left" /></span>
        <span class="horizon" @click="getList(pageNo--)" v-else><a-icon type="left" /></span>
        <span class="horizon-page">第{{pageNo}}页</span>
        <span class="disabled-horizon" v-if="total==0"> <a-icon type="right" /></span>
        <span class="horizon" @click="getList(pageNo++)" v-else> <a-icon type="right" /></span>
      </div>
    </div>
  </div>
</template>

<script>
import selectField from ".././components/selectField";
import { list } from "@/httpsAPI/resources/professor";
export default {
  name: "professor",
  components: {
    selectField,
  },
  data() {
    return {
      listData: [],
      key: "",
      province: "",
      industry: "",
      sortField: "",
      sortOrder: "",
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      pageNo: 1,
      pageSize: 10,
      total: 0
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getChildProvinceId(id) {
      this.province = id;
      this.pageNo = 1;
      this.pageSize = 10;
      this.getList();
    },
    getChildIndustryId(id) {
      this.industry = id;
      this.pageNo = 1;
      this.pageSize = 10;
      this.getList();
    },
    getChildOrderId(id) {
      this.sortField = id;
      this.pageNo = 1;
      this.pageSize = 10;
      this.getList();
    },
    getList(page){
      const {
        province,
        industry,
        key,
        sortField,
        pageNo,
        pageSize,
        sortOrder,

      } = this;
      list({
        pageNo,
        pageSize,
        key,
        province,
        industry,
        sortField,
        sortOrder,
      }).then((res) => {
        if (res.code === "10000") {
          this.listData = res.data.data;
          this.total = res.data.totalCount;
        }
      });
    },
    search() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.getList();
    },
    onPageNoChange(pageNo, pageSize) {
      this.pageNo = pageNo;
      this.getList();
    },
    onShowSizeChange(pageNo, pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    goDetail(id) {
      this.$router.push({ path: `/resources/professor/detail/${id}` });
    },
  },
};
</script>

<style lang="less" scoped>
.antd-panel {
  .page_area{	
    width: 200px;
    margin: 20px auto 0px;
    .horizon-page{
      display: inline-block;
      margin: 0px 20px;
    }
    .disabled-horizon{
      color: #999;
    }
  }
  .top {
    text-align: center;
    h2 {
      text-align: center;
      font-size: 30px;
      font-weight: 900;
      padding: 20px 0;
    }
  }
  .antd-page-content {
    position: relative;
    .input-search {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .list {
      display: flex;
      align-items: center;
      justify-content: stretch;
      flex-wrap: wrap;
      margin-top: 30px;
      .list-item {
        flex: 0 1 auto;
        cursor: pointer;
        margin-left: 20px;
        margin-top: 20px;
        width: 350px;
        height: 160px;
        font-size: 18px;
        font-weight: 600;
        color: #1890ff;
        background: #fff;
        box-shadow: 0 0 10px -5px #333;
        border-radius: 8px;
        .list-info-top {
          width: 230px;
          margin: 0px 15px;
          display: inline-block;
          font-size: 14px;
          color: #999;
          vertical-align: middle;
          p {
            span {
              margin-left: 20px;
            }
          }
          .introduce{
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
          }
        }
        .list-info-bottom {
          margin: 10px 20px;
          font-size: 14px;
          color: #999;
          p {
            display: inline-block;
            margin-left: 20px;
            span {
              color: indianred;
            }
          }
        }
        img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          display: inline-block;
          margin-left: 20px;
        }
        .list-item-detail {
          display: inline-block;
          height: 60px;
          text-align: center;
          width: 175px;
          background: rgb(202, 201, 201);
        }
        .list-item-detail:nth-child(2) {
          border-right: 1px solid rgb(228, 227, 227);
        }
      }
    }
    .pagination {
      margin-top: 80px;
      text-align: center;
    }
  }
  .f-toe {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
