<template>
  <div>
    <a-card :bordered="false" title="用户等级汇总">
      <ve-histogram
        :title="titleUserLevel"
        :data="chartDataUserLevel"
        :settings="chartSettings"
        :colors="colors"
        :extend="extendUserLevel"
        judge-width
      ></ve-histogram>
    </a-card>
  </div>
</template>

<script>
import Lib from "echarts/lib/echarts";
import { userLevel } from "@/httpsAPI/effect";


export default {
  name: "ability",
  data() {
    return {
      colors: ["#1be9be", "#63c2ff", "#ffcb8c", "#ff7993",  "#0070b6", "#a024d3"],
      titleUserLevel: {
        text: "人数(个)",
      },
      tooltip: {
        show: false,
        trigger: "none",
      },
      chartSettings: {
        labelMap: {'grade': '会员等级', 'count': '人数'},
      },
      chartDataUserLevel: {
        columns: ["等级"],
        rows: [{"等级":""}],
      },
      extendUserLevel: {
        series: {
          type: "bar",
          label: { show: false, position: "top" },
        },
      },
    };
  },
  components: {
    Lib,
  },
  methods: {
    getUserLevelChartData() {
      const data = {};
      userLevel(data).then((res) => {
        console.log(res)

        let kk = this.chartDataUserLevel.rows[0]
        res.data.forEach(p => {
          this.chartDataUserLevel.columns.push(p.grade)
          
          kk[p.grade]= p.count
        })
      })
    }
  },
  created() {
    this.getUserLevelChartData()
  }
}
</script>

<style scoped></style>
