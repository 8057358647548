<template>
  <div class="antd-panel"  style="position:relative">
      <a-button style="position: absolute;right:30px;" type="primary" @click="$router.back()">返回</a-button>
    <div class="detail-container" style="margin-top:20px"> 
      <div class="top">
        <h4 class="title">
          {{ detail.achiName ? detail.achiName : "暂无数据" }}
        </h4>
        <p class="a">{{ detail.comMan ? detail.comMan : "暂无专家说明" }}</p>
        <p class="b">
          {{ detail.formTime }} {{ detail.subUnit }} {{ detail.city }}
        </p>
      </div>
      <div class="bottom">
        <h2 class="title">成果介绍</h2>
        <div class="content">
          {{ detail.proIntro ? detail.proIntro : "暂无成果介绍" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { view } from "@/httpsAPI/resources/finished";
export default {
  name: "finishedDetail",
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      view({ id: this.id }).then((res) => {
        if (res.code === "10000") {
          console.log(res.data)
          this.detail = res.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.detail-container {
  background: #fff;
  padding: 30px 10px;
  .top {
    padding: 20px 30px;
    box-shadow: 0 0 4px #888888;
  }
  .bottom {
    margin-top: 50px;
    .title {
      width: 180px;
      padding: 6px 10px;
      color: #fff;
      border-radius: 5px;
      background: #1890ff;
    }
    .content {
      margin-top: 15px;
      padding: 20px;
      box-shadow: 0 0 4px #888888;
    }
  }
}
</style>
