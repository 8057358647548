import API from "@/httpsAPI/API";
import request from "@/utils/request";
import {exportFile} from "@/httpsAPI/common"

export function getProjectList(params) {
  return request({
    url:API.projectList,
    method:'post',
    data:params
  })
}

export function getProjectTemplateSelect(params){
  return request({
    url:API.projectTemplateSelect,
    method:'post',
    data:params
  })
}

export function getProjectSelect(params){
  return request({
    url:API.projectSelect,
    method:'post',
    data:params
  })
}
export function projectAdd(params){
  return request({
    url:API.projectAdd,
    method:'post',
    data:params
  })
}
// 导出
export function projectExport(params) {
  return exportFile('/project/export', '项目列表导出', params, 'post')
}

// 项目选择切换列表
export function changeProjectList(params){
  return request({
    url:API.changeProjectList,
    method:'post',
    data:params
  })
}