
<template functional>
  <a-sub-menu :key="props.subMenuArr.key" :title="props.subMenuArr.title">
    <template v-for="(subMenuItem, subMenuKey) in props.subMenuArr.children">
      <template v-if="subMenuItem.children.length > 0">
        <!--  vue组件自己调用自己  -->
        <sub-Menu :key="subMenuKey" :subMenuArr="subMenuItem"></sub-Menu>
      </template>

      <template v-else>
        <a-menu-item :key="subMenuItem.key">{{
          subMenuItem.title
        }}</a-menu-item>
      </template>
    </template>
  </a-sub-menu>
</template>
<script>
export default {
  name: "SubMenu",
  components: {},
  props: {
    subMenuArr: {},
  },
};
</script>
