<template>
  <div class="configure-right">
    <h2 style="">邮件设置</h2>
    <a-alert message="邮件设置用于修改系统发送邮件通知时，显示的邮件主题、发件人昵称等信息" type="info" show-icon style="margin:20px 0;" />

    <a-card size="small" title="设置邮件模板" style="width: 600px">
      <a-form :form="form" :label-col="{ span: 2 }" :wrapper-col="{ span: 12 }" style="margin-left:6px">
          <a-col :span="24" style="">
            <a-form-item label="主题">
              <a-input placeholder="请输入主题"
                v-decorator="['title', { rules: [{message: '请输入主题' }] }]" />
            </a-form-item>
            <a-form-item label="发件人">
              <a-input placeholder="请输入发件人"
                v-decorator="['sender', { rules: [{message: '请输入发件人' }] }]" />
            </a-form-item>
          </a-col>
      </a-form>
    </a-card>
    <!-- <div style="background:#ECECEC; padding:30px;margin-top:20px;"> -->
      <a-card size="small" title="邮件预览" style="width: 600px;margin-top:20px">
        <p>主题：{{title}}</p>
        <p>发件人：{{sender}}</p>
        <img src="@/assets/email.png" alt="" style="width:300px;height:300px">
      </a-card>
    <!-- </div> -->
    <a-form-item>
        <a-button type="primary" @click="dataFormSubmit">保存</a-button>
    </a-form-item>
  </div>
</template>

<script>
import { emailSet,emailDetail } from '@/httpsAPI/configure/index'

export default {
  name: "emailSet",
  data(){
    return{
      title:'',
      sender:'',
      form: this.$form.createForm(this),
    }
  },
  created(){
    this.emailDetail()
  },
  methods:{
    //设置邮件
    dataFormSubmit (e) {
      this.confirmLoading = true;
      this.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        emailSet(values).then(res => {
          if (res.code == 10000) {
            this.$message.success({
              content: '操作成功',
              duration: 1,
              onClose: () => {
                this.confirmLoading = false;
                this.emailDetail()
              }
            })
          } else {
            this.$message.error({
              content: res.msg,
              duration: 1
            })
          }
        })
      })
    },
    //邮件详情
    emailDetail(){
      emailDetail().then(res=>{
        this.title = res.data.title
        this.sender = res.data.sender
      })
    }
  }
}
</script>
<style lang="less" scoped>
.configure-right{
  padding: 20px;
}
</style>
