import API from "@/httpsAPI/API";
import request from "@/utils/request";

// 评审点查询接口
export function getProjectReviewList(params) {
  return request({
    url: '/project/review/list',
    method:'post',
    data:params
  })
}

// 新建评审点
export function addReview(params) {
  return request({
    url: '/project/review/add',
    method:'post',
    data:params
  })
}

// 修改评审点
export function updateReview(params) {
  return request({
    url: '/project/review/update',
    method:'post',
    data:params
  })
}

// 更新评审点状态
export function updateReviewStatus(params) {
  return request({
    url: '/project/review/update-status',
    method:'post',
    data:params
  })
}

// 删除评审点
export function deleteReview(params) {
  return request({
    url: '/project/review/delete/' + params,
    method:'post',
    data:{}
  })
}

// 评审点详情
export function detailReview(params) {
  return request({
    url: '/project/review/detail/' + params.id,
    method:'post',
    data:params
  })
}

// 评审点交付物列表
export function getDeliverableList(params) {
  return request({
    url: '/project/review/deliverable-list/' + params.id,
    method:'post',
    data:params
  })
}

// 评审点交付物上传
export function deliverableUpload(params) {
  return request({
    url: '/project/review/deliverable-upload',
    method:'post',
    data:params
  })
}

// 新建目标交付物
export function deliverableAdd(params) {
  return request({
    url: '/project/review/deliverable-add',
    method:'post',
    data:params
  })
}

// 删除目标交付物
export function deliverableDelete(params) {
  return request({
    url: '/project/review/deliverable-delete/' + params.id,
    method:'post',
    data:params
  })
}

