<template>
  <div class="antd-panel">
    <div class="top">
      <h2>查找你需要的成果</h2>
    </div>
    <div class="antd-page-content">
      <a-input-search
        v-model="key"
        placeholder="请输入关键字"
        size="large"
        class="input-search"
        style="width: 50%"
      >
        <a-button slot="enterButton" type="primary" @click.native="search()">
          检索
        </a-button>
      </a-input-search>
      <select-field
        @childProvinceId="getChildProvinceId"
        @childIndustryId="getChildIndustryId"
        @childOrderId="getChildOrderId"
      ></select-field>
      <div class="list">
        <div
          class="item"
          v-for="item in listData"
          :key="item.id"
          @click="goDetail(item.id)"
        >
          <div class="top-title">
            <h3 class="title">{{ item.achiName }}</h3>
            <p class="des">
              {{ item.subUnit }}
            </p>
          </div>
          <div class="content f-lh2">
            {{ item.proIntro }}
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="page_area">
        <span class="disabled-horizon" v-if="pageNo<=1"><a-icon type="left" /></span>
        <span class="horizon" @click="getList(pageNo--)" v-else><a-icon type="left" /></span>
        <span class="horizon-page">第{{pageNo}}页</span>
        <span class="disabled-horizon" v-if="total==0"> <a-icon type="right" /></span>
        <span class="horizon" @click="getList(pageNo++)" v-else> <a-icon type="right" /></span>
      </div>
    </div>
  </div>
</template>

<script>
import selectField from ".././components/selectField";
import { list } from "@/httpsAPI/resources/finished";
export default {
  name: "finished",
  components: {
    selectField,
  },
  data() {
    return {
      listData: [],
      key: "",
      province: "",
      industry: "",
      sortField: "",
      sortOrder: "",
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      pageNo: 1,
      pageSize: 10,
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getChildProvinceId(id) {
      this.province = id;
      this.pageNo = 1;
      this.pageSize = 10;
      this.getList();
    },
    getChildIndustryId(id) {
      this.industry = id;
      this.pageNo = 1;
      this.pageSize = 10;
      this.getList();
    },
    getChildOrderId(id) {
      if(id=='最近新增') {
        this.sortField = '添加时间';
      } else {
        this.sortField = id;
      }
      this.pageNo = 1;
      this.pageSize = 10;
      this.getList();
    },
    getList(page){
      const {
        province,
        industry,
        key,
        sortField,
        pageNo,
        pageSize,
        sortOrder,

      } = this;
      list({
        pageNo,
        pageSize,
        key,
        province,
        industry,
        sortField,
        sortOrder,
      }).then((res) => {
        if (res.code === "10000") {
          this.listData = res.data.data;
          this.total = res.data.totalCount;
        }
      });
    },
    search() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.getList();
    },
    onPageNoChange(pageNo, pageSize) {
      this.pageNo = pageNo;
      this.getList();
    },
    onShowSizeChange(pageNo, pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    goDetail(id) {
      this.$router.push({ path: `/resources/finished/detail/${id}` });
    },
  },
};
</script>

<style lang="less" scoped> 



.antd-panel {
  .page_area{	
    width: 200px;
    margin: 20px auto 0px;
    .horizon-page{
      display: inline-block;
      margin: 0px 20px;
    }
    .disabled-horizon{
      color: #999;
    }
  }
  .top {
    h2 {
      text-align: center;
      font-size: 30px;
      font-weight: 900;
      padding: 20px 0;
    }
  }
  .antd-page-content {
    position: relative;
    .input-search {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .list {
      padding-top: 20px;
      .item {
        cursor: pointer;
        padding: 20px;
        margin-top: 20px;
        box-shadow: 0 0 8px #888888;
        .top-title {
          display: flex;
          align-items: baseline;
          justify-content: flex-start;
          & > .des {
            margin-left: 20px;
          }
        }
        .f-lh2 {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
    .pagination {
      margin-top: 50px;
      text-align: center;
    }
  }
}
</style>
