<template>
  <a-modal
      :title="model.unlock?'解锁密码':'设置锁定密码'"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="() => { $emit('ok',{unlock:model.unlock}) }"
      @cancel="()=>{$emit('cancel')}"
  >
    <a-form :form="form"  :label-col="{span:4}" :wrapper-col="{span:18}">
      <a-form-item label="密码：" prop="password">
        <a-input-password v-decorator="['passWord', { rules: [{ required: true, message: '请输入锁定密码' } ] }]" placeholder="请输入锁定密码" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
const fields = ['password']
export default {
  name: "settingLock",
  props:{
    visible:{
      type:Boolean,
      default:false
    },
    confirmLoading:{
      type:Boolean,
      default:false
    },
     model: {
      type: Object,
      default: () => null
    }
  },
  data(){
    return {
      form: this.$form.createForm(this),
    }
  },
  created () {
    console.log('custom modal created')
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      console.log(this.model)
      })
  },
  methods:{
  }
}
</script>

<style scoped>

</style>