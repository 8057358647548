<template>
  <a-modal
      title="处理求助单"
      :width="720"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
      <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-item label="处理意见">
          <a-textarea placeholder="请输入处理意见" :rows="4" v-decorator="['remark',  {rules: [ {required: true, max:150, message: '请输入处理意见,字数不能超过150个字' }] }]" />
        </a-form-item>

        <a-form-item label="附件">
          <a-upload
            name="file"
            :action="uploadAction"
            :data="{refType: refType, refId: refId}"
            :beforeUpload="beforeUpload"
            :headers="headers"
            accept=".doc,.docx,.ppt,.xls,.xlsx,.XLS,.XLSX,.DOC,.DOCX,.PPT,.PPTX,.png,.jpg,.pdf"
            @change="handleChange"
            :multiple="false"
          >
            <a-button class="ant-btn ant-btn-primary"> <a-icon type="upload" />上传附件</a-button>
          </a-upload>
        </a-form-item>
      </a-form>
      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">取消</a-button>
        <a-button class="ant-btn ant-btn-primary" @click="dataFormSubmit()" disabled v-if="fileStatus==1">确定</a-button>
        <a-button class="ant-btn ant-btn-primary" @click="dataFormSubmit()" v-else>确定</a-button>
      </span>
    </a-modal>
</template>
<script>
import {TOKEN} from "@/store/token.config"
import storage from 'store'
import { resolveWorder } from '@/httpsAPI/worder'
let apiBaseUrl = window._CONFIG['domianURL'] || process.env.VUE_APP_BASE_API;
    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                visible: false,
                confirmLoading: false,
                fileList: [],
                headers:{},
                uploadAction: '',
                refType: 7,
                refId:0,
                filePath: '',
                fileName: '',
                fileId: 0,
                fileStatus:0
            }
        },
        created(){
          const token = storage.get(TOKEN);
          this.headers = {"token":token};
        },
        methods:{
          beforeUpload(file) {
              this.uploadAction = apiBaseUrl + "/project/upload/file-upload"
          },
          handleChange(info) {
            if(info.file.status==='uploading'){
              this.fileStatus=1
            }
            console.log(info,"info")
            if(info.file.status==='done'){
              this.fileStatus=0
              this.fileName = info.file.response.data.oldName
              this.filePath = info.file.response.data.name
              this.fileId = info.file.response.data.fileId
            }else if (info.file.status === 'error') {
              this.$message.error(`${info.file.name} 上传失败.`);
            }
          },
          dataFormSubmit(e) {
            this.form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                this.confirmLoading = true;

                values['id'] = this.refId
                values['filePath'] = this.filePath
                values['fileId'] = this.fileId

                console.log('Received values of form: ', values);
                resolveWorder(values).then(res=>{
                  if(res.code==10000) {
                    this.$message.success({
                        content: '操作成功',
                        duration: 1,
                        onClose: () => {
                            this.form.resetFields();
                            this.visible = false;
                            this.$emit('refreshDataList')
                        }
                    })
                  }else {
                    this.$message.error({
                        content: res.msg,
                        duration: 1
                    })
                  }
                })
            });
          },
          handleCancel(e) {
            this.visible = false;
          },
          init (refId) {
            this.visible = true
            this.$nextTick(() => { 
                this.form.resetFields();
                this.refId = refId
            });
          }
        }
    }
</script>