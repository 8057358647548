<template>
  <a-modal
      title="关联任务"
      ref="relateTaskForm"
      width="800px"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :maskClosable="false"
      destroyOnClose
  >
    <div class="member-list-wrap">
      <div class="list-label">请选择任务进行关联</div>
      <div class="list-input">
        <a-input-search style="margin-right: 16px; width: 272px;" placeholder="请输入任务名称进行搜索" v-model="queryParams.title" @keyup="$refs.table.refresh(true)"/>
      </div>
    </div>
    <s-table
        rowKey="id"
        :data="loadData"
        size="default"
        ref="table"
        :columns="columns"
        :alert="alert"
        :rowSelection="rowSelection"
        :pageNum="pageNum"
    >
        <span slot="status" slot-scope="text, record">
          <a-tag color="red" v-if="record.status == 0">待开始</a-tag>
          <a-tag color="orange" v-if="record.status == 1">处理中</a-tag>
          <a-tag color="green" v-if="record.status == 2">已完成</a-tag>
        </span>
        <span slot="level" slot-scope="text, record">
          <a-tag color="red" v-if="record.level == 1">最高</a-tag>
          <a-tag color="orange" v-if="record.level == 2">较高</a-tag>
          <a-tag color="blue" v-if="record.level == 3">普通</a-tag>
          <a-tag color="blue" v-if="record.level == 4">较低</a-tag>
          <a-tag color="blue" v-if="record.level == 5">最低</a-tag>
        </span>
    </s-table>
  </a-modal>
</template>

<script>
import {getUnrelateTask, batchRelateTask} from "@/httpsAPI/project/task";

const columns = [
  {
    title:'优先级',
    dataIndex:'level',
    scopedSlots: {customRender: 'level'}
  },
  {title:'任务名',dataIndex:"title"},

  {
    title:'负责人',
    dataIndex: 'masterName'
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: {customRender: 'status'}
  }
]
export default {
  name: "relateTask",
  data(){
    return{
      columns,
      visible: false,
      confirmLoading: false,
      queryParams: {},
      pageNum: 1,
      projectId: 0,
      planId: 0,
      loadData:parameter=>{
        this.pageNum = parameter.pageNo
        this.queryParams.projectId = this.projectId
        this.queryParams.taskType = 2

        return getUnrelateTask(Object.assign(parameter, this.queryParams)).then(res=>{
          return res.data
        })
      },
      selectedRowKeys:[],
      selectedRows:[],
      alert:{
        show: false, clear: () => { this.selectedRowKeys = [] }
      },
      rowSelection:{
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  methods:{
    handleOk(){
      if(this.selectedRows.length==0) {
          this.$message.warning('请选择要进行关联的任务', 1)
          return
      }
      this.confirmLoading = true;
      const values = {planId:this.planId, ids:this.selectedRowKeys}
      
      batchRelateTask(values).then(res=>{
          if(res.code==10000) {
              this.$message.success({
                  content: '操作成功',
                  duration: 1,
                  onClose: () => {
                      this.visible = false;
                      this.$emit('refreshDataList')
                  }
              })
          }else {
              this.$message.error({
                  content: res.msg,
                  duration: 1
              })
          }
          this.confirmLoading = false;
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleCancel(e) {
      this.visible = false;
    },
    init(projectId, planId) {
      this.confirmLoading = false
      this.visible = true
      this.projectId = projectId
      this.planId = planId
    }
  }
}
</script>

<style lang="less">
.member-list-wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .list-label{
    line-height: 42px;
  }
}
</style>