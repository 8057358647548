<template>
    <a-card :bordered="false">
      <a-row :gutter="50">
        <a-form
          :form="form"
          layout="vertical"
          @submit="handleSubmit"
        >
            <a-col :md="24" :lg="12">
            <a-form-item label="姓名">
              <a-input
              v-decorator="[
                'name',
                {rules: [{message: '请输入姓名！'}]},
              ]"
              placeholder="请输入姓名" :disabled="true" />
            </a-form-item>

            <a-form-item label="所属部门">
              <a-input
              v-decorator="[
                'deptName',
                {rules: [{message: '请输入所属部门！'}]},
              ]"
              placeholder="请输入所属部门" :disabled="true" />
            </a-form-item>

            <a-form-item label="电话">
              <a-input rows="4"
              v-decorator="[
                'phone',
                {rules: [{required: true, message: '请输入电话！'}]},
              ]"
              placeholder="请输入电话" :disabled="true" />
            </a-form-item>

            <a-form-item label="邮箱">
              <a-input
              v-decorator="[
                'email',
                {rules: [{required: true, message: '请输入邮箱！'}]},
              ]"
              placeholder="请输入邮箱" />
            </a-form-item>

              <h3 style="font-size:15px">会员积分</h3>
                <a-input
                v-model="credit"
                placeholder="请输入会员等级" :disabled="true" />

            <a-form-item label="会员等级" style="margin-top:25px">
              <a-input
              v-decorator="[
                'creditLevel',
                {rules: [{message: '请输入会员等级！'}]},
              ]"
              placeholder="请输入会员等级" :disabled="true" />
            </a-form-item>

            <a-form-item>
              <a-button type="primary" html-type="submit">保存</a-button>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item label="更换头像">
              <a-upload v-decorator="['image']" list-type="picture-card" class="avatar-uploader"
                :show-upload-list="false" :before-upload="beforeUpload" :customRequest="customRequest">
                <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width:100px;height:100px;border-radius: 50%;"/>
                <div v-else>
                  <a-icon :type="loadingFile ? 'loading' : 'plus'" />
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <p>等级图标</p>
            <img :src="creditUrl" alt="" >
          </a-col>
        </a-form>
      </a-row>
    </a-card>
</template>

<script>
import {uploadImg} from '@/httpsAPI/common'
import { getuserInfo, updateUser} from '@/httpsAPI/personal/index'

export default {
  data () {
    return {
      id:'',
      imageUrl:'',
      loadingFile:false,
      credit:'',
      creditUrl:'',
      creditLevel:''
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'zs' });
  },
  methods: {
    //头像上传
    beforeUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('仅支持图片上传');
      }
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        this.$message.error('图片不能大于4MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    customRequest(option){
      const formData = new FormData() 
      formData.append('file', option.file)
      formData.append('channel', 2)
      this.saveFile(formData)
    },
    saveFile (formData) {
      console.log('saveFile')
      console.log(formData,"formData")
      this.loadingFile = true
      uploadImg(formData).then(res=>{
        console.log(res,"res")
          if (res.code == 10000) { 
            this.imageUrl = this.$imgBaseUrl + res.data.name
            this.loadingFile = false           
            this.form.setFieldsValue({
              image: res.data.name
            })
          } else {  
            this.$message.error(res.msg)  
          }
      })
    },
    // 获取信息
    getInfo(){
      getuserInfo().then(res => {
        console.log(res.data)
          this.id = res.data.id
          this.imageUrl = this.$imgBaseUrl + res.data.image
          this.creditUrl = this.$imgBaseUrl + res.data.creditUrl
          this.credit = res.data.credit,
          this.form.setFieldsValue({
            name: res.data.name,
            departmentId: res.data.departmentId,
            phone: res.data.phone,
            email: res.data.email,
            deptName: res.data.deptName,
            creditLevel: res.data.creditLevel
          }) 
      })
    },
    //修改信息
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
          values['id'] = this.id
          console.log(values,"values")
          updateUser(values).then(res => {
              this.$message.success('修改成功')
              this.getInfo()
          })
        }
      });
    },
  },
  created(){
      this.getInfo()
  }
}
</script>
<style lang="less" scoped>
.certificate-box{
  position: relative;
  width: 360px;
  margin: auto;
  .item{
    position: absolute;
    color: #799999;
    .red{
      color: #FF7070;
    }
  }
  img{
    width: 100%;
  }
}
</style>