<template>
<a-modal
      title="上传文件"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
 <div class="antd-page">
  <div class="antd-panel">
  <a-form :form="form" class="form">
        <a-row type="flex" justify="center" class="form-row" :gutter="16">
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item>
                <div class="dropbox">
                  <a-upload-dragger
                    :remove="handleRemove" 
                    :before-upload="beforeUpload"
                    name="file"
                    :file-list="fileList"
                    :accept="uploadFomat+','+uploadFomat.toUpperCase()"
                    :multiple="false"
                  >
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">
                      点击或将文件拖拽到这里上传
                    </p>
                    <p class="ant-upload-hint">
                      支持扩展名：{{uploadFomat.replace(/,/g," ")}}
                    </p>
                  </a-upload-dragger>
                </div>
              </a-form-item>
            </a-col>
        </a-row>
      </a-form>
      <span v-if="filePath!=''">图片或视频地址：{{filePath}}</span>
  </div>
 </div>
  <span slot="footer" class="dialog-footer">
    <a-button @click="handleCancel">取消</a-button>
    <a-button class="ant-btn ant-btn-primary" style="margin-left: 8px" :loading="uploading" @click="startUpload()">{{ uploading ? '上传中' : '开始上传' }}</a-button>
  </span>
</a-modal>
</template>
<script>
import {uploadFile, uploadImg} from '@/httpsAPI/common' 
    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                visible: false,
                confirmLoading: false,
                uploadFomat: '.mp4,.avi,.wmi,.jpg,.png,.pdf',
                fileList: [],
                filePath: '',
                uploading: false
            }
        },
        methods:{
          handleRemove(file) {
            const index = this.fileList.indexOf(file);
            const newFileList = this.fileList.slice();
            newFileList.splice(index, 1);
            this.fileList = newFileList;
          },
          beforeUpload(file) {
            this.fileList = [file];
            return false;
          },
          // 上传课程文件
          startUpload(){ 
              this.filePath = ''
              const formData = new FormData();
              formData.append('channel', 1)
              formData.append('file', this.fileList[0]);
              this.uploading = false;
              const fileExt = this.fileList[0].name.substring(this.fileList[0].name.lastIndexOf('.') + 1)
              
              if(".mp4,.avi,.wmi".indexOf(fileExt.toLowerCase()) > -1) {
                uploadFile(formData).then(res=>{
                  this.uploading = false;
                  if(res.code==10000) {
                    this.filePath = res.data.path
                    this.$message.info('上传成功，请复制以下文件地址到编辑器内：')
                  }else {
                    this.$message.error(res.data.msg)
                  }
                })
              } 
              else if(".jpg,.png,.pdf".indexOf(fileExt.toLowerCase()) > -1) {
                // formData.append('refType', 2)
                uploadImg(formData).then(res=>{
                  this.uploading = false;
                  if(res.code==10000) {
                    this.filePath = res.data.path
                    this.$message.info('上传成功，请复制以下文件地址到编辑器内：')
                  }else {
                    this.$message.error(res.data.msg)
                  }
                })
              }
              else {
                this.$message.warning("文件格式无法识别，暂不能上传")  
              }
          },
          handleCancel(e) {
            this.visible = false;
          },
          init () {
            this.visible = true
            this.fileList = []
            this.filePath = ''

            this.$nextTick(() => {
                this.form.resetFields();
            });
          }
        }
    }
</script>