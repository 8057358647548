<template>
<a-modal
      title="上传试题"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :width="550"
      :body-style="{ paddingBottom: '80px' }"
      @cancel="cancel"
      destroyOnClose
    >
    <a-form
      :form="form"
      :label-col="{ span: 4 }" 
      :wrapper-col="{ span: 20 }"
    >

      <a-form-item label="上传文件" extra="允许上传图片的格式为xls、xlsx，大小限制100M以内">
        <a-upload
          v-decorator="['picture']"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          accept=".xls,.xlsx,.XLS,.XLSX"
          :customRequest="customUploadRequest"
        >
          <div>
            <a-icon :type="'plus'" />
            <div class="ant-upload-text">
              选择文件
            </div>
          </div>
        </a-upload>
      </a-form-item>

    </a-form>

    <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">关闭</a-button>
      </span>
</a-modal>
</template>

<script>
import {uploadQuestion} from '@/httpsAPI/course/index'
export default {
  data () {
      return {
        visible: false,
        confirmLoading: false,
        form : this.$form.createForm(this),
        courseNo: ''
    }
  },
  created() {
  },
  methods: {
    // 试题上传
    customUploadRequest(option){ 
      console.log(option.data)
        const formData = new FormData() 
        formData.append('file', option.file)
        formData.append('courseId', this.courseId)
        formData.append('courseNo', this.courseNo)
        let progress = {percent : 1 }        

        uploadQuestion(formData).then(res=>{
          if (res.code == 10000){
            option.onSuccess(res, option.file)   
            // progress.percent = 99

            this.confirmLoading = false;
            this.$emit('refreshDataList')
            this.visible = false
          } else {  
              this.$message.error(res.msg)  
          }
        })
        /*
        let speed = 100/(data.file.size/65000)
        const intervalId = setInterval(() => {
          if (progress.percent < 99 && progress.percent+speed < 100 ) {
            progress.percent+=speed 
            data.onProgress(progress)
          } else if(progress.percent == 99) {
            progress.percent++
          } else if(progress.percent == 100){
            clearInterval(intervalId)
          }
        }, 100)*/
    },
    cancel(e) {
      this.visible = false;
    },
    init (courseId, courseNo) {
      this.courseId = courseId
      this.courseNo = courseNo
      this.visible = true
    }
  }
};
</script>