<template>
  <div>
    <div class="antd-table-search-wrap">
      <a-descriptions title="个人信息">
        <a-descriptions-item label="姓名">{{dataForm.name}}</a-descriptions-item>
        <a-descriptions-item label="学习总分数">{{dataForm.totalLearningScore}}</a-descriptions-item>
      </a-descriptions>
    </div>
    <div class="antd-page-content">
      <a-row :gutter="12" style="padding:20px 15px">
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" @submit="searchHandleSubmit">
          <a-col :span="6">
            <a-form-item label="课程名称">
              <a-input v-model="queryParams.courseName" placeholder="请输入课程名称" />
            </a-form-item>
          </a-col>
           <a-col :span="6">
           <a-form-item label="课程类型">
              <a-select :filter-option="false" show-search :allowClear="true" placeholder="请输入课程类型"
                v-model="queryParams.courseTypeId" @popupScroll="handlePopupScroll">
                <a-select-option v-for="item in channelList" v-bind:key="item.id" :value="item.id">
                  {{item.courseTypeName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item>
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQueryForm">重置</a-button>
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
      <s-table size="default" ref="table" rowKey="id" :data="loadData" :columns="columns" :pageNum="pageNum">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
         <a-button
          type="link"
          slot="courseName"
          slot-scope="text, record"
          @click="detailFormEvt(record)"
          >{{ record.courseName }}</a-button
          >
        <span slot="studyStatus" slot-scope="text, record">
          <a-tag color="red" v-if="record.studyStatus == 1">待学习</a-tag>
          <a-tag color="orange" v-if="record.studyStatus == 2">学习中</a-tag>
          <a-tag color="orange" v-if="record.studyStatus == 3">待考试</a-tag>
          <a-tag color="green" v-if="record.studyStatus == 4">已完成</a-tag>
        </span>
      </s-table>
    </div>
  </div>
</template>

<script>
import { getStudyList,getPersonalInfo,getCourseType } from '@/httpsAPI/course/index'

const columns = [
  {
    title: '序号',
    scopedSlots: { customRender: 'serial' }
  },
  {
    title: '课程名称',
    dataIndex: 'courseName',
    scopedSlots: { customRender: "courseName" }
  },
  {
    title: "课程类型",
    dataIndex: 'courseTypeName'
  },
  {
    title: '学习状态',
    dataIndex: 'studyStatus',
    scopedSlots: { customRender: "studyStatus" },
  },
  {
    title: '学习时长',
    dataIndex: 'playTime2'
  },
  {
    title: '考试成绩',
    dataIndex: 'score'
  }
]
export default {
  name: "learningRecords",
  data () {
    return {
      columns,
      dataForm:{
        name:'',
        totalLearningTime:'',
        department:'',
        totalLearningScore:''
      },
      queryParams:{},
      channelList:[],
      courseTypeId:'',
      pageNum: 1,
      form: this.$form.createForm(this, { name: 'searchProject' }),
      loadData: parameter => {
        this.pageNum = parameter.pageNo
        return getStudyList(Object.assign(parameter,this.queryParams)).then(res => {
          return res.data
        })
      }
    }
  },
  created(){
    this.personalInfo()
    this.changeChannelList ()
  },
  methods:{
    // 重置搜索form
    resetQueryForm () {
      this.queryParams = {}
      this.$refs.table.refresh(true)
    },
    // 详情
    detailFormEvt (record) {
      this.$router.push({ path: '/personalDevelopment/course/detail',query:{courseId:record.courseNo} })
    },
    //课程分类
    changeChannelList () {
      getCourseType()
        .then(res => {
          this.channelList = res.data;
          this.courseTypeId = res.data.id
        })
    },
    // 下拉滚动
    handlePopupScroll (e) {
      const { target } = e
      const scrollHeight = target.scrollHeight - target.scrollTop
      const clientHeight = target.clientHeight
      // 下拉框不下拉的时候
      if (scrollHeight === 0 && clientHeight === 0) {
        this.scrollPage = 1
      } else {
        // 当下拉框滚动条到达底部的时候
        if (scrollHeight < clientHeight + 5) {
          this.pageData(1);
        } else if (scrollHeight == 3208) {
          this.pageData(2);
        }
      }
    },
    handleSearch (keyword) {
      this.keyword = keyword;
      this.changeChannelList();
    },
    //个人信息
    personalInfo(){
      getPersonalInfo().then((res)=>{
        this.dataForm.name = res.data.name
        this.dataForm.totalLearningScore = res.data.totalLearningScore
        this.dataForm.department = res.data.department
        this.dataForm.totalLearningTime = res.data.totalLearningTime
      })
    },
    searchHandleSubmit (e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
        }
      });
    },
  }
}
</script>

<style scoped>
</style>