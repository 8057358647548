<template>
  <div class="antd-panel" style="position:relative">
    <a-button style="position: absolute;right:30px;" type="primary" @click="$router.back()">返回</a-button>
    <div class="detail-container" style="margin-top:20px">
      <div style="margin-bottom:10px">
        <a href="javascript:void(0)" @click="viewFile" style="margin-left:20px;font-size:20px;">预览</a>
        <a-icon type="arrow-down" style="color:#1890ff;font-size:20px;margin-left:10px" @click="down" />
      </div>
      <div class="top">
        <h4 class="title">
          {{ detail.standardName ? detail.standardName : "暂无数据" }}
        </h4>
        <p class="b">
          {{ detail.classifyName }}
        </p>
      </div>
    </div>
  </div>
</template>



<script>
import { standView } from "@/httpsAPI/resources/stand";
import { downloadFile, standDownloadFile, standViewFile } from '@/httpsAPI/common'
export default {
  name: "finishedDetail",
  components: {
  },
  data () {
    return {
      id: this.$route.params.id,
      detail: {},
      standId: '',
      filePath: '',
      fileURL: 'https://ipd-test.obs.cn-east-3.myhuaweicloud.com/',
      standardName: '',
      downId: '',
      viewPath: ''
    };
  },
  created () {
    this.standId = this.$route.query.standId
  },
  mounted () {
    this.getInfo();
  },
  methods: {
    getInfo () {
      standView(this.standId).then((res) => {
        if (res.code === "10000") {
          this.detail = res.data;
          this.downId = res.data.id;
          this.filePath = this.fileURL + res.data.filePath
          this.viewPath = res.data.filePath
          this.standardName = res.data.standardName
        }
      });
    },
    //预览
    viewFile () {
      // var type = window._CONFIG['downloadType'];
      // if (type == "cloud") {
      standViewFile({ fileUrl: this.viewPath }, '/resources/standard/getDownloadUrl');
      // }
    },
    //下载
    down () {
      // var type = window._CONFIG['downloadType'];
      // if (type == "local") {
      //   const params = {
      //     fileName: this.standardName + this.filePath.substring(this.filePath.lastIndexOf('.'))
      //   }
      //   downloadFile(this.filePath, this.standardName, params, 'post')
      // }
      // if (type == "cloud") {
      standDownloadFile(this.filePath)
      // }

    }
  },
};
</script>

<style lang="less" scoped>
.detail-container {
  background: #fff;
  padding: 30px 10px;
  .top {
    padding: 20px 30px;
    box-shadow: 0 0 4px #888888;
  }
  .bottom {
    margin-top: 50px;
    .title {
      width: 180px;
      padding: 6px 10px;
      color: #fff;
      border-radius: 5px;
      background: #1890ff;
      display: inline-block;
    }
    .content {
      margin-top: 15px;
      padding: 20px;
      box-shadow: 0 0 4px #888888;
    }
  }
}
</style>
