<template>
  <div class="login">
    <div class="logo">
      <img src="../../assets/rz.png" alt="" style="width:50px;height:45px;margin-top:8px;">
      <h2>日照市科技局科技专员工作系统</h2>
    </div>
    <div class="login-form">
      <a-spin :spinning="isLoding">
      <h2>欢迎登录</h2>
      <a-tabs default-active-key="1" style="width:400px">
        <a-tab-pane key="1" tab="手机号登录">
          <a-form id="components-form-demo-normal-login" :form="form2" @submit="handleSubmit">           
              <a-form-item>
                <a-input ref="phone" v-decorator="[
                  'username',
                  {rules: [{ required: true, message: '请输入手机号!' }] }]" placeholder="请输入手机号">
                        <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
                </a-input>
              </a-form-item>

              <a-form-item style="position:relative"> 
                <a-input v-decorator="[
                  'password',
                  { rules: [{ required: true, message: '请输入密码!' }] }]" type="password" placeholder="请输入密码">
                        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
                </a-input>
                <a href="javascript:void(0)" style="position:absolute;top:20px;right:0" @click="passEvt">忘记密码</a>
              </a-form-item>
              <a-form-item>
                <a-button type="primary" html-type="submit" class="login-form-button">
                  登录
                </a-button>
              </a-form-item>
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="2" tab="验证码登录">
          <a-form id="components-form-demo-normal-login" :form="form" @submit="phoneSubmit">           
              <a-form-item>
                <a-input ref="phone" v-decorator="[
                  'phone',
                  {rules: [{ required: true, message: '请输入手机号!' }] }]" placeholder="请输入手机号">
                        <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
                </a-input>
              </a-form-item>

              <a-form-item style="width:250px;display:inline-block;margin-left:24px;"> 
                <a-input v-decorator="[
                  'verification',
                  { rules: [{ required: true, message: '请输入验证码!' }] }]" type="password" placeholder="请输入验证码">
                        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
                </a-input>
                <a-button class="login-form-button" style="width:100px;display:inline-block;margin-left:5px;position:absolute; margin-top:3px" @click="sendcode($event)" v-if="issend">
                  验证码
                </a-button>
                <a-button html-type="submit" class="login-form-button" style="width:100px;display:inline-block;margin-left:5px;margin-top:3px;position:absolute" @click="sendcode($event)" v-if="!issend">
                  {{count}}s
                </a-button>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" html-type="submit" class="login-form-button">
                登录
              </a-button>
              </a-form-item>
          </a-form>
        </a-tab-pane>
      </a-tabs>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {smsVerification} from "@/httpsAPI/personal/index";
import { values } from 'xe-utils';

export default {
  name: "login",
  data(){
    return{
      isLoding:false,
      count: 60,
      issend: true,
      phone:'',

    }
  },
  beforeCreate () {
    this.form = this.$form.createForm(this, { name: 'normal_login' });
    this.form2 = this.$form.createForm(this, { name: 'normal_login' });
  },
  methods: {
    ...mapActions(['login','phoneLogin']),
    passEvt(){
      this.$router.push("/v3forget_password")
    },
    handleSubmit (e) {
      this.isLoding = true
      e.preventDefault();
      this.form2.validateFields((err, values) => {
        values.username = values.username.replace(/(^\s*)|(\s*$)/g, "")
        values.password = values.password.replace(/(^\s*)|(\s*$)/g, "")
        if (!err) {
          this.login(values).then(res => {
            if(res.code == 10000){
              this.isLoding = false
              if (this.$route.query.redirect) {
                this.$router.push({ path: this.$route.query.redirect })
              } else {
                this.$router.push({ path: "/" })
              }
            }else{
              this.isLoding = false
              this.$message.error(res.msg)
            }
          })
        }
      });
    },
    phoneSubmit (e) {
      this.isLoding = true
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.phone = values.phone.replace(/(^\s*)|(\s*$)/g, "")
        values.verification = values.verification.replace(/(^\s*)|(\s*$)/g, "")
        if (!err) {
          this.phoneLogin(values).then(res => {
            if(res.code == 10000){
              this.isLoding = false
              if (this.$route.query.redirect) {
                this.$router.push({ path: this.$route.query.redirect })
              } else {
                this.$router.push({ path: "/" })
              }
            }else{
              this.isLoding = false
              this.$message.error(res.msg)
            }
          })
        }
      });
    },
    //验证码
    sendcode() {
      this.phone = this.$refs.phone.value
      if(this.phone == null){
        this.issend = true;
      }else{
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.issend = false;
          //这里可以插入$axios调用后台接口
          smsVerification(this.phone).then(res=>{
            if(res.code == 10000){
              this.isLoding = false
            }else{
              this.isLoding = false
              this.$message.error(res.msg)
            }
          })
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.issend = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.login {
  .logo{
    display: flex;
    position: fixed;
    top: 30px;
    left: 100px;
    img{
      width: 60px;
      height: 60px;
    }
    h2{
      color: #fff;
      font-weight: 900;
      margin-top: 15px;
      margin-left: 10px;
    }
  }
  background: url(../../assets/bg.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: relative;
  .login-form {
    width: 400px;
    height: 450px;
    background: #fff;
    overflow: hidden;
    position: absolute;
    right: 200px;
    top: 200px;
    box-shadow: 0 0 30px -5px #fff;
    h2 {
      color: #1890ff;
      font-weight: 600;
      text-align: center;
      margin: 30px 0px;
    }
  }
}
/deep/.ant-tabs-tab{
  width: 50%;
}
/deep/.ant-tabs-nav-scroll{
  margin: 0px 50px;
}
.ant-form {
  margin-top: 20px;
}

/deep/.ant-form-item-control-wrapper {
  width: 70%;
  margin: 0 auto;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
</style>