<template>
  <a-drawer
    title="编辑试题"
    :visible="visible"
    :confirm-loading="confirmLoading"
    :width="650"
    :body-style="{ paddingBottom: '80px' }"
    @close="cancel"
    destroyOnClose
  >
    <div class="antd-panel">
      <a-form
        :form="form"
        v-bind="formItemLayout"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        @keyup.enter.native="dataFormSubmit()"
      >
        <a-form-item label="题型">
          <a-radio-group
            v-decorator="['questionType', {initialValue:questionType, rules: [{required: true, message: '请选择题型！'}] }]"
            @change="typeChange"
          >
            <a-radio :value="1">
              单选题
            </a-radio>
            <a-radio :value="2">
              多选题
            </a-radio>
            <a-radio :value="3">
              判断题
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="题干">
          <a-textarea
            v-decorator="['question', {initialValue:dataForm.question, rules: [{required: true, message: '请输入题干！'}]}]"
            :rows="4"
            placeholder="请输入题干"
          />
        </a-form-item>

        <a-form-item
          label="图片"
          extra="允许上传图片的格式为jpg、jpeg、png，大小限制5M以内"
        >
          <a-upload
            v-decorator="['picture', {initialValue:dataForm.picture}]"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            accept=".png,.jpg,.PNG,.JPG,JPEG"
            :customRequest="customUploadRequest"
          >
            <img
              v-if="imageUrl"
              :src="imageUrl"
              alt="avatar"
            />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                添加图片
              </div>
            </div>
          </a-upload>
        </a-form-item>

        <a-form-item
          label="选项"
          :required="true"
          v-if="questionType !== 3"
        >
          <a-form-item
            v-for="(item,index) in choiceData"
            :key="index"
            :label="optionLetter[index]"
            :required="false"
            :labelCol="{ span: 2 }"
            :wrapperCol="{ span: 22 }"
          >

            <a-row class="add-option-row">
              <a-col :span="21">

                <a-input
                  :placeholder="item.title"
                  v-decorator="['qItem' + item.key, {initialValue:item.title, rules: [{required: true, message: '请输入选项内容！'}]}]"
                  class="input-text"
                  @change="itemChange($event,item)"
                />
                <a-upload
                  name="file"
                  accept=".png,.jpg,.PNG,.JPG,JPEG"
                  :data=item
                  list-type="picture"
                  :multiple="false"
                  :fileList="item.fileList"
                  :showUploadList="{showRemoveIcon:false}"
                  :customRequest="customItemUploadRequest"
                >
                  <div class='Item'></div>
                  <a-button>
                    <a-icon type="upload" /> 添加图片
                  </a-button>
                </a-upload>
              </a-col>
              <a-col :span="3">
                <a-icon
                  v-if="choiceData.length > 2"
                  class="dynamic-delete-button"
                  type="minus-circle-o"
                  :disabled="choiceData.length === 2"
                  @click="() => removeItem(item.key)"
                  style="margin-left: 18px"
                />
              </a-col>
            </a-row>
          </a-form-item>

          <a-form-item>
            <a-space>
              <a-button
                type="dashed"
                @click="addItem"
                :disabled="choiceData.length === 10"
              >
                <a-icon type="plus" /> 添加选项
              </a-button>
              <span>请添加2-10个选项</span>
            </a-space>
          </a-form-item>
        </a-form-item>

        <a-form-item label="正确答案">
          <a-radio-group
            v-if="questionType === 1"
            v-decorator="['singleAnswer', {initialValue:dataForm.answer,rules: [{required: true, message: '请选择正确答案！'}]}, ]"
          >
            <a-radio
              v-for="(item,index) in choiceData"
              :key="index"
              :value="optionLetter[index]"
            >
              {{optionLetter[index]}}
            </a-radio>
          </a-radio-group>
          <a-checkbox-group
            v-if="questionType === 2"
            v-decorator="['multipleAnswer', {initialValue:dataForm.answer,rules: [{required: true, message: '请选择正确答案！'}]}, ]"
          >
            <a-checkbox
              v-for="(item,index) in choiceData"
              :key="index"
              :value="optionLetter[index]"
            >
              {{optionLetter[index]}}
            </a-checkbox>
          </a-checkbox-group>
          <a-radio-group
            v-if="questionType === 3"
            v-decorator="['judgeAnswer', {initialValue:dataForm.answer,rules: [{required: true, message: '请选择正确答案！'}]}, ]"
          >
            <a-radio
              v-for="(item,index) in judgeData"
              :key="index"
              :value="optionLetter[index]"
            >
              {{optionLetter[index]=='A' ? '正确' : '错误'}}
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="答案解析">
          <a-textarea
            v-decorator="['answerAnalyze',{initialValue:dataForm.answerAnalyze}]"
            :rows="4"
            placeholder="请输入答案解析"
          />
        </a-form-item>
        <a-row>
          <a-col :span="4"></a-col>
          <a-col :span="20">
            <a-button @click="cancel">取消</a-button>
            <a-button
              type="primary"
              style="margin-left: 8px"
              @click="dataFormSubmit()"
            >保存</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </a-drawer>
</template>

<script>
import { updateCourse, detailQuestion } from '@/httpsAPI/course/index'
import { uploadImg } from '@/httpsAPI/common'
export default {
  data () {
    return {
      imgName: '',
      fileList: [],
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      courseId: 0,
      courseNo: '',
      questionId: 0,
      loading: false,
      imageUrl: '',
      questionType: 1,
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      },
      choiceData: [],
      judgeData: [],
      optionLetter: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q"],
      dataForm: [{
        question: '',
        picture: '',
        answer: undefined,
        questionType: 1,
        answerAnalyze: '',
      }]
    }
  },
  created () {

  },
  methods: {
    itemChange (e, item) {
      this.choiceData[item.key].title = e.target.value
    },
    typeChange (e) {
      this.questionType = e.target.value
    },
    // 题干图片上传
    customUploadRequest (option) {
      const formData = new FormData()
      formData.append('file', option.file)
      formData.append('channel', 9)
      let progress = { percent: 1 }

      uploadImg(formData).then(res => {
        if (res.code == 10000) {
          option.onSuccess(res, option.file)
          // progress.percent = 99

          this.imageUrl = res.data.path
          this.form.setFieldsValue({
            picture: res.data.name
          })

        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 选项图片上传
    customItemUploadRequest (option) {
      console.log(option)
      const formData = new FormData()
      formData.append('file', option.file)
      formData.append('channel', 9)

      return uploadImg(formData).then(res => {
        if (res.code == 10000) {
          option.onSuccess(res, option.file)
          option.data.fileList = [{
            uid: '-1',
            name: res.data.name,
            status: 'done',
            url: res.data.path,
            thumbUrl: res.data.path
          }]
          console.log(option.data.fileList,"option.data.fileList")
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    dataFormSubmit (e) {
      this.confirmLoading = true;
      this.form.validateFields((err, values) => {
        if (err) {
          return;
        }

        values['id'] = this.questionId
        values['courseId'] = this.courseId
        values['courseNo'] = this.courseNo
        console.log(this.courseNo,"this.courseNo")

        if (this.questionType === 1) {
          values['answer'] = values['singleAnswer']
        } else if (this.questionType === 2) {
          values['answer'] = values['multipleAnswer'].join(',')
        } else if (this.questionType === 3) {
          values['answer'] = values['judgeAnswer']
        }

        values['itemList'] = this.makeItemData()
        console.log('Received values of form: ', values);
        updateCourse(values).then(res => {
          if (res.code == 10000) {
            this.$message.success({
              content: '操作成功',
              duration: 1,
              onClose: () => {
                this.confirmLoading = false;
                this.$emit('refreshDataList')
                this.visible = false
              }
            })
          } else {
            this.$message.error({
              content: res.msg,
              duration: 1
            })
          }

        })
      })
    },
    // 新增选项
    addItem () {
      const length = this.choiceData.length
      this.choiceData.push({
        key: length === 0 ? '0' : length.toString(),
        title: '',
        imgUrl: '',
        fileList: [

        ]
      })
    },
    // 删除选项
    removeItem (key) {
      const newData = this.choiceData.filter(item => item.key !== key)
      this.choiceData = newData
    },
    // 组装考题选项数据
    makeItemData () {
      const itemData = []

      // 选择题
      if (this.questionType !== 3) {
        this.choiceData.forEach((item, index) => {
          itemData.push({
            courseId: this.courseId,
            courseNo: this.courseNo,
            itemType: this.optionLetter[item.key],
            itemContent: item.title,
            itemImg: item.fileList.length > 0 ? item.fileList[0].name : "",
            sort: item.key + 1
          })
          //有删除按钮
          // if(item.fileList.length > 0){
          //   itemData.push({
          //     courseId: this.courseId,
          //     courseNo: this.courseNo,
          //     itemType: this.optionLetter[item.key],
          //     itemContent: item.title,
          //     // itemImg: item.fileList.length > 0 ? item.fileList[0].name : "",
          //     itemImg: item.fileList.status !== 'removed' ? item.fileList[0].name : "",
          //     sort: item.key + 1
          //   })
          // }else{
          //   itemData.push({
          //     courseId: this.courseId,
          //     courseNo: this.courseNo,
          //     itemType: this.optionLetter[item.key],
          //     itemContent: item.title,
          //     itemImg: "",
          //     sort: item.key + 1
          //   })
          // }
        })
      }

      return itemData;
    },

    cancel (e) {
      this.visible = false;
    },
    init (id,courseNo) {
      this.questionId = id
      this.courseNo = courseNo
      console.log(this.questionId,"this.questionId ")
      this.visible = true
      this.choiceData = []
      this.judgeData = []

      this.$nextTick(() => {
        this.form.resetFields();

        detailQuestion(this.questionId).then(res => {
          this.courseId = res.data.courseId
          this.courseNo = res.data.courseNo
          this.imageUrl = res.data.picture != null ? this.$imgBaseUrl + res.data.picture : ''
          this.questionType = res.data.questionType

          this.dataForm.question = res.data.question
          this.dataForm.picture = res.data.picture
          this.dataForm.answer = this.questionType !== 2 ? res.data.answer : res.data.answer.split(',')

          this.dataForm.questionType = res.data.questionType
          this.dataForm.answerAnalyze = res.data.answerAnalyze

          if (this.questionType !== 3) {
            res.data.items.forEach((item, index) => {
              if (item.itemImg) {
                this.choiceData.push({
                  key: index,
                  title: item.itemContent,
                  imgUrl: item.itemImg,
                  sort: item.key + 1,
                  fileList: [{
                    uid: '-1',
                    name: item.itemImg,
                    status: 'done',
                    url: this.$imgBaseUrl+item.itemImg,
                    thumbUrl: this.$imgBaseUrl+item.itemImg
                  }]
                })

              } else {
                this.choiceData.push({
                  key: index,
                  title: item.itemContent,
                  imgUrl: item.itemImg,
                  sort: item.key + 1,
                  fileList: [

                  ]
                })
              }

            })
          } else {
            this.choiceData = [
              {
                key: '0',
                title: '考题选项一',
                imgUrl: '',
              },
              {
                key: '1',
                title: '考题选项二',
                imgUrl: '',
              },
              {
                key: '2',
                title: '考题选项三',
                imgUrl: '',
              }
            ]
            this.judgeData = [
              {
                key: '0',
                title: '判断选项一',
                imgUrl: '',
              },
              {
                key: '1',
                title: '判断选项二',
                imgUrl: '',
              }
            ]
          }
        })
      })

    }
  }
};
</script>
<style lang="less" scoped>
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.add-option-row {
  .input-text {
    width: 60%;
    margin-right: 18px;
  }
  /deep/ .ant-upload-list {
    width: 60% !important;
  }
}
</style>