<template>
  <div class="antd-table-search-wrap">
    <h4 style="position: absolute; top:50px; left:20px;font-size:24px">{{policyType}}</h4>
      <div class="antd-toolbar">
        <div class="antd-toolbar-right">
          <div class="antd-toolbar-item">
            <a-tooltip>
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover v-model="settingVisible" placement="bottomLeft" trigger="click">
                <a-icon type="setting" style="font-size:30px" />
                <div slot="content" class="antd-draggable-container">
                  <a-checkbox-group v-model="defaultTableList" @change="tableListHandleChange">
                    <div class="antd-draggable-wrapper" v-for="(item,index) in tableListArr" :key="index">
                      <div class="antd-draggable-list-item" :style="{padding:'4px 0'}">
                        <a-checkbox :value="item.dataIndex">
                          {{ item.title }}
                        </a-checkbox>
                      </div>
                    </div>
                  </a-checkbox-group>
                </div>
              </a-popover>
            </a-tooltip>
          </div>
        </div>
      </div>
    <a-button style="position: absolute; top:50px; right:80px;" type="primary" @click="$router.back()">返回</a-button>
    <s-table ref="table" size="default" rowKey="id"  :columns='columns' :data="loadData" :pageNum="pageNum" style="padding-top:60px">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
    </s-table>
    </div>
</template>

<script>
import { getDeclareDetail } from '@/httpsAPI/declare/index'
const columns = [
  {
    title: '序号',
    scopedSlots: { customRender: 'serial' },
    isShow: true 
  },
  {
    title: "项目名称",
    dataIndex: 'projectName',
    isShow: true 
  },
  {
    title: '部门',
    dataIndex: 'deptId',
    isShow: true 
  },
  {
    title: '级别',
    dataIndex: 'level',
    isShow: true 
  },
  {
    title: '类型',
    dataIndex: 'type',
    isShow: true 
  },
  {
    title: '预计金额(万元)',
    dataIndex: 'estimatedMoney',
    isShow: true 
  },
]
export default {
  name: "OrdersEnt",
  data () {
    return {
      isShow: false,
      tableSelectVisible: false,
      listId: '',
      pageNum: 1,
      queryParams: {},
      policyType:'',
      addFormVisible: false,
      loadData: parameter => {
        this.pageNum = parameter.pageNo
        this.listId = this.$route.query.id
        return getDeclareDetail(Object.assign(parameter, {policyTypeId:this.listId})).then(res => {
          return res.data
        })
      },
      visible: false,
      confirmLoading: false,
      model: null,
      tableListArr: (() => {
        return columns.filter(item => {
          return item.dataIndex
        })
      })(),
      defaultTableList: (() => {
        let arr = [];
        columns.map(item => {
          if (item.isShow) {
            arr.push(item.dataIndex)
          }
        })
        return arr
      })(),
      currentMenu: [0],
      settingVisible: false,
      form: this.$form.createForm(this, { name: 'searchProject' }),
      selectedRowKeys: [],
      selectedRows: [],
      alert: {
        show: false, clear: () => { this.selectedRowKeys = [] }
      },
      rowSelection: {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  components: {
  },
  computed: {
    columns () {
      return columns.filter(item => {
        return item.isShow
      })
    }
  },
  created () {
    this.policyType = this.$route.query.policyType
  },

  methods: {
    // 重置搜索form
    resetQueryForm () {
      this.queryParams = {}
      this.$refs.table.refresh(true)
    },
    //动态表头
    handleTable () {
      this.tableSelectVisible = true
    },
    handleMenuClick (e) {
      if (e.key == null) {
        this.isShow = false;
      }
    },
    tableListHandleChange (checkedVal) {
      columns.forEach(item => {
        if (checkedVal.includes(item.dataIndex)) {
          item.isShow = true
        } else {
          item.isShow = false
        }
      })
      console.log(this.columns);
    },
  }
}
</script>
<style lang="less" scoped>
.icon-chang {
  color: #666;
  right: 0;
}
.antd-table-search-wrap{
  position: relative;
  .antd-toolbar-right{
    position: absolute;
    top: 40px;
    right: 40px;
  }
}
</style>