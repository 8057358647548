<template>
  <a-modal
      title="设置目标交付物"
      :visible="visible"
      width="60%"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      destroyOnClose
    >
      <div class="antd-panel">
        <div class="antd-toolbar">
            <div class="antd-toolbar-left">
                <a-button type="primary" @click="addDeilverableEvt"> + 新建交付物</a-button>
            </div>
        </div>
        <div class="antd-table-wrap">
          <a-table
            ref="table"
            size="default"
            rowKey='id'
            :columns="columns"
            :data-source="tabData"
          >
          <template slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </template>
          <template slot="deliverableType" slot-scope="text, record">
                <a-select :default-value="2" style="width: 100px;" @change="value => handleChangeType(value)" v-if="record.editable" :disabled="true">
                        <a-select-option :value="1">网页链接</a-select-option>
                        <a-select-option :value="2">文件</a-select-option>
                        <a-select-option :value="3">知识库</a-select-option>
                </a-select>
                <template v-else>
                    <span v-if="record.type===1">网页链接</span>
                    <span v-if="record.type===2">文件</span>
                    <span v-if="record.type===3">知识库</span>
                </template>
           </template>
          <template slot="deliverableName" slot-scope="text, record">
                <a-input
                    v-if="record.editable"
                    style="margin: -5px 0"
                    :value="text"
                    @change="e => handleChangeName(e.target.value)"
                />
                <template v-else>
                    {{ text }}
                    </template>
           </template>
          <template slot="action" slot-scope="text,record">
              <span v-if="record.editable">
                <a @click="() => saveDeilverableEvt(record)">保存</a>
              </span>
              <a-popconfirm
                title="确定删除该交付物么？"
                ok-text="确定"
                cancel-text="取消"
                placement="left"
                @confirm="deleteEvt(record)"
              >
                <a href="javascript:void(0)" class="table-operation-action">删除</a>
              </a-popconfirm>
            </template>
          </a-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">关闭</a-button>
      </span>
    </a-modal>
</template>
<script>
import {getDeliverableList, deliverableDelete, deliverableAdd} from "@/httpsAPI/project/review";

const columns=[
  {
    title: '序号',
    scopedSlots: { customRender: 'serial' }
  },
  {
    title:'文件名',
    dataIndex:'name',
    scopedSlots: { customRender: "deliverableName" }
  },
  {
    title:'类型',
    dataIndex:'type',
    scopedSlots:{customRender:"deliverableType"}
  },
  {
    title:'操作',
    dataIndex:'action',
    align: 'center',
    scopedSlots:{customRender:"action"},
    width:"260px",
  }
]
    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                visible: false,
                confirmLoading: false,
                planId : 0,
                projectId: 0,
                columns,
                tabData: []
            }
        },
        created(){
        },
        methods:{
          handleChangeName(value) {
              this.tabData[this.tabData.length-1].name = value
          },
          handleChangeType(value) {
              this.tabData[this.tabData.length-1].type = value
          },
          // 新建
          addDeilverableEvt() {
              if(this.tabData.length==0 || this.tabData[this.tabData.length-1].id) {
                  this.tabData.push({
                        id: 0,
                        planId: this.planId,
                        projectId: this.projectId,
                        name: '交付物名称',
                        type: 2,
                        editable: true
                    })
              } else {
                  this.$message.info("请先保存新建交付物！")
              }
          },
          // 保存交付物
          saveDeilverableEvt(record) {
              deliverableAdd(record).then(res=>{
                    if(res.code==10000) {
                        this.$emit('refreshDataList')
                        this.init(this.planId, this.projectId)
                    } else {
                        this.$message.error({
                            content: res.msg,
                            duration: 1
                        })
                    }
                })
          },
          // 删除
          deleteEvt(record) {
            const that = this
            if(record.id) {
                deliverableDelete({id:record.id}).then(res=>{
                    if(res.code==10000) {
                        this.$emit('refreshDataList')
                        that.init(this.planId, this.projectId)
                    } else {
                        this.$message.error({
                            content: res.msg,
                            duration: 1
                        })
                    }
                })
            } else {
                this.tabData.splice(this.tabData.length-1,1);
            }
          },
          handleCancel(e) {
            this.visible = false;
          },
          init (planId, projectId) {
            this.visible = true
            this.planId = planId
            this.projectId = projectId
            
            this.$nextTick(() => { 
                this.form.resetFields();
                
                getDeliverableList({id: planId}).then(res=>{
                    this.tabData = res.data.data
                })
            });
          }
        }
    }
</script>