<template>
  <div>
    <vxe-toolbar>
      <template #buttons>
        <a-button type="primary" @click="addParentStageEvt(1)" v-permit:addStage="{projectId:$route.query.projectId}"> + 新建阶段</a-button>
        <a-button type="primary" @click="addParentStageEvt(2)" style="margin-left: 10px;" v-permit:addStageReview="{projectId:$route.query.projectId}"> + 新建评审点</a-button>
      </template>
    </vxe-toolbar>
    <vxe-grid
      border
      highlight-hover-row
      highlight-current-row
      row-key
      ref="xTable2"
      align="left"
      class="sortable-tree-demo"
      :scroll-y="{enabled: false}"
      :columns="tableColumn"
      :data="tableTreeData"
      :loading="loading"
      :tree-config="{children: 'children', expandAll: true}"></vxe-grid>
    <stage-add ref="addForm" @cancel="()=>{this.stageAddVisible=false}" :visible="stageAddVisible" :confirm-loading="confirmLoading" @refreshDataList="loadStageData"/>
    <stage-update ref="updateForm" @cancel="()=>{this.stageUpdateVisible=false}" :visible="stageUpdateVisible" :confirm-loading="confirmLoading" @refreshDataList="loadStageData"/>
    <plan-task-add ref="addTaskForm"  v-if="taskAddVisible" @refreshDataList="loadStageData" />
    <plan-task-update ref="updateTaskForm"  v-if="taskUpdateVisible" @refreshDataList="loadStageData" />
    <task-relate ref="relateTaskForm"  v-if="relateTaskVisible" @refreshDataList="loadStageData" />
    <!-- 修改阶段任务状态弹窗 -->
    <status-form v-if="updateFormVisible" ref="statusForm" @refreshDataList="loadStageData" />
      <!-- 修改状态弹窗 -->
    <status-review-form v-if="updateReviewFormVisible" ref="statusReviewForm" @refreshDataList="loadStageData"></status-review-form>
  </div>
</template>

<script>
import store from '@/store'
import moment from 'moment'
import {treeDataTranslate} from '@/utils'
import XEUtils from 'xe-utils'
import Sortable from 'sortablejs'
import { getUserProjectPermList } from "@/httpsAPI/configure";
import {getProjectManageStageList2, deleteStage, deleteTask, detailTask} from "@/httpsAPI/project/stage";
import stageAdd from "@/views/projectManage/moudles/stageAdd";
import stageUpdate from "@/views/projectManage/moudles/stageUpdate";
import planTaskAdd from "./planTaskAdd";
import planTaskUpdate from "./planTaskUpdate";
import taskRelate from "./taskRelate";
import StatusForm from "@/components/Status";
import StatusReviewForm from "@/components/Status/reviewDel";

export default {
  name: "stageTable",
  data(){
    return{
      imgStatus:[require('@/assets/edit.png')],
      updateFormVisible: false,
      updateReviewFormVisible:false,
      statusType:1,
      scroll:0,
      tableTreeData: [{"planName": ""}, {"planName": ""}, {"planName": ""}],
      showHelpTip2: false,
      loading: false,
      tableColumn: [
        {
          width: 40,
          slots: {
            default: () => {
              return [
                <span class="drag-btn">
                          <a-icon type="menu" />
                        </span>
              ]
            },
            header: () => {
              return [
                <vxe-tooltip v-model={ this.showHelpTip2 } content="按住后可以上下拖动排序！" enterable>
                  <i class="vxe-icon--question" onClick={ () => { this.showHelpTip2 = !this.showHelpTip2 } }></i>
                </vxe-tooltip>
              ]
            }
          }
        },
        { field: 'planName',width:300, title: '项目阶段', treeNode: true,
          slots:{
            default:(data)=>{
              if(data.row.planType==1) {
                if(window._CONFIG['highlightId'] == data.row.id){
                  return [<div style="background:#B8CAF7"><a-tooltip><a-icon type="layout" style="font-size:12px;" />{ data.row.planName }</a-tooltip></div>]
                }else{
                  return [<div><a-tooltip><a-icon type="layout" style="font-size:12px;" />{ data.row.planName }</a-tooltip></div>]
                }
              } else if(data.row.planType==2) {
                if(window._CONFIG['highlightId'] == data.row.id){
                  return [<div style="background:#B8CAF7"><a-tooltip><a-icon type="flag" style="font-size:12px;" />{ data.row.planName }</a-tooltip></div>]
                }else{
                  return [<div><a-tooltip><a-icon type="flag" style="font-size:12px;" />{ data.row.planName }</a-tooltip></div>]
                }
              } else if(data.row.planType==3) {
                if(window._CONFIG['highlightId'] == data.row.id){
                  return [<div style="background:#B8CAF7"><a-tooltip><a-icon type="file" style="font-size:12px;" />{ data.row.planName }</a-tooltip></div>]
                }else{
                  return [<div><a-tooltip><a-icon type="file" style="font-size:12px;" />{ data.row.planName }</a-tooltip></div>]
                }
              } else if(data.row.planType==4) {
                return data.row.planName
              }
            }
          }
        },
        { field: 'planType', title: '类型',
          slots:{
            default:(data)=>{
              if(data.row.planType==1) {
                return '阶段'
              } else if(data.row.planType==2) {
                return '评审点'
              } else if(data.row.planType==3) {
                return '任务'
              } else if(data.row.planType==4) {
                return '需求'
              }
            }
          }
        },
        { field: 'progress', title: '进度',
          slots:{
            default:(data)=>{
              if(data.row.progress!=undefined) {
                return data.row.progress + '%'
              }else {
                return ''
              }

            }
          }
        },
        { field: 'masterName', title: '负责人'},
        { field: 'startTime', title: '计划开始日期',
          slots:{
            default:(data)=>{
              if(data.row.startTime) {
                return data.row.startTime ? moment(data.row.startTime).format('YYYY-MM-DD') : ''
              } else {
                return ''
              }
            }
          }
        },
        { field: 'endTime', title: '计划完成日期',
          slots:{
            default:(data)=>{
              return data.row.endTime ? moment(data.row.endTime).format('YYYY-MM-DD') : ''
            }
          }
        },
        { field: 'status', title: '状态',
          slots:{
            default:(data)=>{
              if(data.row.planType==1) {
                if(data.row.status==0) {
                  return '待开始'
                } else if(data.row.status==1) {
                  return '进行中'
                } else if(data.row.status==2) {
                  return '已完成'
                }
              } else if(data.row.planType==2) {
                if(data.row.status==0) {
                  return '未完成'
                } else {
                  return '已完成'
                }
              } else {
                if(data.row.status==0) {
                  return '待开始'
                } else if(data.row.status==1) {
                  return '进行中'
                } else if(data.row.status==2) {
                  return '已完成'
                }
              }
            }
          }
        },
        {
          width: 190,
          slots:{
            default:(data)=>{
              if(data.row.planType==2) { // 评审点
                return [
                  this.aclList.includes('updateStagePlan') ? <a-tooltip><template slot="title">编辑</template><a-icon type="form" onClick={()=>this.updateStageEvt(data)} /></a-tooltip> : '',
                  <a-tooltip><template slot="title">详情</template><a-icon type="container" onClick={()=>this.detailReviewEvt(data)} /></a-tooltip>,
                  this.aclList.includes('deletePlan') ? <a-tooltip><template slot="title" v-permit:addStage>删除</template><a-icon type="delete" onClick={()=>this.deleteStageEvt(data)}/></a-tooltip> : '',
                  <a-tooltip><template slot="title">状态</template><div class="status-icon" onClick={()=>this.updateStatusEvt(data)}></div></a-tooltip>
                ]
              } else if(data.row.planType==3) { // 任务
                return [
                  this.aclList.includes('updateStagePlan') ? <a-tooltip><template slot="title">编辑</template><a-icon type="form" onClick={()=>this.updateStageEvt(data)} /></a-tooltip> : '',
                  this.aclList.includes('addChildTask') ? <a-tooltip><template slot="title">新建子任务</template><a-icon type="plus" onClick={()=>this.addChildTaskEvt(data, 1)} /></a-tooltip> : '',
                  <a-tooltip><template slot="title">详情</template><a-icon type="container" onClick={()=>this.detailTaskEvt(data)} /></a-tooltip>,
                  this.aclList.includes('deleteTask') ? <a-tooltip><template slot="title">删除</template><a-icon type="delete" onClick={()=>this.deleteStageEvt(data)}/></a-tooltip> : '',
                  <a-tooltip><template slot="title">状态</template><div class="status-icon" onClick={()=>this.updateStatusEvt(data)}></div></a-tooltip>
                ]
              } else {
                return [
                  this.aclList.includes('updateStagePlan') ? <a-tooltip><template slot="title" v-permit:updateStagePlan>编辑</template><a-icon type="form" onClick={()=>this.updateStageEvt(data)} /></a-tooltip> : '',
                  this.aclList.includes('addChildTask') ? <a-tooltip><template slot="title">新建任务</template><a-icon type="plus" onClick={()=>this.addChildTaskEvt(data, 0)} /></a-tooltip> : '',
                  this.aclList.includes('addChildPlan') ? <a-tooltip><template slot="title">新建子阶段</template><a-icon type="plus-circle" onClick={()=>this.addChildStageEvt(data)} /></a-tooltip> : '',
                  this.aclList.includes('relateTask') ? <a-tooltip><template slot="title">关联任务</template><a-icon type="apartment" onClick={()=>this.relateTaskEvt(data)} /></a-tooltip> : '',
                  <a-tooltip><template slot="title">详情</template><a-icon type="container" onClick={()=>this.detailStageEvt(data)} /></a-tooltip>,
                  this.aclList.includes('deletePlan') ? <a-tooltip><template slot="title">删除</template><a-icon type="delete" onClick={()=>this.deleteStageEvt(data)}/></a-tooltip> : '',
                  <a-tooltip><template slot="title">状态</template><div class="status-icon" onClick={()=>this.updateStatusEvt(data)}></div></a-tooltip>
                ]
              }
            },
            header:()=>{
              return [
                '操作'
              ]
            }
          }
        }
      ],
      stageAddVisible:false,
      stageUpdateVisible: false,
      confirmLoading:false,
      taskAddVisible: false,
      taskUpdateVisible: false,
      relateTaskVisible: false,
      isAuth: true,
      aclList: [] // 已授权权限列表
    }
  },
  components:{
    stageAdd,
    stageUpdate,
    planTaskAdd,
    planTaskUpdate,
    taskRelate,
    StatusForm,
    StatusReviewForm
  },
  beforeDestroy () {
    if (this.sortable2) {
      this.sortable2.destroy()
    }
  },
  created() {
    this.getPermData()
  },
  watch:{
      $route: function(to, from) {
        console.log(from.path,"path")
        if (from.path == "/projectManage/stage/detail" || from.path == "/projectManage/task/detail" || from.path == "/projectManage/review/detail") {
          to.meta.isBack = true;
          console.log(to.meta.isBack,"to.meta.isBack")
        } else {
          to.meta.isBack = false;
        }
      }
  },

  methods:{
    // 查询项目权限
    getPermData() {
      const permits = store.getters.permits

      if(permits.length==0) {
        getUserProjectPermList({id: this.$route.query.projectId}).then((res) => {
          let permitList = {}
          permitList.permissions = res.data.permissions
          permitList.isAuth = res.data.isAuth
          permitList.isManageAuth = res.data.isManageAuth
          permitList.projectId = this.$route.query.projectId

          store.commit('SET_PERMITS',permitList)

          if(permitList.isAuth==0) { // 项目已归档，不授权
            this.isAuth = false
            return
          }

          const accessBtns = permitList.permissions.filter(item=>item.permissionId==='projectStage')[0].actionEntitySet
          this.aclList = accessBtns.map(action => {
                    return action.action
                  })
          console.log('accessBtns==========', this.aclList)
        })
      } else {
          if(permits.isAuth==0) { // 项目已归档，不授权
            this.isAuth = false
            return
          }

          const accessBtns = permits.permissions.filter(item=>item.permissionId==='projectStage')[0].actionEntitySet
          this.aclList = accessBtns.map(action => {
                    return action.action
                  })
      }
    },
    ganttComputedData(){
      let arr = []
      this.tableTreeData.map(item=>{
        arr.push(item)
        if(item.children && item.children.length>0){
          item.children.map(child=>{
            arr.push(child)
          })
        }
      })
      this.tasks = arr
    },
    treeDrop () {
      this.$nextTick(() => {
        const xTable = this.$refs.xTable2
        this.sortable2 = Sortable.create(xTable.$el.querySelector('.body--wrapper>.vxe-table--body tbody'), {
          handle: '.drag-btn',
          animation: 150,
          onEnd: ({ item, oldIndex }) => {
            const options = { children: 'children' }
            const targetTrElem = item
            const wrapperElem = targetTrElem.parentNode
            const prevTrElem = targetTrElem.previousElementSibling
            const tableTreeData = this.tableTreeData
            const selfRow = xTable.getRowNode(targetTrElem).item
            const selfNode = XEUtils.findTree(tableTreeData, row => row === selfRow, options)
            if (prevTrElem) {
              // 移动到节点
              const prevRow = xTable.getRowNode(prevTrElem).item
              const prevNode = XEUtils.findTree(tableTreeData, row => row === prevRow, options)
              if (XEUtils.findTree(selfRow[options.children], row => prevRow === row, options)) {
                // 错误的移动
                const oldTrElem = wrapperElem.children[oldIndex]
                wrapperElem.insertBefore(targetTrElem, oldTrElem)
                return this.$XModal.message({ content: '不允许自己给自己拖动！', status: 'error' })
              }
              const currRow = selfNode.items.splice(selfNode.index, 1)[0]
              if (xTable.isTreeExpandByRow(prevRow)) {
                // 移动到当前的子节点
                prevRow[options.children].splice(0, 0, currRow)
              } else {
                // 移动到相邻节点
                prevNode.items.splice(prevNode.index + (selfNode.index < prevNode.index ? 0 : 1), 0, currRow)
              }
            } else {
              // 移动到第一行
              const currRow = selfNode.items.splice(selfNode.index, 1)[0]
              tableTreeData.unshift(currRow)
            }
            // 如果变动了树层级，需要刷新数据
            this.tableTreeData = [...tableTreeData]
            this.ganttComputedData()
          }
        })
      })
    },
    // 新建一级阶段或评审点
    addParentStageEvt(planType){
      this.stageAddVisible=true
      this.$nextTick(() => {
        this.$refs.addForm.init(0, planType,this.$route.query.projectId)
      })
    },
    // 新建子阶段
    addChildStageEvt(data){
      this.stageAddVisible=true
      this.$nextTick(() => {
        this.$refs.addForm.init(data.row.id, 1)
      })
    },
    // 编辑阶段
    updateStageEvt(data){
      if(data.row.planType==3) {
        this.taskUpdateVisible=true
        this.$nextTick(() => {
          this.$refs.updateTaskForm.init(data.row.id)
        })
      } else {
        this.stageUpdateVisible=true
        this.$nextTick(() => {
          this.$refs.updateForm.init(data.row)
        })
      }
    },
    // 删除阶段或任务
    deleteStageEvt(data) {
      const that = this
      if(data.row.planType==3) {
        this.$confirm({
          title: '系统提示',
          content: '确认删除任务['+data.row.planName+']么?',
          okText: '确认',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            deleteTask({id:data.row.id}).then(res=>{
              if(res.code==10000) {
                  that.$message.success({
                      content: '操作成功',
                      duration: 1,
                      onClose: () => {
                          that.loadStageData()
                      }
                  })
              } else {
                  that.$message.error({
                      content: res.msg,
                      duration: 1
                  })
              }
              })
          }
        })
      } else {
        this.$confirm({
          title: '确认删除'+ data.row.planName + '?',
          content: h => <div style="color:red;">项目阶段内包含子任务等数据，请慎重进行删除操作！！！</div>,
          okText: '确认',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            deleteStage({id:data.row.id}).then(res=>{
              if(res.code==10000) {
                  that.$message.success({
                      content: '操作成功',
                      duration: 1,
                      onClose: () => {
                          that.loadStageData()
                      }
                  })
              } else {
                  that.$message.error({
                      content: res.msg,
                      duration: 1
                  })
              }
              })
          }
        })
      }
    },
    // 修改评审点状态
    updateStatusEvt(data) {
      if(data.row.planType==2){
        this.updateReviewFormVisible = true
        this.$nextTick(() => {
          this.$refs.statusReviewForm.init(data.row.id,data.row.planType,data.row.status)
        })
      }else{
        this.updateFormVisible = true
        if(data.row.planType==3){
            detailTask({id:data.row.id}).then(res=>{
              const record = res.data
              this.$nextTick(() => {
                this.$refs.statusForm.init(record.id,data.row.planType,data.row.status)
              })
            })
        }else{
          this.$nextTick(() => {
            this.$refs.statusForm.init(data.row.id,data.row.planType,data.row.status)
          })
        }
      }
    },
    // 阶段详情
    detailStageEvt(data) {
      this.$router.push({ path: '/projectManage/stage/detail', query: {projectId: this.$route.query.projectId, planId: data.row.id} })
    },
    // 评审点详情
    detailReviewEvt(data) {
      this.$router.push({ path: '/projectManage/review/detail', query: {projectId: this.$route.query.projectId, planId: data.row.id} })
    },
    // 任务详情
    detailTaskEvt(data) {
      detailTask({id:data.row.id}).then(res=>{
        const record = res.data
        this.$router.push({ path: '/projectManage/task/detail', query: {projectId: this.$route.query.projectId, taskId: record.id} })
      })

    },
    // 加载阶段数据
    loadStageData() {
      this.loading = true
      getProjectManageStageList2({projectId:this.$route.query.projectId}).then(res=>{
        this.tableTreeData = treeDataTranslate(res.data, 'id', 'parent')
        console.log('this.tableTreeData', this.tableTreeData)

        this.$nextTick(() => this.$refs.xTable2.setAllTreeExpand(true))
        //this.ganttComputedData()
        this.loading = false
      })
    },
    // 新建子任务 isChild 0非子任务，1子任务
    addChildTaskEvt(data, isChild){
      this.taskAddVisible=true
      this.$nextTick(() => {
        this.$refs.addTaskForm.init(this.$route.query.projectId, data.row.id, isChild)
      })
    },
    // 关联任务
    relateTaskEvt(data){
      this.relateTaskVisible=true
      this.$nextTick(() => {
        this.$refs.relateTaskForm.init(this.$route.query.projectId, data.row.id)
      })
    },
    handleScroll () {
       this.scroll  = document.querySelector(".ant-layout-content").scrollTop;
    },

  },

  mounted() {
    document.querySelector(".ant-layout-content").addEventListener('scroll',this.handleScroll)
    this.loadStageData()
    this.treeDrop()
    this.handleScroll ()
  },

  activated() {
    // 第一次和非详情页进入页面时 isBack都为false，即需要重新请求数据
    if (!this.$route.meta.isBack) {
        // 清空查询条件和数据 并重新请求数据
        this.tableTreeData = [];
        this.loadStageData()
    }
    this.$route.meta.isBack = false;
    if(this.scroll > 0){
        document.querySelector(".ant-layout-content").scrollTo(0, this.scroll);
          this.scroll = 0;
          document.querySelector(".ant-layout-content").addEventListener('scroll',this.handleScroll)
      }

  },
  deactivated(){
      document.querySelector(".ant-layout-content").removeEventListener('scroll', this.handleScroll);
  },

}
</script>

<style lang="less">
.status-icon{
  width: 17px;
  height: 17px;
  background: url('../../../assets/edit.png') no-repeat;
  background-size: 17px 17px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 2px;
  margin-bottom: 6px;
}
.vxe-cell{
  .anticon{
    font-size: 18px;
    margin: 0 3px;
  }
}
.vxe-loading{
  opacity: 0.4;
}
.vxe-loading .vxe-loading--spinner{
  height:180px !important;
  width:100% !important;

  background-image:url('../../../assets/loading2.gif');
  background-repeat: no-repeat;
  background-size: contain;
  background-position:center;
}


.vxe-loading .vxe-loading--spinner:before,.vxe-loading .vxe-loading--spinner:after{
  display: none;
}
</style>
