<template>
  <a-modal
      title="预警阶段列表"
      ref="addForm"
      width="800px"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      :maskClosable="false"
      destroyOnClose
  >
    <div class="member-list-wrap">
      <div class="list-input">
        <a-input-search v-model="queryParams.planName" placeholder="请输入搜索预警阶段"  @keyup.enter.native="$refs.table.refresh(true)" />
      </div>
    </div>
    <s-table
        rowKey="id"
        :data="loadData"
        size="default"
        ref="table"
        :columns="columns"
        :pageNum="pageNum"
    >
    <a type="link" slot="planName" slot-scope="text,record" @click="planDetailEvt(record)">{{ record.planName }}</a>
    </s-table>
    <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">关闭</a-button>
      </span>
  </a-modal>
</template>

<script>
import { getProjectOverviewPlan } from "@/httpsAPI/project/overview";

const columns = [
  {
    title: "名称",
    dataIndex: "planName",
    scopedSlots: { customRender: "planName" },
  },
  {
    title: "预警时间",
    dataIndex: "endTime",
  },
  {
    title: "负责人",
    dataIndex: "masterName",
  },
]
export default {
  name: "addMember",
  data(){
    return{
      columns,
      visible: false,
      confirmLoading: false,
      queryParams: {},
      pageNum: 1,
      projectId: 0,
      loadData:parameter=>{
        this.pageNum = parameter.pageNo
        this.queryParams.projectId = this.projectId
        this.queryParams.isAlarm = 1
        this.queryParams.planType = 1

        return getProjectOverviewPlan(Object.assign(parameter, this.queryParams)).then(res=>{
          return res.data
        })
      }
    }
  },
  methods:{
    // 阶段详情
    planDetailEvt(record){
      this.$router.push({ path: '/projectManage/stage/detail', query: {projectId: this.$route.query.projectId, planId: record.id} })
    },
    handleCancel(e) {
      this.visible = false;
    },
    init(projectId) {
      this.confirmLoading = false
      this.visible = true
      this.projectId = projectId
    }
  }
}
</script>

<style lang="less">
.member-list-wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .list-label{
    line-height: 42px;
  }
}
</style>