<template>
  <a-modal title="附件列表" :visible="visible" :width="750" :confirm-loading="confirmLoading" @cancel="handleCancel" destroyOnClose>
    <div class="page-search-wrap">
      <a-form layout="inline">
        <a-row :gutter="20">
          <a-col :md="12" :sm="24">
            <a-form-item label="附件名称">
              <a-input v-model="queryParams.fileTitle" placeholder="请输入附件名称" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item>
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQueryForm">重置</a-button>
            </a-form-item>
          </a-col>
          <a-button type="primary" id="load" style="float: right;right: 12px" @click="uploadAccessoryFileButton" v-permit:manageWiki="{moduleId:17}">附件上传
          </a-button>
          <input type="file" id="load_xls" name="file" style="display:none"
            @change="uploadAccessoryFile(uploadAccessoryFileCallback)">
        </a-row>
      </a-form>

      <div>
        <s-table ref="table" size="default" rowKey="id" :data="loadData" :columns="columns" :pageNum="pageNum"
          :pageSize="5" :pagination="{pageSizeOptions:['5']}">
          <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
          <span slot="createTime" slot-scope="text">{{$moment(text).format('YYYY-MM-DD HH:mm:ss')}}</span>
          <template slot="action" slot-scope="text,record">
            <a-popconfirm title="确定删除此数据么？" ok-text="确定" cancel-text="取消" placement="topLeft"
              @confirm="deleteAccessoryFile(record)">
              <a href="javascript:void(0)" class="table-operation-action" v-permit:manageWiki="{moduleId:17}">删除</a>
            </a-popconfirm>
            <a href="javascript:void(0)" class="table-operation-action" @click="downloadAccessoryFile(record)">下载</a>
          </template>
        </s-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <a-button @click="visible = false">关闭</a-button>
    </span>
  </a-modal>
</template>
<script>
import { deleteAccessoryFile, getAccessoryFileList, downloadCommonFile } from "@/httpsAPI/knowledgeBase/index"
import { uploadFile,coludDownloadFile } from '@/httpsAPI/common'

const columns = [
  {
    title: '序号',
    scopedSlots: { customRender: 'serial' }
  },
  {
    title: "文件名称",
    dataIndex: "fileTitle"
  },
  {
    title: "文件大小(kb)",
    dataIndex: "fileSize"
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    align: 'center',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    title: "操作",
    dataIndex: "action",
    align: 'center',
    scopedSlots: { customRender: "action" },
    width: "188px"
  }
];

export default {
  data () {
    return {
      columns,
      queryParams: {},
      wikiDicId: 0,
      pageNum: 1,
      visible: false,
      confirmLoading: false,
      selectedRowKeys: [],
      selectedRows: [],
      filePath: '',
      list:null,
      loadData: parameter => {
        this.pageNum = parameter.pageNo;
        this.queryParams.refType = 6;
        this.queryParams.refId = this.wikiDicId;
        return getAccessoryFileList(Object.assign(parameter, this.queryParams))
          .then(res => {
            this.filePath = res.data.filePath
            this.list = res.data.data.length
            console.log(this.list,"list")
            return res.data
          })
      }
    }
  },
  components: {
  },
  methods: {
    // 重置搜索form
    resetQueryForm () {
      this.queryParams = {};
      this.$refs.table.refresh(true)
    },
    //附件上传按钮
    uploadAccessoryFileButton () {
      document.getElementById("load_xls").click();
    },
    //附件上传
    uploadAccessoryFile (callback) {
      // 获取input标签选择的文件,并选择第一条
      let resultFile = document.getElementById('load_xls').files[0]
      // 如果文件存在
      if (resultFile) {
        callback(resultFile)
      }
    },
    //附件上传按钮回调
    uploadAccessoryFileCallback (file) {
      var myform = new FormData();
      myform.append('file', file);
      myform.append('refType', 6);
      myform.append('refId', this.wikiDicId);
      uploadFile(myform).then(res => {
        if (res.code == 10000) {
          this.resetQueryForm()
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    //附件下载
    downloadAccessoryFile (record) {
      var type = window._CONFIG['downloadType'];
        if (type == "local") {
        const params = {
          id: [record.id],
          fileName: record.fileName + record.filePath.substring(record.filePath.lastIndexOf('.'))
        }
          downloadCommonFile(params).then(res => {
          })
        }
        if(type == "cloud"){
            coludDownloadFile(record.id,'/project/commonFile/file-download');
        }
    },
    //删除附件按钮
    deleteAccessoryFile (item) {
      const that = this
      deleteAccessoryFile([item.id]).then(res => {
        that.$message.success({
          content: '操作成功',
          duration: 1,
          onClose: () => {
            this.$refs.table.refresh(true)
          }
        })
      }).catch(error => {
        console.log(error)
      })
    },
    dataFormSubmit () {
      this.confirmLoading = true;
      this.visible = false;
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleCancel (e) {
      this.visible = false;
    },
    init (wikiDicId) {
      this.wikiDicId = wikiDicId;
      this.visible = true;
    }
  }
}
</script>