<template>
  <div class="configer-container">
    <a-row type="flex" :gutter="24">
      <a-col :span="6">
        <a-menu
          v-model="activeName"
          style="width: 256px;height: 100%;"
          :default-selected-keys="['sub1']"
          :default-open-keys="['sub1']"
          :mode="mode"
          :theme="theme"
          :style="{width:'100%',overflowY:'auto',overflowX:'hidden'}"
        >
          <a-sub-menu key="sub1">
            <span slot="title"> <a-icon type="appstore" />公司管理</span>
            <a-menu-item key="companyInfo">
              公司信息
            </a-menu-item>
            <a-menu-item key="orgSet">
              组织配置
            </a-menu-item>
            <a-menu-item key="projectSet">
              项目配置
            </a-menu-item>
            <a-menu-item key="permisSet">
              权限设置
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="sub2">
            <span slot="title"> <a-icon type="save" />系统偏好设置</span>
            <a-menu-item key="workSet">
              工作日设置
            </a-menu-item>
            <a-menu-item key="emailSet">
              邮件设置
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-col>
      <a-col :span="18">
        <component :is="activeName[0]" />
      </a-col>
    </a-row>
  </div>
</template>
<script>
import companyInfo from "./components/companyInfo";
import orgSet from "./components/orgSet";
import projectSet from "./components/projectSet";
import permisSet from "./components/permisSet";
import workSet from "./components/workSet";
import emailSet from "./components/emailSet";

export default {
  name: "configure",
  components: { companyInfo, orgSet, projectSet, permisSet, workSet, emailSet },
  data() {
    return {
      mode: "inline",
      theme: "light",
      activeName: ["companyInfo"],
    };
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
</style>
