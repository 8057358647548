<template>
  <div class="antd-panel" style="position:relative">
    <a-button style="position: absolute; top:20px;right:10px;" type="primary" @click="$router.back()">返回</a-button>
    <div class="msg-detail">
      <p style="color:red">{{resData.titile}}</p>
      <p>{{resData.msgContent}}</p>
      <p>发布人：{{resData.sender}}</p>
      <p><span v-if="resData.createBy!=null">操作人：{{resData.createBy}}</span><span style="display:inline-block;margin-left:10px" v-if="resData.createTime!=null">操作时间：{{resData.createTime}}</span></p>
    </div>
  </div>
</template>

<script>
import { getMessageDetail,unreadMessageCount } from "../../httpsAPI/search";
export default {
  data(){
    return{
      msgId:'',
      resData:{}
    }
  },
  created(){
    this.msgId = this.$route.query.msgId
    
  },
  mounted(){
    this.msgDetail()
  },
  methods:{
    //查询消息是否有未读
    unMsgCount(){
      unreadMessageCount().then(res=>{
        this.unReadMsgCount = res.data
      })
    },
    msgDetail(){
      getMessageDetail(this.msgId).then(res=>{
        this.resData = res.data
      })
      this.unMsgCount()
    }
  }

}
</script>

<style lang="sss" scoped>

</style>>

