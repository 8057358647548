<template>
  <div>
    <div class="user">
      <a-button type="primary" @click="getAccount">获取登陆账号和密码</a-button>
    </div>
    <a-modal v-model="visible" title="获取登陆账号和密码" @ok="handleOk">
      <p>用户名：shfxtsg</p>
      <p>密码：Shfxtsg2022</p>

      <p>访问链接：<a href="https://zlf.cqvip.com" target="_blank">https://zlf.cqvip.com</a></p>

      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">关闭</a-button>
      </span>
    </a-modal>
    <div @click="getAccount"><img src="@/assets/zlf.png" alt="" style="width: 100%; height: 100%;" /></div>
  </div>
</template>

<script>
import { addResourcesUse } from "@/httpsAPI/resources/common";

export default {
  data () {
    return {
      visible: false,
    };
  },
  created () {
    addResourcesUse({ type: 6, content: '文献库统计' }).then(res => { })
  },
  methods: {
    getAccount () {
      this.visible = true;
    },
    handleOk (e) {
      this.visible = false;
    }
  }
}
</script>

<style lang="less" scoped>
.user {
  height: 45px;
  p {
    display: inline-block;
  }
}
</style>>

