<template>
  <a-modal
      title="系统提示"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      :maskClosable="false"
      destroyOnClose
    >
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @keyup.enter.native="dataFormSubmit()">
        <a-form-item label="解锁密码">
            <a-input-password placeholder="请输入解锁密码" v-decorator="['passWord', { rules: [{ required: true, message: '请输入解锁密码' } ] }]" />
        </a-form-item>
        <a-form-item label="注意">
            项目已被锁定，请输入解锁码密码后启用！(解锁有效期为半小时，超过此时间需要重新输入密码解锁)
        </a-form-item>
    </a-form>
    <span slot="footer" class="dialog-footer">
        <a-button @click="handleCancel">取消</a-button>
        <a-button class="ant-btn ant-btn-primary" @click="dataFormSubmit()">确定</a-button>
    </span>
    </a-modal>
</template>
<script>
import { unlockPwdProject } from "@/httpsAPI/project/setting";

export default {
 data(){
     return{
        newPassword:'',
        form: this.$form.createForm(this),
        visible: false,
        confirmLoading:false,
        projectId: 0
     }

 },
    methods:{
        dataFormSubmit(e){
            this.form.validateFields((err, values) => {
                if (err) {            
                    return;
                }

                this.confirmLoading = true
                values['projectId'] = this.projectId
                unlockPwdProject(values).then(res=>{
                    this.confirmLoading = false
                    if(res.code == '11111'){
                      this.$message.error({
                        content: res.msg,
                        duration: 1,
                        })
                    }else{
                       this.$message.success({
                          content: res.msg,
                          duration: 1,
                          onClose: () => {
                            this.form.resetFields();
                            this.visible = false;
                          }
                       }) 
                    }
                })                 
            });
            
        },
        handleCancel () {
            this.$router.push({ 
                path : '/project/index'
            })
        },
        close () {
            this.$emit('close');
            this.visible = false;
        },
        init(projectId) {
            this.visible = true
            this.$nextTick(() => { 
                this.projectId = projectId
                this.form.resetFields();
            });
        },
    },
}
</script>

<style>

</style>