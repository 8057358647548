<template>
  <a-modal
      title="修改项目计划周期"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      destroyOnClose
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @keyup.enter.native="dataFormSubmit()">
        <a-form-item label="起止时间">
          <a-range-picker style="width:100%" format="YYYY-MM-DD" v-decorator="['timeRange']" @change="dateChange">
              <a-icon slot="suffixIcon" type="calendar" />
           </a-range-picker>
        </a-form-item>
      </a-form>
      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">取消</a-button>
        <a-button class="ant-btn ant-btn-primary" @click="dataFormSubmit()">确定</a-button>
      </span>
    </a-modal>
</template>
<script>
import moment from 'moment'
import { updatePlantime } from "@/httpsAPI/project/overview";
    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                projectId: '',
                visible: false,
                confirmLoading: false,
                timeRange:[],
                startDate:'',
                endDate:''
            }
        },
        methods:{
          dataFormSubmit(e) {
            this.confirmLoading = true;
            this.form.validateFields((err, values) => {
                if (err) {
                    console.log(err)
                    return;
                }
            
                values['projectId'] = this.projectId
                values['startDate'] = this.startDate
                values['endDate'] = this.endDate
                console.log('Received values of form: ', values);
                updatePlantime(values).then(res=>{
                  if(res.code==10000) {
                    this.$message.success({
                        content: '操作成功',
                        duration: 1,
                        onClose: () => {
                            this.form.resetFields();
                            this.visible = false;
                            this.$emit('refreshDataList')
                        }
                    })
                  }else {
                    this.$message.error({
                        content: res.msg,
                        duration: 1
                    })
                  }
                })
            });
          },
          dateChange(date, dateString) {
            this.startDate = dateString[0]
            this.endDate = dateString[1]
          },
          handleCancel(e) {
            this.visible = false;
          },
          init (projectId,startDate,endDate) {
            this.projectId = projectId
            this.visible = true

            this.$nextTick(() => {
                this.form.resetFields();
                this.startDate = startDate ? moment(startDate).format('YYYY-MM-DD') : ''
                this.endDate = endDate ? moment(endDate).format('YYYY-MM-DD') : ''
                this.form.setFieldsValue({
                  timeRange: this.startDate ? [moment(this.startDate, "YYYY-MM-DD"), moment(this.endDate, "YYYY-MM-DD")] : [],
                })
            });
          }
        }
    }
</script>