<template>
  <layout>
    <template slot="headerMenu">
      <div class="header-menu">
        <div class="header-menu-wraper">
          <div class="header-menu-title">帮助与反馈</div>
          <a-menu mode="horizontal" v-model="currentMenu">
            <a-menu-item key="NewStudy">
              <router-link to="newStudy">新手引导</router-link>
            </a-menu-item>
            <a-menu-item key="Help">
              <router-link to="help">帮助手册</router-link>
            </a-menu-item>
            <a-menu-item key="Workday">
              <router-link to="workday">更新日志</router-link>
            </a-menu-item>
            <a-menu-item key="Contact">
              <router-link to="contact">联系客服</router-link>
            </a-menu-item>
          </a-menu>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      currentMenu: ["NewStudy"],
    };
  },
  created() {
    this.currentMenu = [this.$route.name];
  },
};
</script>

<style scoped>
</style>