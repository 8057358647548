<template>
  <div>
    <a-card :bordered="false" title="资源应用汇总">
      <ve-histogram
        :title="titleSummary"
        :data="chartDataSummary"
        :extend="extendSummary"
        :colors="colors"
        judge-width
      ></ve-histogram>
    </a-card>
    <a-row :gutter="24">
      <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
        <a-card :bordered="false" style="margin-top: 20px; " title="个人应用汇总">
           <a slot="extra" href="#" @click="goMore">更多</a>
          <ve-bar
            :title="titleUser"
            :data="chartDataUser"
            :extend="extendUser"
            :colors="colors"
            :settings="chartDataUserChartSettings"
            judge-width>
            </ve-bar>
        </a-card>
      </a-col>
      
    </a-row>
     <!-- 更多个人应用汇总 -->
    <more-data ref="moreData" v-if="isShowVisible"></more-data>
  </div>
</template>

<script>
import Lib from "echarts/lib/echarts";
import { resourcesMoth, resourcesUser } from "@/httpsAPI/effect";
import moreData from "./allData";

export default {
  name: "effectManage",
  data() {
    return {
      isShowVisible:false,
      colors: ["#1be9be", "#63c2ff", "#ffcb8c", "#ff7993",  "#0070b6", "#f16508", "#eb1c26","#a024d3"],
      titleSummary: {
        text: "应用次数",
      },
      titleUser: {
        text: "应用次数",
      },
      tooltip: {
        show: false,
        trigger: "none",
      },
      chartDataSummary: {
        columns: ["工作项", "专利","成果库","专家库", "市场报告", "标准","期刊库","文献库","万方数据"],
        rows: [],
      },
      chartDataUserChartSettings: {
        metrics: ['专利', '成果库', '专家库', '市场报告', '标准',"期刊库","文献库","万方数据"],
        dimension: ['用户'],
        stack: {
            '国内': ['专利', '成果库', '专家库', '市场报告', '标准',"期刊库","文献库","万方数据"]
        }
      },
      chartDataUser: {
        columns: ['用户', '专利', '成果库', '专家库', '市场报告', '标准'],
        rows: []
      },
      extendSummary: {
        series: {
          type: "bar",
          label: { show: false, position: "top" },
        },
      },
      extendUser: {
        series: {
          type: "bar",
          label: { show: true, position: "top" },
        },
      },
    };
  },
  components: {
    Lib,
    moreData
  },
  methods: {
    // 个人应用汇总更多
    goMore(){
      this.isShowVisible = true
      this.$nextTick(() => {
        this.$refs.moreData.init();
      });
    },
    getResourceSummaryChartData() {
      const data = {};
      resourcesMoth(data).then((res) => { 
        this.chartDataSummary.rows = [{ "工作项":"数量", "专利": res.data[0].resourcesCount, "成果库": res.data[1].resourcesCount, 
        "专家库": res.data[2].resourcesCount, "市场报告": res.data[3].resourcesCount, "标准": res.data[4].resourcesCount,"期刊库": res.data[5].resourcesCount,"文献库": res.data[6].resourcesCount,"万方数据": res.data[7].resourcesCount}]
      });
    },
    getUserChartData() {
      const data = {};
      resourcesUser(data).then((res) => {
        for(var i in res.data) {
          const obj = res.data[i]
          this.chartDataUser.rows.push(
            {'用户': obj['userName'], '专利': obj['0'], '成果库': obj['1'], '专家库': obj['2'], '市场报告': obj['3'], '标准': obj['4'],'期刊库': obj['5'],'文献库': obj['6'],'万方数据': obj['7']}, 
          )
        }
      });
    },
  },
  created() {
    this.getResourceSummaryChartData()
    this.getUserChartData()
  }
};
</script>

<style scoped>
page{
  background: #eb1c26;
  background: #e9409d;
}
</style>
