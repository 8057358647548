import request from "@/utils/request";

//查询企业信息
export function getCompanyInfo (params) {
  return request({
    url: "/config/Company/index",
    method: 'get',
    data: params
  })
}
//修改企业信息
export function updateCompanyInfo (params) {
  return request({
    url: "/config/Company/update",
    method: 'post',
    data: params
  })
}
//组织架构查询
export function getDeptTree (params) {
  return request({
    url: "/config/Dept/deptTree",
    method: 'post',
    data: params
  })
}
//新增组织架构
export function addDeptTree (params) {
  return request({
    url: "/config/Dept/add",
    method: 'post',
    data: params
  })
}
//修改组织架构
export function updateDeptTree (params) {
  return request({
    url: "/config/Dept/update",
    method: 'post',
    data: params
  })
}
//组织架构详情
export function detailDeptTree (params) {
  return request({
    url: "/config/Dept/detail/" + params,
    method: 'post',
    data: params
  })
}
//删除组织架构
export function delDeptTree (params) {
  return request({
    url: "/config/Dept/delete",
    method: 'post',
    data: params
  })
}
//查询工作日
export function getWeekday (params) {
  return request({
    url: "/config/weekday",
    method: 'post',
    data: params
  })
}
//修改工作日
export function updateWeekday (params) {
  return request({
    url: "/config/setWeekday",
    method: 'post',
    data: params
  })
}
//用户列表
export function getUserlist (params) {
  return request({
    url: "/user/profile/list",
    method: 'post',
    data: params
  })
}
//新增用户
export function addUser (params) {
  return request({
    url: "/user/profile/add",
    method: 'post',
    data: params
  })
}
// 修改用户
export function updateUser(params) {
  return request({
    url: "/user/profile/update",
    method: 'post',
    data: params
  })
}
//删除用户
export function delUser (params) {
  return request({
    url: "/user/profile/delete",
    method: 'post',
    data: params
  })
}
//用户详情
export function detailUser (params) {
  return request({
    url: "/user/profile/detail/" + params,
    method: 'post',
    data: params
  })
}
//项目配置列表
export function getProjectList (params) {
  return request({
    url: "/config/project-list",
    method: 'post',
    data: params
  })
}
//项目配置详情
export function detailProject (params) {
  return request({
    url: "/config/project-detail/" + params,
    method: 'get',
    data: params
  })
}
//删除项目配置列表
export function delProjectList (params) {
  return request({
    url: "/config/delete",
    method: 'post',
    data: params
  })
}
//开启项目
export function openProject (params) {
  return request({
    url: "/config/project-active/" + params,
    method: 'post',
    data: params
  })
}
//关闭项目
export function closeProject (params) {
  return request({
    url: "/config/project-close/" + params,
    method: 'post',
    data: params
  })
}
//邮件设置
export function emailSet (params) {
  return request({
    url: "/config/setEmail",
    method: 'post',
    data: params
  })
}
//邮件详情
export function emailDetail (params) {
  return request({
    url: "/config/email",
    method: 'post',
    data: params
  })
}

// 权限设置-获取系统角色列表
export function getRoleList (params) {
  return request({
    url: "/user/role/list",
    method: 'post',
    data: params
  })
}

// 权限设置-获取系统权限列表（标注已分配权限）
export function getPermList (params) {
  return request({
    url: "/user/auth/permission-list/" + params.id,
    method: 'post',
    data: {}
  })
}

// 权限设置-新建自定角色
export function addRole (params) {
  return request({
    url: "/user/role/add",
    method: 'post',
    data: params
  })
}

// 权限设置-修改自定角色
export function updateRole (params) {
  return request({
    url: "/user/role/update",
    method: 'post',
    data: params
  })
}

// 权限设置-删除自定角色
export function deleteRole (params) {
  return request({
    url: "/user/role/delete",
    method: 'post',
    data: params
  })
}

// 权限设置-保存角色权限
export function savePerm (params) {
  return request({
    url: "/user/auth/save-permission",
    method: 'post',
    data: params
  })
}

// 权限设置-获取项目权限列表（标注已分配权限）
export function getProjectPermList (params) {
  return request({
    url: "/user/auth/project-permission-list/" + params.id,
    method: 'post',
    data: {}
  })
}

// 获取用户在项目里的权限列表
export function getUserProjectPermList (params) {
  return request({
    url: "/user/auth/user-project-permission/" + params.id,
    method: 'post',
    data: {}
  })
}

// 获取用户指定模块里的权限列表
export function getUserModulePermList (params) {
  return request({
    url: "/user/auth/user-module-permission/" + params.id,
    method: 'post',
    data: {}
  })
}

//获取当前用户所属企业的部门列表
export function getDepartmentList (params) {
  return request({
    url: "/user/profile/department-list",
    method: 'post',
    data: params
  })
}

//修改项目负责人
export function updateProject (params) {
  return request({
    url: "/project/update",
    method: 'post',
    data: params
  })
}