import API from "@/httpsAPI/API";
import request from "@/utils/request";
import {downloadFile} from "@/httpsAPI/common";

// 项目任务列表
export function getProjectManageTaskList(params) {
  return request({
    url:API.projectTaskList,
    method:'post',
    data:params
  })
}

// 新建任务
export function addTask(params) {
  return request({
    url: '/project/task/add',
    method:'post',
    data:params
  })
}

// 修改任务
export function updateTask(params) {
  return request({
    url: '/project/task/update',
    method:'post',
    data:params
  })
}

// 删除任务
export function deleteTask(params) {
  return request({
    url: '/project/task/delete/' + params.id,
    method:'post',
    data:params
  })
}

// 任务详情
export function detailTask(params) {
  return request({
    url: '/project/task/detail/' + params.id,
    method:'post',
    data:params
  })
}

// 待关联任务列表
export function getUnrelateTask(params) {
  return request({
    url: '/project/task/unrelate-list',
    method:'post',
    data:params
  })
}

// 批量关联任务
export function batchRelateTask(params) {
  return request({
    url: '/project/task/batch-relate',
    method:'post',
    data:params
  })
}

// 任务评分
export function evaluateTask(params) {
  return request({
    url: '/project/task/evaluate',
    method:'post',
    data:params
  })
}

// 已关联WIKI列表
export function getRelatedWikiList(params) {
  return request({
    url: '/project/task/related-wiki-list/' + params.taskId,
    method:'post',
    data:{}
  })
}

// 企业所有WIKI列表
export function getWikiList(params) {
  return request({
    url: '/project/task/wiki-list',
    method:'post',
    data:params
  })
}

// wiki目录列表
export function getWikiDicList(params) {
  return request({
    url: '/project/task/wiki-dic/',
    method:'post',
    data:params
  })
}

// 批量关联Wiki
export function batchRelateWiki(params) {
  return request({
    url: '/project/task/batch-relate-wiki',
    method:'post',
    data:params
  })
}

// 删除关联Wiki
export function deleteRelateWiki(params) {
  return request({
    url: '/project/task/wiki-dic-delete/' + params.id,
    method:'post',
    data:{}
  })
}

// 任务资料列表查询
export function getFileList(params) {
  return request({
    url: '/project/task/file-list',
    method:'post',
    data:params
  })
}

// 任务资料上传接口
export function fileUpload(params) {
  return request({
    url: '/project/task/file-upload',
    method:'post',
    data:params
  })
}

// 删除任务资料
export function deleteFile(params) {
  return request({
    url: '/project/task/file-delete/' + params.id,
    method:'post',
    data:params
  })
}

// 任务资料下载
export function fileDownload(params) {
  return downloadFile('/project/file/file-download/' + params.id, params.fileName, null, 'post')
}

// 共享成员列表
export function getShare(params) {
  return request({
    url: '/user/file/shareList',
    method:'post',
    data:params
  })
}
//设置文件共享
export function setShare(params) {
  return request({
    url: '/user/file/batch_share',
    method:'post',
    data:params
  })
}