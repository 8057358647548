<template>
  <layout>
    <template slot="headerMenu">
      <div class="header-menu">
        <div class="header-menu-wraper">
          <div class="header-menu-title">求助单</div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
export default {
  name: "index",
   data(){
    return{
      currentMenu:['OrdersEnt']
    }
  },
  created() {
    this.currentMenu = [this.$route.name]
  }
}

</script>

<style scoped>

</style>