<template>
  <div class="detail-container" style="position:relative;">
    <a-button style="position: absolute; top:20px; right:50px;" type="primary" @click="$router.back()">返回</a-button>
    <div class="top">
      <img
        class="portrait"
        src="@/assets/ZJ.png"
        alt=""
      />
      <div class="right">
        <h3 style="line-height: 20px;display:inline-block">{{ detail.name }}</h3>
        <span
          style="font-size: 12px;color: #333;font-weight: bold;line-height: 14px;margin-left:5px"
        >
          {{ detail.title }}
        </span>
        <p  style="margin-top:10px"><a-icon type="phone" style="color:#1890ff;font-weight: bold;" />{{detail.contract}}</p>
        <p style="font-size: 12px;line-height: 14px;">
          {{ detail.college }}<span>/{{ detail.company }}</span>
        </p>
      </div>
    </div>
    <div class="bottom">
      <h3 class="title">经历</h3>
      <div class="content">
        <p>教育经历：</p>
        <div v-html="detail.experience ? detail.experience : '暂无数据' ">{{ detail.experience ? detail.experience : "暂无数据" }}</div>
      </div>
    </div>
    <div class="bottom">
      <h3 class="title">成果</h3>
      <div class="content" v-html="detail.achieve ? detail.achieve : '暂无数据'">
        {{ detail.achieve ? detail.achieve : "暂无数据" }}
      </div>
    </div>
    <div class="bottom">
      <h3 class="title">荣誉</h3>
      <div class="content" v-html="detail.honor ? detail.honor : '暂无数据'">
        {{ detail.honor ? detail.honor : "暂无数据" }}
      </div>
    </div>
    <div class="bottom">
      <h3 class="title">专利</h3>
      <div class="content" v-html="detail.perPat ? detail.perPat : '暂无数据'">
        {{ detail.perPat ? detail.perPat : "暂无数据" }}
      </div>
    </div>
  </div>
</template>

<script>
import { view } from "@/httpsAPI/resources/professor";
export default {
  name: "professorDetail",
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      view({ id: this.id }).then((res) => {
        if (res.code === "10000") {
          this.detail = res.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.detail-container {
  background: #fff;
  padding: 30px 10px;
  .top {
    display: flex;
    margin: 0 15px;
    padding: 20px;
    border-bottom: 1px solid #888888;
    .portrait {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      display: inline-block;
    }
    .right {
      margin-left: 20px;
    }
  }
  .bottom {
    margin: 20px 15px;
    padding: 20px;
    .title {
      position: relative;
      padding-left: 12px;
      &::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 16px;
        background: #1890ff;
        position: absolute;
        left: 0;
        top: 4px;
      }
      .content {
        & > div {
          line-height: 22px;
        }
      }
    }
  }
}
</style>
