<template>
  <div class="layout-content-page">
    <div>
      <div class="antd-table-search-wrap">
        <a-row :gutter="12">
          <a-form
            :form="form"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 19 }"
            @submit="searchHandleSubmit"
          >
            <a-col :md="6" :sm="24">
              <a-form-item label="标题">
                <a-input
                  v-model="queryParams.title"
                  placeholder="请输入需求标题"
                />
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="状态">
                <a-select placeholder="请选择状态" v-model="queryParams.status">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="0">待开始</a-select-option>
                  <a-select-option value="1">进行中</a-select-option>
                  <a-select-option value="2">已完成</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="级别">
                <a-select placeholder="请选择级别" v-model="queryParams.level">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="1">最高</a-select-option>
                  <a-select-option value="2">较高</a-select-option>
                  <a-select-option value="3">普通</a-select-option>
                  <a-select-option value="4">较低</a-select-option>
                  <a-select-option value="5">最低</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="6">
              <a-form-item>
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button :style="{marginLeft:'20px'}" type="default" @click="resetQueryForm">重置</a-button>
              </a-form-item>
            </a-col>
          </a-form>
        </a-row>
      </div>

      <div class="antd-page-content">
        <div class="antd-toolbar">
          <div class="antd-toolbar-left">
            <a-button type="primary" @click="createFormEvt" v-action:addRequirement> + 新建需求</a-button>
          </div>
          <div class="antd-toolbar-right">
            <div class="antd-toolbar-item">
              <a-tooltip>
                <template slot="title">
                  <span>刷新</span>
                </template>
                <a-icon type="reload" @click="$refs.table.refresh(true)" />
              </a-tooltip>
            </div>
            <div class="antd-toolbar-item">
              <a-tooltip>
                <template slot="title">
                  <span>列设置</span>
                </template>
                <a-popover
                  v-model="settingVisible"
                  placement="bottomLeft"
                  trigger="click"
                >
                  <a-icon type="setting" />
                  <div slot="content" class="antd-draggable-container">
                    <a-checkbox-group
                      v-model="defaultTableList"
                      @change="tableListHandleChange"
                    >
                      <div
                        class="antd-draggable-wrapper"
                        v-for="(item, index) in tableListArr"
                        :key="index"
                      >
                        <div
                          class="antd-draggable-list-item"
                          :style="{ padding: '4px 0' }"
                        >
                          <a-checkbox :value="item.dataIndex">
                            {{ item.title }}
                          </a-checkbox>
                        </div>
                      </div>
                    </a-checkbox-group>
                  </div>
                </a-popover>
              </a-tooltip>
            </div>
          </div>
        </div>
        <s-table
          size="default"
          ref="table"
          rowKey="id"
          :data="loadData"
          :columns="columns"
          :selections="true"
          :pageNum="pageNum"
          :pagination="{ pageSizeOptions: ['10', '20', '50', '100', '150'] }"
        >
          <span slot="status" slot-scope="text, record">
            <a-tag color="red" v-if="record.status == 0">待开始</a-tag>
            <a-tag color="orange" v-if="record.status == 1">进行中</a-tag>
            <a-tag color="green" v-if="record.status == 2">已完成</a-tag>
          </span>
          <span slot="level" slot-scope="text, record">
            <a-tag color="red" v-if="record.level == 1">最高</a-tag>
            <a-tag color="orange" v-if="record.level == 2">较高</a-tag>
            <a-tag color="blue" v-if="record.level == 3">普通</a-tag>
            <a-tag color="blue" v-if="record.level == 4">较低</a-tag>
            <a-tag color="blue" v-if="record.level == 5">最低</a-tag>
          </span>
          <a-button type="link" slot="taskTitle" slot-scope="text,record" @click="detailEvt(record)">{{ record.title }}</a-button>
          <template slot="action" slot-scope="text,record">
            <a href="javascript:void(0)" class="table-operation-action" @click="detailEvt(record)" v-action:detailRequirement>详情</a>
            <a href="javascript:void(0)" class="table-operation-action" @click="updateFormEvt(record)" v-if="userinfo.userId==record.masterId" v-action:updateRequirement>编辑</a>
            <a-popconfirm
                :title="'确定删除需求['+record.title+']么？'"
                ok-text="确定"
                cancel-text="取消"
                placement="topLeft"
                @confirm="deleteEvt(record)"
                >
                <a href="javascript:void(0)" class="table-operation-action" v-if="(userinfo.userId==record.masterId || userinfo.userType==1)" v-action:deleteRequirement>删除</a>
            </a-popconfirm>
          </template>
        </s-table>
      </div>

      <!-- 新建弹窗 -->
      <add-form v-if="addFormVisible" ref="addForm" @refreshDataList="$refs.table.refresh(true)"></add-form>
      <!-- 修改弹窗 -->
      <update-form v-if="updateFormVisible" ref="updateForm" @refreshDataList="$refs.table.refresh(true)"></update-form>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import { list, deleteTask } from "@/httpsAPI/requirement";
import addForm from "./components/taskAdd";
import updateForm from "./components/taskUpdate";

let columns = [
  { title: "需求标题", dataIndex: "title", isShow: true },
  {
    title: "所属项目",
    dataIndex: "projectName",
    align: "center",
    isShow: true,
  },
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
    isShow: true,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "级别",
    dataIndex: "level",
    align: "center",
    isShow: true,
    scopedSlots: { customRender: "level" },
  },
  { title: "计划完成时间", dataIndex: "endTime", isShow: true },
  { title: "提交人", dataIndex: "masterName", isShow: true },
  {
    title: "操作",
    dataIndex: "action",
    align: 'center',
    scopedSlots: { customRender: "action" },
    isShow: true,
  },
];
export default {
  name: "demands",
  data() {
    return {
      queryParams: {},
      pageNum: 1,
      addFormVisible: false,
      updateFormVisible: false,
      loadData: (parameter) => {
        this.pageNum = parameter.pageNo;

        if (!parameter.sortField && !parameter.sortOrder) {
            parameter.sortField = 'createTime'
            parameter.sortOrder = 'descend'
          }

          this.queryParams.projectId = this.$route.query.projectId
          this.queryParams.taskType = 1

        return list(Object.assign(parameter, this.queryParams)).then((res) => {
          return res.data;
        });
      },
      tableListArr: (() => {
        return columns.filter((item) => {
          return item.dataIndex;
        });
      })(),
      defaultTableList: (() => {
        let arr = [];
        columns.map((item) => {
          if (item.isShow) {
            arr.push(item.dataIndex);
          }
        });
        return arr;
      })(),
      currentMenu: [0],
      settingVisible: false,
      form: this.$form.createForm(this, { name: "searchRequirement" }),
      dialogVisible: false,
      dialogTitle: "",
      id: null,
      type: ""
    };
  },
  components: {
    addForm,
    updateForm
  },
  computed: {
    ...mapGetters(['userinfo']),
    columns() {
      return columns.filter((item) => {
        return item.isShow;
      });
    },
  },
  created() {
  },
  methods: {
    // 刷新表格数据
    resetTable(pageNo){ 
      console.log('pageNo', pageNo)
      this.$refs.table.localPagination.current = Number(pageNo)
      this.$refs.table.refresh(false)
    },
    // 重置搜索form
    resetQueryForm() {
      this.queryParams = {};
      this.$refs.table.refresh(true);
    },
    getList() {},
    searchHandleSubmit() {
      this.loadData();
    },
    tableListHandleChange(checkedVal) {
      columns.forEach((item) => {
        if (checkedVal.includes(item.dataIndex)) {
          item.isShow = true;
        } else {
          item.isShow = false;
        }
      });
    },
    // 新建
    createFormEvt() {
      this.addFormVisible = true
      this.$nextTick(() => {
        this.$refs.addForm.init()
      })
    },
    // 修改
    updateFormEvt(record) {
      this.updateFormVisible = true
      this.$nextTick(() => {
        this.$refs.updateForm.init(record)
      })
    },
    // 详情
    detailEvt(record){
      this.$router.push({ path: '/requirement/detail', query: {taskId: record.id,masterId:record.masterId} })
    },
    // 删除
    deleteEvt(record) {
        const that = this
        deleteTask({id:record.id}).then(res=>{
            if(res.code==10000) {
                that.$message.success({
                    content: '操作成功',
                    duration: 1,
                    onClose: () => {
                        that.$refs.table.refresh(true)
                    }
                })
                } else {
                that.$message.error({
                    content: res.msg,
                    duration: 1
                })
            }
        })
    },
  },
};
</script>

<style scoped>
.ant-layout .layout-content-page-wrap{
  overflow: hidden !important;
}
</style>
